// Import necessary libraries
import React, { useState } from 'react';
import './LoadingScreen.css'; // Import CSS for styling (create this file)
import { ReactComponent as Logo } from '../../resources/Halton_Logo.svg'
import { Typography } from '@mui/material';

// Define the LoadingScreenProps interface for TypeScript
interface LoadingScreenProps {
    loadingState: string; // Path to the logo image
}

// Create the LoadingScreen functional component
const LoadingScreen: React.FC<LoadingScreenProps> = ({ loadingState }) => {

    const [zIndexSet, setZIndexSet] = useState(loadingState === 'loaded');
    const handleTransitionEnd = () => {
        setTimeout(() => {
            setZIndexSet(true);
        }, 700);
    }

    return (
        <div className={`loading-screen ${loadingState === 'loaded' ? 'hidden' : ''} ${zIndexSet ? 'below' : ''}`} onTransitionEnd={handleTransitionEnd}>
            <Logo fill='#2499D5' className='logo' />
            <div className="loading-status">
                <div className="spinner"></div>
                <Typography fontFamily={'Roboto'}>
                </Typography>
            </div>
        </div>
    );
};

export default LoadingScreen;
