import axios, { AxiosResponse } from 'axios';
import { Template } from 'store/reducers/template';
import { ENDPOINT_BASE } from 'constants/index';

const TEMPLATE_PATH = 'template';

export function getTemplate(token: string): Promise<AxiosResponse<Template>> {
  return axios.get(`${ENDPOINT_BASE}${TEMPLATE_PATH}?token=${token}`);
}

export type GetTemplateResponse = Template;
