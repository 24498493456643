import { FC } from 'react';

import { Card, CardContent, Grid, Typography } from '@mui/material';

import { ReactComponent as SensorImg } from 'assets/vector/sensor.svg';
import { ReactComponent as ThumbsUp } from 'assets/vector/thumbs-up.svg';
import {
  DataValue,
  getAirQualityValues,
  getThermalComfortValues,
  getViralRiskValues,
} from './dataValueGetters';

import { SensorDataObject } from '../../types/types';
import { AIR_QUALITY, THERMAL_COMFORT, VIRAL_RISK } from '.';

import './SensorCard.scss';

const SensorCard: FC<{
  sensorData: SensorDataObject[];
  openSensorDetails(): void;
}> = ({ sensorData, openSensorDetails }) => {
  return (
    <Card className="Sensors__componentCard" onClick={openSensorDetails}>
      <CardContent style={{ padding: 0 }}>
        <div className="Sensors__cardHeader">
          <Typography>
            {sensorData[0]?.variableName?.split('_Thermal')[0]}
          </Typography>
        </div>

        <Grid container className="Sensors__cardContent">
          <Grid item display="flex" alignItems="center" justifyContent="center">
            <SensorImg />
          </Grid>
          <Grid item xs={12} sm>
            {sensorData.map((data, i) => {
              let activeValues: DataValue[] = [];
              if (data.variableName?.includes(THERMAL_COMFORT)) {
                const thermalComfortData = sensorData?.find((d) =>
                  (d as any).variableName.includes(THERMAL_COMFORT)
                );
                activeValues = getThermalComfortValues(7, thermalComfortData);
              }
              if (data.variableName?.includes(AIR_QUALITY)) {
                const airQualityData = sensorData?.find((d) =>
                  (d as any).variableName.includes(AIR_QUALITY)
                );
                activeValues = getAirQualityValues(6, airQualityData);
              }
              if (data.variableName?.includes(VIRAL_RISK)) {
                const viralRiskData = sensorData?.find((d) =>
                  (d as any).variableName.includes(VIRAL_RISK)
                );
                activeValues = getViralRiskValues(5, viralRiskData);
              }

              const activeDataValue = activeValues.find((s) => s.isActive);
              return (
                <Grid
                  container
                  key={i}
                  display="flex"
                  alignItems="center"
                  margin="8px 0"
                  data-type={activeDataValue?.type}
                >
                  <Grid item xs={12} md={6}>
                    <Typography>{data.displayName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="Sensors__badge">
                    <Typography>{activeDataValue?.description}</Typography>
                    <ThumbsUp />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SensorCard;
