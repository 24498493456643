import { Box, CircularProgress, Typography } from '@mui/material';
import './Loading.scss';

interface Props {
  isCentered?: boolean;
}
const Loading = (props: Props): JSX.Element => {
  return (
    <Box
      display="inline-flex"
      className={props.isCentered ? 'LoaderCentered' : ''}
    >
      <Typography marginRight={1}>Loading</Typography>
      <CircularProgress size={20}></CircularProgress>
    </Box>
  );
};
export default Loading;
