import { Template } from 'store/reducers/template/types';

export const template: Template = {
  templateDescription: 'FS Monthly report - Type String',
  systems: [
    {
      name: 'FR00153',
      description: {
        latitude: 0,
        longitude: 0,
      },
      coreData: {
        totalConsumption: 11760999.038825627,
        tendance: -6.521139163886986,
      },
      technologiesData: {
        MARVEL: {
          averageSavings: 562966.6719714096,
        },
        UV: {
          totalConsumption: 0,
          tendance: 0,
          averageSavings: 0,
        },
        WW: {},
        WMist: {},
      },
      systems: [
        {
          name: 'M02',
          coreData: {
            totalConsumption: 5780205.345668045,
            tendance: -15.673192398018784,
          },
          technologiesData: {
            MARVEL: {
              averageSavings: 397445.9716267253,
            },
            UV: {
              totalConsumption: 0.0,
              tendance: 0,
              averageSavings: 0,
              usageLimit: 0,
            },
            WW: {},
            WMist: {},
          },
          hoods: [
            {
              name: 'H13',
              coreData: {
                totalConsumption: 1314317.9028738998,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 1639.140794223827,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1802.75,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1944.5666666666668,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1908.8555555555556,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1877.0055555555557,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1845.7666666666667,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1751.572222222222,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1833.1222222222223,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1958.4222222222222,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1979.5944444444444,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1924.6666666666665,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1895.1722222222222,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1885.288888888889,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1725.5833333333335,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 1633.927777777778,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 1568.927777777778,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 1529.8944444444444,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 1487.3388888888887,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 1502.0333333333335,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 1587.3444444444444,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1848.2055555555555,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1939.7944444444447,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1919.2444444444443,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1936.1166666666668,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1928.1166666666666,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1872.85,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1963.6833333333334,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1986.2722222222221,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1821.8277777777778,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1885.6055555555556,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1990.6,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1883.9055555555556,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1860.588888888889,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 1673.533333333333,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 1609.6,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 1541.9833333333331,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 1548.0166666666669,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 1548.3166666666668,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 1520.3166666666666,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 1525.011111111111,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1560.3555555555556,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1603.0111111111112,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1644.0166666666667,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1664.6722222222222,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1720.3555555555556,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1720.9833333333333,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1666.9888888888888,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1658.3333333333335,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 1620.677777777778,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 1564.3333333333335,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 1546.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1664.9,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1865.9444444444443,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1978.3111111111111,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1917.5222222222221,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 1840.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1854.9444444444443,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1962.5888888888887,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1958.75,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1942.6222222222223,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1933.4055555555556,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1864.311111111111,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1863.8055555555557,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 1712.3944444444444,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 1686.0222222222221,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 1693.3111111111111,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 1684.0277777777778,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 1686.6888888888889,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1736.0222222222221,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1938.5944444444444,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1919.0666666666666,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1885.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1883.2944444444445,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 1832.7333333333333,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1814.8833333333334,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1908.0111111111112,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1842.3666666666668,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1951.427777777778,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1897.0277777777778,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1775.7333333333331,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 1740.038888888889,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 1700.8,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 1671.7111111111112,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 1655.0444444444445,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 1646.9555555555555,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1651.3944444444444,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1638.0444444444445,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1628.95,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1643.0055555555557,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1681.8444444444444,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 1719.95,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1781.811111111111,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1965.011111111111,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1921.838888888889,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1997.2777777777778,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1952.6055555555556,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1896.3833333333332,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1855.216666666667,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 1735.8888888888891,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 1689.2222222222222,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 1634.3277777777778,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 1605.5166666666669,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 1575.338888888889,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1594.4833333333331,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1783.6166666666668,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1828.4555555555555,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1849.1388888888891,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1880.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1882.6,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 1925.5944444444444,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1918.5277777777778,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1983.25,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1952.211111111111,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1970.038888888889,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 2010.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1967.0777777777778,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 1920.3444444444444,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 1730.0333333333335,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 1669.4888888888888,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 1646.7444444444443,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 1650.9222222222222,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 1173.0611111111111,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 1396.938888888889,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 1639.4944444444443,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1636.4111111111113,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1868.9777777777779,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1933.833333333333,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1902.161111111111,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1938.9111111111113,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1916.3444444444447,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1803.6888888888889,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1761.9333333333332,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1807.1111111111109,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1912.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1969.0555555555557,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1987.9055555555556,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1958.8444444444447,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1904.3055555555554,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 1783.85,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 1754.0444444444445,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 1658.8611111111113,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 1674.1388888888891,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 1671.6277777777777,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 1650.2333333333333,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 1642.7666666666667,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 1658.8944444444444,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 1638.2,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1680.7388888888888,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1877.9111111111113,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1852.4388888888889,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1915.9166666666665,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1953.666666666667,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1904.8833333333334,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1919.2277777777779,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1920.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1906.7777777777778,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1934.3333333333335,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1928.3333333333335,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1901.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1863.1222222222223,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 1727.4444444444443,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 1724.3444444444444,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 1670.1166666666666,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 1620.8055555555557,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1623.8444444444447,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1674.2111111111112,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1685.2111111111112,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1726.7555555555557,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1787.3611111111113,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1845.8611111111109,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1828.7222222222224,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1782.1277777777777,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1782.2055555555555,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1785.7333333333331,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 1681.8555555555556,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 1665.6666666666665,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 1648.2722222222221,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 1643.0555555555554,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 1689.3888888888891,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 1682.2722222222221,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 1636.0777777777778,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 1582.4777777777779,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 1600.0555555555557,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1642.3888888888887,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1815.7166666666665,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1986.7611111111112,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1914.1555555555556,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1919.1,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 1860.8555555555554,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1970.2666666666667,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1969.438888888889,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1962.3777777777777,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1984.2888888888888,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1958.3277777777778,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1962.4777777777779,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 1917.2666666666667,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 1771.238888888889,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 1721.1277777777777,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 1646.9666666666665,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 1621.15,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 1634.177777777778,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 1628.3111111111111,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 1604.8444444444444,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1719.4222222222222,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1921.677777777778,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1868.3166666666668,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1893.3333333333335,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1922.65,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 1778.6611111111113,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1796.85,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1918.1833333333332,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1954.6388888888891,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1963.0055555555557,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1985.6666666666665,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 1976.6833333333332,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 1796.1888888888889,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 1689.1888888888889,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 1623.5055555555557,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 1639.5,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 1630.166666666667,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 1586.7055555555555,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 1593.1555555555556,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1616.3111111111111,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 1793.6388888888887,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1897.7222222222222,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1846.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1910.2666666666667,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1878.6166666666668,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 1764.85,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 1804.2722222222221,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1950.7055555555555,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1936.083333333333,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1932.666666666667,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1924.8555555555556,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 1849.4166666666665,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 1754.7944444444447,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 1694.5277777777778,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 1648.4555555555555,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 1616.2777777777776,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 1742.9333333333332,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1948.6555555555553,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1988.061111111111,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1943.461111111111,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1984.2555555555557,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 1984.4833333333331,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 1863.1388888888891,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1859.5833333333335,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1888.4555555555555,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1938.161111111111,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1993.461111111111,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1990.6166666666666,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 1881.2722222222221,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 1787.7555555555552,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 1753.2944444444445,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 1701.5444444444445,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 1718.788888888889,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 1702.3111111111111,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 1638.0944444444444,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 1523.6277777777777,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 1596.3555555555556,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1631.0009722222221,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1783.2722222222221,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1965.65,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1894.7722222222221,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1873.788888888889,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1928.788888888889,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1828.6722222222222,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 1813.3333333333335,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1832.1944444444443,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1882.45,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1980.1222222222223,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1957.6555555555556,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1931.1111111111113,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1846.6,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 1759.4611111111112,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 1727.9,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 1713.4555555555555,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 1666.2277777777779,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 1640.8944444444444,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 1622.5666666666666,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 1638.1,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 1641.7611111111112,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 1709.4777777777779,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1768.95,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1873.8444444444447,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1857.6055555555556,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1962.5166666666667,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1943.9055555555556,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 1914.8611111111113,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1846.4222222222222,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1874.3555555555556,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1933.916666666667,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1930.083333333333,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1958.3444444444444,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1838.1388888888891,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 1724.5833333333335,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 1708.916666666667,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 1696.588888888889,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 1686.4111111111113,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 1692.9222222222222,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 1653.4166666666665,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 1629.833333333333,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 1645.177777777778,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 1730.8111111111111,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1969.4833333333331,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1971.7444444444443,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1903.6555555555553,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1775.5166666666669,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1727.188888888889,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1743.4777777777774,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1752.5888888888887,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1720.5944444444444,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1731.3388888888887,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1719.5944444444444,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 1674.2,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 1677.1388888888891,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 1692.6,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 1661.1388888888887,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 1619.6055555555554,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 1600.3944444444446,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 1630.3944444444446,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 1638.6055555555556,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1768.5222222222221,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1825.4944444444443,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1772.6722222222222,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1902.416666666667,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1851.1611111111113,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 1892.45,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 1813.4555555555555,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1842.65,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1909.2277777777779,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1885.2777777777778,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1855.1444444444444,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1882.0333333333335,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 1768.4333333333334,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 1663.2388888888888,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 1652.6222222222223,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 1628.6166666666668,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 1603.2777777777778,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 1593.9944444444443,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 1582.2888888888888,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 1596.3666666666666,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 1595.288888888889,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 1597.0444444444445,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1712.9,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1962.3388888888887,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1816.5166666666667,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1837.0388888888888,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 1874.0222222222226,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 1775.4166666666665,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 1741.7333333333333,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1793.0277777777778,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1798.161111111111,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1917.0388888888888,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1928.0555555555554,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1915.25,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 1794.0666666666666,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 1663.916666666667,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 1631.3111111111111,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 1612.9722222222222,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 1583.6444444444444,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 1576.9722222222222,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 1550.9944444444448,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 1576.4444444444443,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 1821.2722222222221,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1962.3777777777777,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 1965.6444444444444,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1939.9777777777779,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1915.3277777777778,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1954.688888888889,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1950.322222222222,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1954.6388888888891,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1808.2611111111112,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 1700.2944444444445,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 1676.6722222222222,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 1659.9944444444443,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 1622.9944444444443,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 1613.0166666666669,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 1582.9888888888888,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 1584.1166666666666,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 1611.6666666666665,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1764.6555555555556,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 1890.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1993.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1984.3333333333335,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1936.333333333333,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 1982.6555555555556,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1959.15,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 2006.6555555555556,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1988.0055555555557,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1967.3444444444444,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1950.3555555555556,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1829.7333333333333,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 1724.6944444444443,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 1689.6833333333334,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 1687.9833333333333,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 1697.3444444444444,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 1654.0222222222226,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 1630.0222222222221,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 1631.2833333333335,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1835.7444444444443,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1969.3166666666668,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1990.9833333333333,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 2019.9666666666665,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1998.0777777777778,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1911.4,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1932.927777777778,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1988.2833333333335,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1990.0333333333335,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1947.0555555555557,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1877.8555555555556,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 1742.8,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 1680.6944444444446,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 1680.3722222222223,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 1627.1,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 1605.6277777777777,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 1625.3,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 1639.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 1605.4333333333332,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 1587.6333333333332,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 1612.9222222222222,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1794.0666666666666,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1992.5888888888887,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1999.4222222222222,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1973.2722222222221,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 1999.2944444444445,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 1967.3444444444444,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1798.3777777777777,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1800.5333333333335,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1910.9166666666665,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1876.2777777777776,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1954.9555555555555,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1870.7722222222221,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 1680.2833333333335,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 1648.2833333333335,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 1649.1333333333332,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 1610.038888888889,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 1599.3777777777777,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 1557.7722222222226,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 1546.95,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 1558.666666666667,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 1558.677777777778,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 1571.938888888889,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 1598.9388888888889,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 1612.5555555555557,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1664.9388888888889,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 1667.6111111111113,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1711.8166666666668,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1730.9944444444448,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1703.927777777778,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 1660.661111111111,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 1672.666666666667,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 1659.0666666666666,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 1617.811111111111,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 1576.416666666667,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 1613.1222222222223,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 1612.333333333333,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1654.5111111111112,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1685.7611111111112,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 1720.9222222222222,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 1738.9944444444443,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1833.5166666666667,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1957.666666666667,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1952.8277777777778,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1995.5833333333335,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 1922.4222222222222,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 1726.677777777778,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 1627.6111111111113,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1767.5166666666667,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1947.2444444444448,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1924.0055555555552,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1957.4777777777779,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1917.7777777777778,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 1773.6222222222223,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 1857.8944444444446,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1996.8944444444444,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1989.7611111111112,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1977.8888888888891,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 1941.5944444444444,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 1772.9777777777779,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 1727.0888888888887,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 1644.2277777777779,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 1639.7777777777778,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1723.488888888889,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1922.9888888888888,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1964.4333333333332,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1942.1388888888891,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1898.666666666667,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 1897.761111111111,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 1982.3111111111111,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 2033.088888888889,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 2028.9111111111113,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1978.2444444444443,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1958.1277777777777,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1906.9444444444446,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 1752.538888888889,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 1663.3722222222223,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 1641.538888888889,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 1905.6555555555556,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 1822.7444444444443,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 1619.1277777777777,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 1636.4,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1785.5,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1948.9944444444443,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1983.6722222222222,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 2033.6,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1987.6111111111113,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 1965.3444444444444,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1965.1944444444443,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1994.8611111111113,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1990.9444444444443,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1976.5222222222226,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 1927.1277777777777,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 1771.4222222222222,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 1740.4666666666665,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 1717.95,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 1681.1388888888887,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 1675.5055555555557,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 1630.95,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 1635.7166666666665,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1854.3777777777777,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1944.15,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1986.911111111111,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 2040.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 2040.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 2006.322222222222,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1977.6444444444444,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1974.177777777778,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1994.8444444444444,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1988.1555555555556,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1995.8388888888887,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1916.6166666666666,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1746.9722222222222,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 1715.4944444444443,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 1685.1611111111113,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 1655.9944444444448,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 1653.3333333333335,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 1640.4944444444443,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1806.9611111111112,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1991.8277777777778,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1982.5666666666666,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1987.8277777777778,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1983.1833333333332,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1951.9833333333331,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1961.4,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 1818.9055555555556,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 1738.188888888889,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 1703.6944444444443,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 1661.7333333333333,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1645.7611111111112,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1680.338888888889,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1718.088888888889,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 1774.088888888889,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1787.1944444444443,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1761.5277777777778,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 1760.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1754.2,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 1706.7333333333333,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 1688.7333333333333,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 1644.2111111111112,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 1628.7444444444443,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 1626.8861092824227,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 1626.8861092824227,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 171920.90276638189,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H14',
              coreData: {
                totalConsumption: 41846.29555555556,
                airflow: [
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 170.29111111111112,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 464.16555555555556,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 464.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 464.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 464.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 464.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 464.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 467.8388888888889,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 471.48777777777775,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 472.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 468.51222222222225,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 468.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 468.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 0,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H08',
              coreData: {
                totalConsumption: 1330847.5333333332,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 1867.0166666666667,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1952.427777777778,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1936.0944444444444,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1770.6833333333332,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1722.333333333333,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1801.072222222222,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1937.1333333333334,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1963.6611111111113,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1940.961111111111,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1951.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1903.0444444444445,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 1701.6666666666665,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 1597.1555555555556,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 1529.2666666666669,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 1537.1944444444443,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 1754.4166666666665,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1954.4888888888888,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1950.6055555555556,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1928.9833333333333,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1954.438888888889,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1997.9777777777779,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1954.8277777777778,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 1722.161111111111,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 1629.2944444444445,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 1612.2944444444447,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 1561.9833333333331,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 1559.6666666666665,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 1547.3055555555557,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 1507.6555555555556,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 1587.5055555555557,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 1869.088888888889,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 1890.3333333333335,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1927.3555555555556,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1944.661111111111,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1884.2555555555557,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1697.95,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1660.338888888889,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1821.0611111111111,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1956.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1971.333333333333,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1978.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1828.6611111111113,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1627.3277777777778,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 1616.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 1589.0055555555552,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 1527.3444444444444,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 1658.5777777777778,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1842.9777777777779,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1927.2944444444447,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1956.0111111111112,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 1939.3333333333335,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1969.9722222222224,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1896.1388888888887,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 1748.0333333333335,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1844.8555555555556,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1935.6666666666665,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1950.3166666666666,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1949.6833333333334,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1912.7722222222226,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 1783.7277777777779,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 1749.711111111111,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 1715.3722222222223,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 1695.0222222222221,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 1664.0222222222226,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 1649.0555555555557,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 1753.5222222222221,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 1935.6166666666666,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1919.0277777777778,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1928.666666666667,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1932.6444444444444,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1935.6666666666665,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1892.85,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 1818.061111111111,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1796.9944444444443,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1856.4722222222222,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1943.5944444444444,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1956.033333333333,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1926.7388888888888,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1880.4,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1879.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1804.8055555555557,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 1753.7444444444443,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 1735.3777777777777,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 1679.8388888888887,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 1650.2833333333335,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 1653.0444444444445,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 1669.7666666666667,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1780.6333333333334,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1680.4777777777779,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1467.7277777777779,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1329.2,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1341.95,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1493.8611111111113,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 1679.6166666666668,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1639.5777777777778,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1404.072222222222,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1342.05,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1328.3833333333334,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1320.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1320.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1366.561111111111,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 1421.7722222222221,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 1623.0388888888888,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 1655.3333333333335,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 1652.4555555555555,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 1613.6722222222222,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 1593.1277777777777,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 1587.0055555555557,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 1734.8388888888887,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1994.1388888888891,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1992.1333333333334,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1962.9333333333332,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1994.9333333333334,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1980.1333333333332,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1935.5388888888888,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 1849.2833333333335,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1801.6666666666665,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1860.3166666666666,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1936.261111111111,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1973.45,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1994.1555555555556,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 1900.927777777778,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 1744.6333333333334,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 1702.0777777777778,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 1193.711111111111,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 1453.7777777777776,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 1787.1666666666665,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1913.4166666666665,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1920.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1952.4,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1951.6722222222222,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1876.7555555555557,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1875.7555555555557,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1900.2277777777779,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1968.3388888888887,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1964.9111111111113,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1997.5777777777778,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1942.7555555555552,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 1809.5166666666667,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 1774.1888888888889,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 1760.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 1716.822222222222,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 1652.572222222222,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 1664.5222222222221,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 1621.427777777778,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 1625.9055555555556,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 1753.8888888888887,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 1945.5555555555554,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1979.3666666666666,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1994.2111111111112,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1969.5611111111111,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1944.3,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1767.75,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1716.2333333333333,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1902.1333333333334,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1956.6722222222222,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1969.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1975.7833333333335,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1808.4944444444448,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 1086.85,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 1608.088888888889,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 1647.2166666666665,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 1646.1111111111113,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 1649.8888888888887,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 1641.561111111111,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 1601.4555555555553,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 1606.211111111111,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 1594.0277777777778,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 1603.8944444444444,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1554.4444444444446,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1882.1388888888891,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1936.211111111111,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1960.75,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1967.788888888889,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1925.3,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1811.838888888889,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1762.6722222222222,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1898.7166666666665,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1947.716666666667,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1932.538888888889,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1941.2055555555555,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1985.4055555555556,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1960.4555555555555,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 1812.9888888888888,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 1779.8055555555557,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 1740.9444444444443,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 1673.2055555555555,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 1633.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 1638.4722222222222,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 1752.5611111111111,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1948.188888888889,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1957.3833333333334,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1978.4722222222222,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1963.5222222222221,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1928.8777777777777,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 1797.0555555555557,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1735.6666666666665,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1893.2555555555557,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 2029.711111111111,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 2040.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 2040.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 2040.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 1993.6388888888887,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 1845.3888888888891,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 1747.4222222222222,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 1648.6444444444444,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 1623.15,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 1611.4944444444443,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 1594.338888888889,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 1615.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 1697.2666666666667,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 1875.038888888889,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1958.3333333333335,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1948.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1944.177777777778,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1971.838888888889,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1920.1611111111113,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 1764.6944444444443,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1704.9944444444443,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1831.1722222222222,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1941.1722222222222,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1981.333333333333,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1935.677777777778,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 1898.5111111111112,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 1769.6833333333334,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 1689.177777777778,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 1636.5055555555552,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 1629.338888888889,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 1592.3555555555556,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 1607.3166666666666,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 1618.8388888888887,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 1652.2777777777778,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1887.0833333333335,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 1925.1555555555556,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1978.5888888888887,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1993.7,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1896.1333333333334,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 1735.7333333333331,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 1747.9611111111112,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1850.0055555555557,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1942.1444444444444,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1971.4833333333333,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1993.1444444444444,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1990.3777777777777,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 1914.8,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 1755.166666666667,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 1661.1888888888889,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 1666.1388888888887,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 1665.7277777777779,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 1617.7277777777779,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 1616.8055555555554,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 1727.072222222222,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 1919.7888888888888,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1916.2666666666667,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1949.5944444444444,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1994.7944444444447,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 1948.438888888889,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 1814.15,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1774.338888888889,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1873.8277777777778,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1942.2055555555555,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1938.9944444444443,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1956.7944444444445,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1977.5777777777778,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 1973.0388888888888,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 1821.261111111111,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 1764.5444444444445,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 1754.8777777777777,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 1725.5444444444445,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 1704.8722222222223,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 1692.8833333333332,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 1560.0555555555557,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 1603.0166666666669,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 1712.7277777777779,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1882.788888888889,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1890.1,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1922.0111111111112,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1979.3333333333335,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1958.2166666666665,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1904.2222222222222,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1797.45,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 1786.55,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1891.4222222222222,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1963.8944444444444,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1974.1055555555556,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1997.1055555555554,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1933.7222222222224,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 1777.6888888888889,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 1755.2277777777779,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 1708.461111111111,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 1667.0277777777778,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 1639.5611111111111,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 1609.9,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 1587.0055555555557,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 1796.5,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 1950.1333333333332,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 1956.7555555555557,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1944.9055555555553,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1952.0944444444444,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1964.338888888889,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1903.0166666666669,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1754.2055555555555,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 1768.6444444444444,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1936.7777777777778,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1968.0055555555557,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1961.416666666667,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1965.5777777777778,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1925.822222222222,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 1773.9111111111113,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 1683.9944444444443,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 1654.916666666667,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 1629.3333333333335,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 1623.5055555555557,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 1587.6611111111113,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 1641.538888888889,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 1916.0222222222221,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1957.1,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1942.072222222222,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1956.6277777777777,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1894.15,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1723.6388888888887,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1714.2944444444445,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1857.2611111111112,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1966.3444444444447,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1947.4055555555553,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1944.927777777778,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1917.2722222222221,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 1743.666666666667,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 1719.2666666666669,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 1669.4777777777779,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 1614.4944444444443,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 1586.9888888888888,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 1560.3333333333335,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 1733.2611111111112,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 1887.333333333333,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1879.0055555555552,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1916.838888888889,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1952.3944444444444,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1964.6666666666665,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1903.6277777777777,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 1777.838888888889,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 1719.8944444444444,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1840.9166666666665,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1927.6722222222222,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1948.0055555555557,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1937.2944444444447,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1912.6222222222223,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 1846.6722222222222,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 1705.4777777777779,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 1654.5833333333335,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 1636.9555555555555,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 1593.5444444444447,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 1541.288888888889,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 1537.283333333333,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 1528.0444444444445,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 1686.2722222222221,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 1890.3722222222223,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1859.3333333333335,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1932.7777777777778,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1915.9222222222222,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1919.0333333333335,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 1906.8555555555556,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 1729.7222222222222,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 1675.6944444444446,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1762.7388888888888,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1955.8277777777778,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1983.2333333333331,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1947.7,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1932.2666666666667,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 1872.1333333333334,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 1703.8055555555557,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 1605.9166666666665,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 1576.9722222222222,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 1544.9666666666665,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 1536.9722222222222,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 1493.95,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 1621.7444444444443,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 1878.7555555555557,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 1927.0444444444445,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1960.688888888889,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1976.3166666666666,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1914.8944444444444,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 1775.95,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1762.35,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1827.75,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1930.7055555555555,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1937.322222222222,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1953.3666666666668,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1952.3111111111111,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1915.6166666666666,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 1779.9444444444443,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 1721.9888888888888,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 1699.9722222222224,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 1643.3277777777778,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 1627.3277777777778,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 1634.338888888889,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 1613.3444444444444,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 1543.3277777777778,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 1551.333333333333,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 1674.3666666666666,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 1901.6555555555556,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1929.661111111111,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 1973.3333333333335,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1994.3277777777778,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1959.0166666666667,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1814.677777777778,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1856.9666666666665,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 1971.9944444444443,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1968.3388888888887,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1964.322222222222,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1958.05,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 1803.061111111111,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 1706.7055555555555,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 1658.3611111111109,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 1638.677777777778,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 1610.3333333333335,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 1631.65,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 1621.0222222222221,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 1742.3777777777777,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1942.9444444444443,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1988.3,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1970.677777777778,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1998.65,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1942.1666666666665,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1805.0666666666666,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1790.3055555555557,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1921.4944444444443,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1937.0777777777778,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1960.911111111111,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 2000.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1933.5222222222221,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 1767.2055555555555,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 1701.3611111111113,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 1669.411111111111,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 1634.0333333333335,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 1580.7611111111112,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 1561.9666666666665,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 1557.072222222222,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 1591.9777777777779,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 1843.3444444444444,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1932.2277777777774,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1961.7222222222222,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1947.2944444444447,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1954.038888888889,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 1924.0444444444445,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1932.2944444444447,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1950.911111111111,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1991.4388888888889,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1958.666666666667,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1924.2833333333335,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 1741.7611111111112,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 1648.088888888889,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 1604.3833333333334,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 1597.0444444444445,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 1554.1,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 1661.4722222222222,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 1939.1888888888889,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 1903.666666666667,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 1949.1666666666665,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1993.3944444444444,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1975.9444444444443,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 1948.4444444444443,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1954.9388888888889,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1953.7444444444443,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1959.9333333333334,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1911.466666666667,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 1752.5888888888887,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 1723.5277777777778,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 1664.4,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 1656.7444444444443,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 1601.8833333333334,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 1567.4055555555553,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 1583.5222222222221,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 1705.0055555555557,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 1893.5166666666667,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 1868.161111111111,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1933.7722222222221,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1909.7277777777774,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 1782.4111111111113,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 1742.5888888888887,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1872.8722222222223,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1970.5388888888888,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1944.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1975.9166666666665,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 1948.7555555555557,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 1788.9333333333334,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 1700.588888888889,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 1682.238888888889,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 1683.7722222222221,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 1660.9722222222222,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 1603.088888888889,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 1581.177777777778,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 1582.3833333333334,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 1795.8388888888887,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1927.3277777777778,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1929.4,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1925.4,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 1809.9777777777779,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 1760.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1835.5555555555554,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 1966.6055555555556,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1976.2,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 1928.8388888888887,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 1785.6222222222223,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 1733.5444444444447,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 1672.2222222222222,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 1641.8888888888891,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 1626.5555555555557,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 1753.088888888889,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 1918.1111111111109,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1912.85,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1942.7722222222226,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1954.7777777777778,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1962.7722222222226,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1953.05,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 1781.3611111111113,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 1750.1166666666666,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 1908.927777777778,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1941.5444444444445,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1963.666666666667,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1973.7,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1943.7833333333335,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1910.9555555555555,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 1739.6555555555556,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 1662.2444444444448,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 1388.6888888888889,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 456.4277777777778,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 1488.0166666666669,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 1664.9111111111113,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 1907.6555555555556,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1922.5333333333335,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1953.4055555555556,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1958.4055555555556,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1888.3444444444444,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1764.5388888888888,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 1738.9444444444443,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1340.083333333333,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1106.6166666666666,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1121.7333333333333,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1016.0333333333334,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1406.2944444444445,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 1895.011111111111,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 1770.9,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 1718.2888888888888,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 1677.2944444444447,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 1614.7666666666667,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 1598.8166666666666,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 1582.3444444444444,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 1725.2777777777778,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 1910.1833333333334,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1910.8722222222223,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1953.0333333333335,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1975.1555555555556,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1943.1555555555556,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1925.1388888888887,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 1828.9444444444443,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1801.6888888888889,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1936.0777777777778,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1970.3888888888891,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1966.177777777778,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1980.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1978.9888888888888,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1911.3166666666666,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1779.9388888888889,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 1732.9888888888888,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 1673.322222222222,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 1648.1666666666665,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 1624.1666666666665,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 1749.3166666666668,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 1915.1611111111113,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1901.822222222222,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1940.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1895.0444444444445,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1759.6833333333334,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1736.8,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1910.2555555555552,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1950.5166666666669,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1939.3277777777778,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1943.1555555555556,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1966.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1907.4,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 1793.8944444444444,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 1725.85,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 1720.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 1694.572222222222,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 1643.3722222222223,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 1619.3333333333335,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 1766.4777777777779,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 1957.2222222222222,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1881.5222222222226,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1921.9055555555556,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1960.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1920.3166666666666,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1821.9055555555556,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 1780.661111111111,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1891.7777777777778,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1956.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1974.8,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1947.4111111111113,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 1950.8055555555557,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1923.2111111111112,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 1802.3944444444444,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 1763.2555555555557,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 1740.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 1710.9333333333334,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 1668.6611111111113,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 1655.6055555555556,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 1602.538888888889,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 1600.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 157210.7931389095,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H12',
              coreData: {
                totalConsumption: 0.0,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: false,
                  totalSavings: 0,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H11',
              coreData: {
                totalConsumption: 60.00833333333333,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 19.725,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 40.28333333333333,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 335846.02662422846,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H10',
              coreData: {
                totalConsumption: 1589.5732407407406,
                airflow: [
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 53.79407407407407,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1260.3416666666665,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 47.15416666666666,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 5.079166666666667,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 24.98333333333333,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 35.47083333333333,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 14.075,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 9.5,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 7.745833333333334,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 14.429166666666667,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 21.975,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 49.1375,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 28.95,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 2.1916666666666664,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 8.404166666666667,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 6.341666666666666,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 363338.6303261317,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H09',
              coreData: {
                totalConsumption: 134424.3686032864,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 340.03943661971834,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 367.16666666666663,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 436.95833333333337,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 387.45833333333337,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 337.91666666666663,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 257.82916666666665,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 360.2375,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 326.39166666666665,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 124.34583333333335,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 443.1416666666667,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 480.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 477.21666666666664,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 457.46666666666664,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 436.4666666666667,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 423.5,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 437.2833333333333,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 450.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 443.47083333333336,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 442.27916666666664,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 450.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 450.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 441.475,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 442.525,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 445.975,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 440.7958333333333,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 465.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 438.5625,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 340.4,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 429.975,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 420.27083333333337,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 429.22916666666663,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 433.02083333333337,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 427.98333333333335,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 421.76666666666665,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 421.72916666666663,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 416.4833333333333,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 382.9708333333333,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 384.7791666666667,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 407.26666666666665,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 422.01666666666665,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 439.0125,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 448.24166666666673,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 428.7416666666666,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 408.9875,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 415.2625,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 429.0083333333334,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 434.0041666666667,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 422.24583333333334,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 412.25416666666666,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 425.75416666666666,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 422.25,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 429.00416666666666,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 421.99166666666673,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 397.75,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 405.5,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 409.5,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 410.75,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 424.99583333333334,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 374.7875,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 373.97083333333336,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 396.2583333333334,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 406.4916666666666,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 408.75833333333327,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 406.25,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 392.525,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 371.25,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 384.49166666666673,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 372.7791666666667,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 333.5,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 344.2625,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 330.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 330.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 351.2041666666667,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 400.70416666666665,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 417.51666666666665,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 410.48333333333335,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 430.5,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 415.99583333333334,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 412.02083333333337,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 391.80833333333334,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 378.77083333333337,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 345.01666666666665,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 340.04166666666663,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 307.24583333333334,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 316.75,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 295.5,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 369.375,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 404.225,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 382.8,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 385.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 361.3,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 334.775,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 325.52500000000003,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 329.6958333333334,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 372.39166666666665,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 414.7208333333333,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 420.72083333333336,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 433.78333333333336,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 408.2833333333333,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 296.0291666666667,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 268.07916666666665,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 355.2875,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 353.175,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 362.78333333333336,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 362.82916666666665,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 176.1625,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 319.1583333333333,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 356.8291666666667,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 348.75,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 354.4625,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 373.16666666666663,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 365.58333333333337,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 370.79166666666663,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 221.58333333333331,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 371.1916666666667,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 405.0875,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 390.20416666666665,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 390.9416666666667,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 313.64166666666665,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 309.6583333333333,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 330.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 356.79583333333335,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 344.1958333333334,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 355.15,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 356.30416666666673,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 384.7833333333333,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 412.44583333333327,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 424.05833333333334,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 399.8041666666666,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 378.80416666666673,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 374.3041666666666,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 358.32916666666665,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 652.6375,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 858.1375,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 859.8083333333334,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 855.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 855.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 855.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 855.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 600.8208333333333,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 732.8083333333333,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 885.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 867.4333333333334,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 763.5708333333333,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 450.44166666666666,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 392.1958333333334,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 358.44166666666666,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 315.75,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 337.43333333333334,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 351.1875,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 373.3666666666666,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 403.93333333333334,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 394.8208333333334,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 404.6125,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 429.9291666666666,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 421.0708333333334,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 427.425,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 434.825,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 419.075,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 398.82916666666665,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 396.425,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 408.5,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 378.73333333333335,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 370.0875,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 408.59166666666664,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 411.58333333333337,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 424.5875,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 430.83333333333337,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 414.32916666666665,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 395.66666666666663,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 377.4125,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 385.3375,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 375.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 375.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 375.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 382.4125,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 398.40833333333336,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 391.5875,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 383.99583333333334,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 400.5708333333334,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 431.2458333333333,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 406.4375,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 390.5,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 397.5583333333333,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 432.15416666666664,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 438.75,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 413.19166666666666,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 403.84583333333336,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 406.95,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 434.1,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 420.89583333333337,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 412.2125,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 410.5,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 418.75,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 383.79166666666674,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 236.96666666666667,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 234.45416666666668,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 346.1791666666666,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 358.17916666666673,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 372.7208333333333,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 371.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 352.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 403.4375,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 449.6375,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 403.1958333333334,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 400.3875,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 394.5,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 401.3625,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 385.8666666666666,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 381.1333333333334,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 397.38333333333327,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 345.58333333333326,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 280.6166666666667,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 348.15416666666664,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 353.8833333333334,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 389.37916666666666,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 377.7416666666666,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 352.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 360.25,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 371.2458333333333,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 333.1166666666666,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 338.75416666666666,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 360.85833333333335,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 383.625,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 390.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 398.125,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 397.875,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 407.49583333333334,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 420.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 421.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 420.87083333333334,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 436.86666666666673,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 445.13333333333327,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 435.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 406.525,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 374.6375,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 290.5083333333333,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 276.15,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 159.9625,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 286.1791666666667,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 356.1375,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 362.725,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 361.525,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 373.5916666666667,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 356.4333333333333,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 319.6125,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 310.0291666666667,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 334.44166666666666,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 350.87083333333334,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 323.89166666666665,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 353.15,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 382.0625,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 350.8208333333334,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 390.2125,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 390.89166666666665,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 384.04166666666663,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 373.30416666666673,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 363.1875,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 147.32083333333333,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 95.44166666666666,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 185.95833333333331,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 343.85833333333335,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 385.5083333333334,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 343.94583333333327,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 313.4916666666667,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 319.30833333333334,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 288.4875,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 345.22916666666674,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 391.09166666666664,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 384.65416666666664,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 375.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 350.2125,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 334.83333333333337,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 342.25416666666666,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 373.37083333333334,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 408.50416666666666,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 405.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 383.0708333333334,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 364.6625,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 302.95,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 347.6125,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 334.86666666666673,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 148.86666666666667,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 820107.3069797482,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H06',
              coreData: {
                totalConsumption: 529771.8555555556,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 1420.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1423.05,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1451.05,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1461.7166666666665,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1482.9944444444443,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1498.711111111111,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1485.6166666666666,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1480.7111111111112,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1495.9555555555555,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1480.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1468.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1480.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1480.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1480.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 1480.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 1463.966666666667,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 1460.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 1460.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 1435.2277777777779,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 1414.072222222222,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 1420.9722222222222,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 1420.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 1424.0055555555552,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 1404.6333333333334,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 1400.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1391.0166666666667,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1400.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1386.6611111111113,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1405.6944444444446,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1454.3777777777777,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1460.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1460.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1479.0222222222226,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1482.0222222222221,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1483.3055555555557,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1469.6222222222223,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1420.961111111111,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 1383.6444444444444,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 1380.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 1386.6722222222222,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 1380.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 1380.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 1380.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 1380.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 1387.3444444444444,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 1419.6833333333334,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 1420.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 1420.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 1421.3388888888887,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1449.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1473.0277777777778,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1520.0166666666667,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1520.988888888889,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1514.0055555555552,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1530.9944444444448,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1495.6722222222222,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1521.333333333333,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1498.666666666667,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 1439.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 1420.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 1446.333333333333,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 1452.3444444444444,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 1428.9944444444443,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 1460.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 1458.9944444444443,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 1457.333333333333,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 1442.0055555555557,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1440.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1447.9944444444443,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1491.9777777777779,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 1509.311111111111,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1549.661111111111,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1589.661111111111,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 1699.5777777777778,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1541.033333333333,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1576.6222222222223,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1596.35,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1597.6055555555556,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1793.8833333333332,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1656.8555555555556,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1613.9833333333333,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 1632.6555555555556,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 1631.0055555555557,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 1593.061111111111,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 1530.7333333333331,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 1504.0055555555552,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 1483.3611111111109,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 1502.5777777777778,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 1566.6444444444446,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1568.2833333333335,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1614.3055555555554,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1637.9722222222222,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 1667.2666666666667,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1699.9666666666665,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1650.8,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1614.3666666666666,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1652.1555555555556,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1702.283333333333,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1825.5555555555554,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1683.6166666666668,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 1600.7888888888888,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 1580.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 1587.9555555555555,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 1597.0444444444445,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 1567.0444444444445,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 1558.711111111111,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1554.2888888888888,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1544.0444444444445,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1524.3833333333334,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1544.2333333333333,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1555.7222222222222,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 1552.9,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1584.6722222222222,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1567.0555555555557,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1567.2277777777774,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1614.2777777777778,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 1604.0555555555557,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 1577.1166666666668,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 1555.3888888888891,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 1525.3888888888891,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 1509.7333333333333,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 1505.6,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 1514.3944444444444,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 1512.8666666666666,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1548.2111111111112,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1563.7944444444445,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1584.061111111111,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1634.6055555555554,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1618.072222222222,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1635.9222222222222,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1638.666666666667,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1640.3333333333335,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1655.5944444444444,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 1645.8166666666668,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 1614.0777777777778,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 1597.7444444444443,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 1593.5111111111112,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 1617.7444444444443,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 1593.3388888888887,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 1120.2944444444445,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 1309.338888888889,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 1520.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 1522.583333333333,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1571.083333333333,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1592.666666666667,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1631.072222222222,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1688.161111111111,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1692.0944444444444,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1652.75,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1683.4833333333333,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1780.288888888889,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1676.7444444444443,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 1656.3888888888887,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 1667.511111111111,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 1606.6166666666666,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 1559.5222222222221,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 1567.3777777777777,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 1599.7611111111112,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 1569.4333333333332,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 1544.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 1565.3333333333335,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 1556.4333333333334,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 1544.2333333333333,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1578.2277777777779,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1610.8,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1595.1,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1632.1166666666668,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1654.2222222222222,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1658.438888888889,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1624.7666666666667,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1652.35,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1660.1111111111113,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1704.1111111111109,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1765.2222222222222,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1668.5555555555557,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 1618.45,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 1585.2388888888888,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 1554.1166666666666,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 1516.5666666666666,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 1502.8833333333332,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 1539.7555555555557,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 1533.6888888888889,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1500.6555555555556,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1536.511111111111,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1618.966666666667,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1660.7944444444445,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1604.2111111111112,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1654.8722222222223,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1659.7888888888888,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1646.083333333333,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1708.0833333333335,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1707.9222222222222,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1692.2,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1771.7611111111112,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1765.9777777777774,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 1603.2611111111112,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 1589.3277777777778,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 1589.9388888888889,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 1556.333333333333,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1586.0555555555557,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1586.1333333333334,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1626.6722222222222,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1710.1111111111113,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1622.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1650.7111111111112,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 1672.811111111111,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1651.8166666666668,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1671.0444444444447,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1680.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1730.2555555555552,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1722.8888888888891,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 1660.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 1644.15,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 1603.6111111111113,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 1542.4833333333333,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 1548.6888888888889,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 1585.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 1555.6388888888891,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 1539.338888888889,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 1528.1555555555556,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 1520.1722222222222,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 1539.833333333333,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1559.8277777777778,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1553.8388888888887,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1598.1944444444446,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1678.8388888888887,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1652.4944444444443,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 1732.8555555555556,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1794.822222222222,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1661.6611111111113,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1672.5,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1700.5055555555557,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1650.5111111111112,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1592.3388888888887,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 1589.8277777777778,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 1576.85,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 1534.6722222222222,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 1526.3555555555556,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 1505.1444444444444,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 1499.8611111111113,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 1451.333333333333,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 1486.5,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1511.8,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 1548.677777777778,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1562.5944444444447,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1648.8722222222223,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1610.3333333333335,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1600.9944444444448,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 1612.488888888889,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1632.9666666666665,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1661.15,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1673.5166666666667,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1687.2888888888888,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1710.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 1658.2333333333331,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 1624.3722222222223,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 1600.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 1579.4,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 1541.8666666666666,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 1540.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 1529.8666666666668,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 1535.261111111111,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1560.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1640.038888888889,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1672.0888888888887,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1616.4611111111112,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 1640.938888888889,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 1654.5388888888888,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1644.4611111111112,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1668.6611111111113,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1642.8722222222223,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1644.5111111111112,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1724.9666666666665,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 1684.916666666667,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 1672.0888888888887,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 1636.416666666667,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 1620.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 1601.0944444444444,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 1560.3333333333335,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 1571.55,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 1451.9444444444446,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 1525.572222222222,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 1554.783333333333,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1532.7944444444445,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 555.7666666666668,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 322198.4055063913,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H07',
              coreData: {
                totalConsumption: 1448756.2998976852,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 2350.452222222222,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 2300.1222222222223,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 2280.53,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 2244.1299999999997,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 2257.8655555555556,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 2313.7333333333336,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 2328.3244444444445,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 2322.942222222222,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 2275.3966666666665,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 2241.345555555555,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 2242.395555555556,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 2277.854444444444,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 2270.2166666666667,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 2228.753333333333,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 2202.161111111111,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 2202.7133333333336,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 2239.58,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 2232.4944444444445,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 2212.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 2212.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 2238.18,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 2260.6111111111113,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 2266.0011111111107,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 2217.6388888888887,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 2268.5366666666664,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 2310.9722222222226,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 2297.3611111111113,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 2330.6033333333335,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 2341.2355555555555,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 2322.11,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 2267.937777777778,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 2191.4511111111115,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 2197.0822222222223,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 2229.7488888888893,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 2225.051111111111,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 2234.431111111111,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 2284.3411111111113,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 2345.0311111111114,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 2374.3844444444444,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 2313.717777777778,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 2326.7999999999997,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 2338.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 2309.0588888888888,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 2348.741111111111,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 2337.5333333333333,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 2319.3411111111113,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 2245.133333333333,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 2234.4,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 2260.0355555555557,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 2339.8433333333332,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 2302.5333333333333,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 2334.7099999999996,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 2383.2122222222224,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 2446.7099999999996,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 2464.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 2425.7644444444445,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 2433.651111111111,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 2439.7099999999996,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 2488.2433333333333,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 2478.9566666666665,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 2427.1566666666668,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 2441.141111111111,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 2400.1366666666668,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 2405.161111111111,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 2388.9366666666665,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 2348.3055555555557,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 2365.3777777777777,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 2482.588888888889,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 2456.183333333333,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 2422.8944444444446,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 2471.7933333333335,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 2547.4866666666667,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 2543.838888888889,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 2477.657777777778,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 2454.2000000000003,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 2434.187777777778,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 2414.02,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 2457.3577777777773,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 2531.5655555555554,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 2533.121111111111,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 2520.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 2519.121111111111,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 2492.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 2492.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 2414.362222222222,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 2372.93,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 2384.6588888888887,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 2367.9366666666665,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 2424.4500000000003,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 2475.7366666666667,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 2442.673333333333,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 2381.5244444444443,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 2405.596666666667,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 2433.9777777777776,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 2447.347777777778,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 2386.688888888889,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 2341.741111111111,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 2330.6111111111113,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 2341.6477777777777,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 2319.0377777777776,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 2318.7733333333335,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 2346.3066666666664,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 2349.7522222222224,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 2330.9066666666668,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 2366.3733333333334,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 2385.141111111111,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 2407.9066666666668,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 2428.9066666666668,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 2416.5011111111107,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 2430.3922222222227,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 2451.1977777777774,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 2464.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 2490.4988888888893,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 2475.402222222222,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 2389.076666666667,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 2381.757777777778,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 2400.6422222222227,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 2371.2344444444448,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 2377.083333333333,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 2371.133333333333,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 1669.1033333333335,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 1968.2055555555555,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 2373.35,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 2361.442222222222,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 2377.433333333333,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 2428.4088888888887,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 2452.208888888889,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 2464.8088888888888,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 2484.183333333333,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 2452.932222222222,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 2442.5255555555555,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 2444.7344444444448,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 2486.6022222222223,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 2483.0633333333335,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 2461.8688888888887,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 2492.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 2492.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 2479.9988888888893,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 2440.8066666666664,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 2403.278888888889,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 2350.592222222222,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 2377.9933333333333,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 2382.1855555555553,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 2426.5266666666666,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 2441.452222222222,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 2452.628888888889,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 2406.4444444444443,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 2508.66,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 2546.2811111111114,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 2500.228888888889,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 2421.362222222222,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 2432.1111111111113,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 2446.8966666666665,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 2522.1855555555553,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 2504.2811111111114,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 2477.5333333333333,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 2476.4444444444443,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 2438.9477777777774,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 2515.6366666666668,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 2496.3633333333332,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 2477.23,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 2464.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 2436.801111111111,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 2380.6299999999997,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 2381.6955555555555,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 2379.8677777777775,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 2361.932222222222,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 2386.237777777778,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 2394.7544444444443,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 2425.087777777778,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 2453.087777777778,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 2500.337777777778,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 2537.4533333333334,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 2489.2777777777774,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 2438.154444444445,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 2481.5466666666666,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 2504.8799999999997,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 2520.4666666666667,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 2493.1200000000003,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 2489.3866666666668,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 2409.68,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 2371.413333333333,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 2399.8722222222223,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 2380.661111111111,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 2412.2777777777774,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 2420.3355555555554,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 2309.02,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1855.3811111111113,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1998.4766666666665,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 2221.737777777778,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1536.6088888888887,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 2428.836666666667,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 2576.4666666666667,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 2552.2000000000003,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 2558.9355555555553,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 2548.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 2548.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 2534.2099999999996,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 2487.496666666667,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 2386.9533333333334,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 2379.7511111111107,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 2357.848888888889,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 2389.3411111111113,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 2374.61,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 2329.358888888889,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 2332.1744444444444,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 2361.807777777778,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 2352.241111111111,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 2425.5155555555552,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 2480.1077777777773,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 2501.092222222222,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 2454.9,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 2434.6077777777773,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 2424.3255555555556,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 2449.7744444444443,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 2462.358888888889,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 2408.2333333333336,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 2396.566666666667,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 2400.9844444444448,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 2474.258888888889,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 2452.3566666666666,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 2356.4411111111112,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 2352.4666666666667,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 2365.2844444444445,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 2343.1566666666668,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 2367.3688888888887,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 2378.8411111111113,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 2358.5255555555555,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 2337.2766666666666,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 2370.448888888889,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 2356.4177777777777,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 2390.7255555555557,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 2390.9511111111115,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 2410.2866666666664,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 2498.2766666666666,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 2502.5233333333335,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 2452.5433333333335,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 2496.402222222222,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 2551.01,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 2548.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 2543.597777777778,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 2468.3244444444445,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 2402.2133333333336,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 2362.08,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 2363.3944444444446,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 2354.1388888888887,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 2374.5944444444444,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 2368.1466666666665,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 2362.64,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 2471.2722222222224,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 2510.48,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 2481.437777777778,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 2566.557777777778,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 2542.0499999999997,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 2475.1299999999997,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 2436.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 2442.0744444444445,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 2455.008888888889,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 2525.491111111111,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 2559.2000000000003,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 2548.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 2532.895555555556,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 2493.6955555555555,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 2492.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 2475.503333333333,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 2454.0366666666664,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 2271.811111111111,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 2342.2388888888886,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 2406.7633333333333,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 2389.17,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 2373.9255555555555,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 2384.192222222222,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 2366.3033333333333,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 2378.288888888889,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 2420.141111111111,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 2464.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 2455.7555555555555,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 2388.2522222222224,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 2381.5555555555557,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 2387.7777777777774,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 2389.333333333333,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 2390.8811111111113,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 2408.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 2396.1933333333336,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 2353.26,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 2298.3799999999997,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 2244.5344444444445,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 2257.4066666666668,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 2268.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 2274.198888888889,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 2320.0566666666664,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 2364.39,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 2382.076666666667,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 2360.9988888888893,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 2380.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 2355.2666666666664,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 2403.5744444444445,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 2454.5422222222223,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 2371.8255555555556,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 2362.383333333333,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 2398.6744444444444,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 2341.4766666666665,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 2324.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 2316.883333333333,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 2296.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 2295.891111111111,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 2254.816666666667,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 2240.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 2285.702222222222,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 2352.4666666666667,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 2332.1977777777774,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1734.1132310185185,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1402.4338888888888,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1402.3441666666665,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1439.9661111111109,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1461.7827777777777,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1455.9886111111111,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1430.2052777777778,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1432.3680555555557,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1460.8052777777777,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1450.7280555555556,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1432.9252777777779,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 1433.5013888888889,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 1410.2113888888891,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 1372.7452777777778,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 1378.3741666666665,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 1360.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 1361.1380555555556,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 1351.7361111111109,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1363.4991666666665,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1379.2666666666669,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1378.7330555555557,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1381.6277777777777,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 1432.0563888888887,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1431.8486111111113,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 1392.5833333333335,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 1375.753333333333,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 1334.4669444444444,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 1330.8591666666666,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 1358.5880555555552,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 1344.1333333333332,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1331.3502777777776,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1346.1780555555556,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1373.9116666666666,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 1379.5783333333334,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1401.135277777778,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1520.8672222222222,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1491.5563888888887,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1456.3569444444445,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1447.8097222222223,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 1414.2725,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 1346.0930555555556,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 1338.726388888889,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 1334.523611111111,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 1347.8355555555556,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 1352.3358333333335,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 1358.3236111111112,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 1351.476388888889,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 1350.9522222222222,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 1360.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 1390.4063888888888,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1582.6433333333334,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1681.2291666666665,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1623.2166666666665,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1647.8713888888888,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 1623.7833333333335,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1687.944166666667,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1647.6163888888887,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1647.0544444444445,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1663.4641666666666,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1666.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1629.6766666666665,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1521.1788888888887,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 1463.4025,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 1462.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 1460.8619444444444,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 1414.9525,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 1374.4405555555556,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 1358.0119444444445,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 1350.0786111111113,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 1342.9952777777778,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 1331.1,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 1361.9880555555555,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 1393.145277777778,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1610.4525,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 1633.1333333333332,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1632.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 1655.2333333333331,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1678.7547222222222,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1635.9761111111109,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 1649.8358333333335,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1699.428611111111,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 1695.4855555555557,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1651.2666666666669,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1688.6477777777777,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1685.0116666666668,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 1560.6755555555555,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 1463.445,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 1431.4141666666665,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 1436.4905555555556,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 1444.145277777778,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 1434.5308333333335,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 1408.5019444444445,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 1377.533611111111,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 1524.385277777778,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1678.7027777777778,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1699.471111111111,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1655.2522222222221,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1665.9480555555554,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1687.3208333333332,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1652.9525,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1644.731111111111,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1679.0333333333335,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1651.5547222222222,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1692.312222222222,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1695.490277777778,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1625.1008333333334,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1519.006666666667,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 1477.3425,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 1462.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 1430.0919444444444,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 1372.8019444444446,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 1356.6,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 1346.145,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 1353.1952777777778,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 1339.345,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 1344.9266666666665,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 1381.9725,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1615.7744444444443,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1679.8833333333332,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1583.4555555555555,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1607.095,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 1613.5455555555557,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 1631.3294444444443,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 1680.1619444444448,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1631.3011111111111,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1682.0366666666666,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1714.7144444444443,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1649.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1615.2361111111109,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1486.5508333333332,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 1438.9319444444443,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 1379.5877777777778,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 1371.9802777777777,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 1338.1880555555556,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 1359.9622222222224,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 1360.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 1360.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 1351.5472222222222,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 1348.4825,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 1482.721111111111,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 1556.0383333333334,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1706.7622222222221,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1633.270277777778,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1675.9544444444443,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 1636.2925,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 1646.6294444444443,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1673.0833333333335,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1644.0086111111113,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1647.7061111111109,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1664.9233333333334,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1610.92,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1467.4305555555557,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 1426.3566666666666,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 1379.7294444444447,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 1360.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 1347.8780555555556,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 1348.0197222222223,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 1385.0891666666666,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 1389.41,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1385.4338888888888,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1395.0625,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 1421.4219444444448,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 1463.4213888888887,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1677.6780555555556,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1718.4166666666665,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1684.5630555555556,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1686.4,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 1574.0677777777778,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 1476.5586111111108,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 1423.4430555555557,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 1377.2361111111109,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 1341.611666666667,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 1343.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 1367.1258333333335,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1400.4411111111112,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1427.3530555555556,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1434.9180555555556,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1472.9697222222221,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 1481.0919444444444,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1620.8272222222222,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 1724.9333333333334,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1658.6097222222222,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1667.5205555555553,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1665.697777777778,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 1560.7416666666668,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 1482.7683333333334,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 1472.3936111111109,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 1419.3961111111112,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 1385.684166666667,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 1377.5619444444444,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 1391.9222222222222,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1390.6944444444443,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1394.6516666666666,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1426.4841666666666,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 1415.5333333333335,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 1422.3238888888889,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 1516.1875,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1681.0969444444445,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1695.5611111111111,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1646.114722222222,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1515.04,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 1460.045,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 1422.9,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 1205.5408333333335,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 402.8952777777777,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 1277.5594444444444,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 1370.7619444444445,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1386.6947222222223,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1441.831388888889,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1446.8133333333335,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1489.455,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 1664.2386111111111,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1665.0319444444444,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1721.6938888888888,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1697.7616666666668,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1747.6094444444448,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1670.273611111111,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 1632.6044444444447,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 1495.4852777777778,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 1478.8394444444443,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 1445.533611111111,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 1424.1797222222222,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 1401.0833333333335,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 1399.2227777777778,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 1404.2094444444444,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 1402.3725,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1417.6677777777777,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1444.8725,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 1465.8769444444445,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1652.7730555555556,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1683.1133333333332,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1648.2822222222221,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1632.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1642.2,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1643.7677777777778,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1631.697777777778,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1518.0716666666665,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 1451.9275,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 1401.928611111111,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 1381.3916666666669,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 1377.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 1389.3297222222222,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 1410.575,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1415.3822222222223,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1443.7155555555555,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1445.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1440.8963888888889,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1442.1572222222221,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1496.6516666666666,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1646.1241666666665,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1684.553611111111,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1670.5380555555557,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1671.1,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1690.0597222222223,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1640.4244444444444,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 1535.3125,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 1471.7891666666665,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 1479.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 1467.8225,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 1472.8941666666665,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 1463.8558333333335,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 1471.9166666666665,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 1423.8019444444446,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 1394.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 1396.6727777777778,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 1407.8833333333332,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1409.1394444444443,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1428.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1434.072777777778,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1471.8741666666665,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1482.4802777777777,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 1636.575833333333,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1683.2125,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1663.1194444444443,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1705.4541666666669,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1729.2305555555556,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 1676.1952777777778,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1568.231111111111,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 1502.0019444444445,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 1496.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 1496.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 1496.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 1474.5847222222221,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 1414.8533333333335,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 1411.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 1411.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 236978.2071543665,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H03',
              coreData: {
                totalConsumption: 0.0,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: false,
                  totalSavings: 448938.91874999995,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H04',
              coreData: {
                totalConsumption: 0.0,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: false,
                  totalSavings: 284106.7634971065,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H02',
              coreData: {
                totalConsumption: 1962.3583333333331,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 4.733333333333333,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 243.24999999999997,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 7.058333333333334,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 186.5,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 84.5,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 62.82916666666667,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 72.42083333333333,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 104.8125,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 29.95,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 167.05416666666665,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 12.458333333333336,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 122.74166666666666,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 72.6625,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 204.97083333333333,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.1125,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 291.4625,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 294.8416666666667,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 563808.4381401143,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H05',
              coreData: {
                totalConsumption: 221436.96138888889,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 447.1472222222222,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 909.901388888889,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1278.2819444444444,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 595.1322222222221,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 317.4844444444444,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 272.25972222222225,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 273.70000000000005,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 552.33,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1556.5766666666666,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1717.0141666666666,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1685.427222222222,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 812.4677777777778,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 289.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 289.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 289.85,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 289.2833333333333,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 289.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 479.1875,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1500.5475,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1297.6666666666665,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 541.1855555555555,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 287.29999999999995,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 272.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 322.1358333333333,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1257.7072222222223,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 644.1677777777778,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 313.34305555555557,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 352.44305555555553,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 398.04555555555555,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 466.0361111111111,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 521.0075,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 570.6002777777778,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 611.1405555555556,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 628.4191666666667,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 629.8499999999999,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 626.4688888888888,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 601.5355555555557,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 595.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 590.7688888888888,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 557.6377777777777,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 511.7661111111111,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 448.5733333333334,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 405.20444444444445,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 377.4236111111111,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 355.6211111111112,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 340.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 340.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 367.12444444444446,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 459.65166666666664,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 571.0630555555556,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 667.4294444444444,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 726.1125,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 763.276388888889,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 778.5716666666667,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 796.6058333333333,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 799.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 787.9783333333332,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 782.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 770.6997222222221,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 753.9783333333332,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 738.1163888888889,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 713.4994444444445,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 683.7494444444445,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 663.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 653.1211111111111,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 624.5374999999999,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 588.5588888888889,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 539.0369444444444,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 492.22555555555556,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 467.8211111111111,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 443.4497222222222,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 456.6908333333333,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 447.70444444444445,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 454.4241666666666,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 479.31972222222225,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 494.0908333333333,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 516.1908333333333,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 532.9027777777777,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 575.8466666666667,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 594.4286111111111,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 608.2694444444445,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 611.7119444444444,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 603.8305555555555,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 574.1325,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 546.8852777777778,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 506.2222222222223,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 448.09638888888884,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 400.5011111111111,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 367.53055555555557,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 353.3733333333333,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 340.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 493.01416666666665,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 1492.7747222222224,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 1031.4891666666667,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 335.1786111111111,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 379.1,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 487.5033333333333,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 581.6833333333334,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 674.9566666666667,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 719.8319444444445,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 735.8261111111111,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 743.6886111111111,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 761.5386111111111,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 776.838611111111,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 782.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 771.2947222222222,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 749.8322222222222,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 722.8494444444444,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 692.8775,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 659.4441666666665,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 617.865,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 575.9316666666666,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 517.565,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 470.4702777777778,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 336.3733333333333,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 420.7736111111111,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 427.12027777777774,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 423.71555555555557,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 497.3963888888889,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 592.365,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 670.8483333333332,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 743.2447222222223,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 788.29,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 821.3077777777778,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 833.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 833.7791666666667,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 850.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 862.1030555555554,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 860.8422222222222,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 840.4469444444445,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 811.901111111111,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 785.1638888888889,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 827.3238888888889,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 715.0152777777778,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 672.6002777777778,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 615.6502777777778,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 558.7805555555556,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 500.33833333333337,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 445.9288888888889,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 663.5052777777777,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 1561.1525,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 1550.2394444444446,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 602.3525,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 495.02583333333337,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 600.7091666666666,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 670.6688888888889,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 729.0308333333332,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 757.265,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 765.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 774.8269444444445,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 785.3055555555554,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 799.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 799.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 781.9102777777778,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 755.7444444444444,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 723.35,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 682.465,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 634.1047222222222,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 574.6991666666667,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 505.0133333333334,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 452.77611111111116,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 835.89,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 1646.3508333333334,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 1622.6405555555552,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 1353.0394444444444,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 1667.1333333333332,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1239.5313888888888,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 406.50305555555553,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 492.4522222222222,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 606.3758333333334,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 695.9044444444444,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 753.6288888888889,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 790.9580555555556,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 809.6580555555556,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 834.4827777777778,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 863.2080555555556,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 847.6672222222222,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 820.1886111111111,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 791.1752777777779,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 764.9386111111111,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 739.0466666666667,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 715.3647222222223,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 686.1766666666667,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 644.2480555555555,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 590.3580555555554,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 517.6547222222222,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 737.0019444444446,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 1611.685,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 1597.9008333333334,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 653.7113888888888,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 519.0761111111111,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 625.2788888888888,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 696.6175,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 753.4211111111111,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 779.8938888888888,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 782.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 794.343888888889,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 804.5391666666667,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 807.6275,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 778.005,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 743.853888888889,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 682.6208333333334,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 590.5374999999999,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 514.3491666666666,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 472.9022222222222,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 1064.7713888888889,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 1727.2708333333335,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 1700.4344444444444,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 1018.6352777777777,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 989.4425,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 1430.7105555555556,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 1458.118333333333,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 514.3208333333333,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 457.6163888888889,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 561.5855555555556,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 632.8297222222222,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 625.4536111111111,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 618.8,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 664.4166666666667,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 695.725,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 710.1797222222224,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 714.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 699.5547222222224,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 655.3594444444444,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 577.1547222222222,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 498.8272222222222,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 440.58805555555557,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 716.9230555555555,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 1586.0055555555557,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 1649.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 1321.5091666666665,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 612.3494444444444,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 400.20833333333337,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 312.27583333333337,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 831.4369444444445,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1260.6113888888888,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 462.8438888888889,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 452.115,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 581.0458333333333,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 678.9563888888888,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 723.4775,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 731.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 741.0441666666667,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 748.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 764.5608333333334,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 765.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 759.2105555555556,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 735.8497222222222,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 765.9444444444446,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 676.1702777777778,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 599.9961111111111,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 543.7638888888889,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 498.87444444444446,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 442.78388888888884,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 693.4205555555554,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 1591.3416666666665,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 1700.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 1643.503333333333,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 738.8483333333332,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 434.75611111111107,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 552.0324999999999,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 643.1572222222222,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 714.4391666666666,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 758.03,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 773.0466666666667,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 782.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 794.9672222222222,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 808.8127777777778,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 782.6422222222222,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 759.5080555555556,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 729.0969444444444,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 692.2636111111111,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 648.2430555555555,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 599.7977777777778,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 536.2225,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 473.60583333333335,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 590.5705555555555,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 1061.9616666666668,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 1261.1261111111112,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 719.5297222222222,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 423.4558333333334,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 162.48222222222222,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 778189.3143880864,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H01',
              coreData: {
                totalConsumption: 755192.1885524327,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 2148.641885766093,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 2324.7755555555555,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 2461.391111111111,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 2372.882777777778,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 2304.6472222222224,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 2267.1422222222222,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 2181.2122222222224,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 2203.6155555555556,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 2195.4616666666666,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 2418.0144444444445,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 2476.398888888889,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 2471.834444444444,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 2456.075555555555,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 2484.8777777777777,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 2166.7244444444445,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 2088.031111111111,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 2022.7927777777782,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 2011.1072222222222,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 2142.3494444444445,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 2351.83,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 2425.9300000000003,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 2366.635555555555,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 2362.323888888889,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 2442.2233333333334,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 2336.331111111111,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 2251.1666666666665,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 2344.983333333333,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 2416.6133333333332,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 2436.799444444445,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 2550.1738888888885,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 2499.842222222222,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 2372.6877777777777,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 2187.8205555555555,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 2135.0044444444447,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 2088.6016666666665,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 2037.0566666666666,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 1994.6405555555557,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 2022.8216666666665,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 2074.027222222222,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 2389.1183333333333,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 2431.7944444444443,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 2323.1505555555555,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 2456.581111111111,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 2416.266666666667,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 2437.9333333333334,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 2404.971111111111,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 2302.2855555555557,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 2370.7955555555554,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 2447.9,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 2482.5522222222226,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 2403.7144444444443,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 2402.385555555555,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 2196.1261111111107,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 2138.0666666666666,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 2082.6072222222224,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 2080.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 2077.8333333333335,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 2039.7072222222218,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 2043.5927777777779,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 2054.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 2046.2144444444443,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 2028.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 2104.201666666667,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 2248.9783333333335,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 2394.0872222222224,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 2459.592777777778,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 2436.2144444444443,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 2415.876666666667,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 2366.8883333333333,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 2386.778333333333,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 2350.8694444444445,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 2415.7466666666664,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 2446.145,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 2490.778333333333,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 2473.4883333333332,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 2438.9011111111113,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 2263.8777777777777,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 2235.1405555555557,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 2223.902777777778,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 2157.2055555555557,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 2219.9016666666666,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 2173.2316666666666,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 2113.395555555556,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1552.1205555555557,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 2259.1544444444444,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 2309.3055555555557,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 2282.8,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 2307.463888888889,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 2327.7872222222227,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 2399.316111111111,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 2363.0533333333333,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 2340.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 2323.1433333333334,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 2291.51,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 2304.423333333333,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 2334.8,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 2306.683888888889,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 2307.016111111111,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 2312.757777777778,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 2243.9661111111113,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 2203.9911111111114,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 2147.7155555555555,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 2137.1422222222222,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 2152.4316666666664,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 2138.435,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 2138.5577777777776,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 2154.3383333333336,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 2264.0366666666664,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 2262.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 2244.1466666666665,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 2210.938888888889,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 2175.9327777777776,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 2177.572222222222,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 2152.0055555555555,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 2136.6800000000003,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 2112.7383333333332,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 2080.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 2089.020555555556,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 2121.000555555556,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 2190.6877777777777,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 2253.6872222222223,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 2267.546666666667,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 2307.406111111111,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 2348.3994444444443,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 2328.6683333333335,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 2303.6,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 2269.893888888889,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 2275.3394444444443,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 2283.8761111111107,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 2192.4283333333333,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 2184.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 2194.8261111111115,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 2166.3416666666667,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 2169.5916666666667,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 1544.1544444444444,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 1785.2538888888887,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 2174.7988888888885,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 2169.8083333333334,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 2167.858333333333,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 2220.616666666667,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 2259.176111111111,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 2289.625,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 2319.9511111111115,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 2348.1177777777775,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 2355.8383333333336,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 2301.1227777777776,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 2256.1788888888887,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 2211.986111111111,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 2164.9333333333334,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 2170.2561111111113,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 2132.563333333333,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 2143.8661111111114,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 2220.6961111111113,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 2212.47,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 2251.4627777777773,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 2244.6594444444445,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 2300.2922222222223,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 2326.1405555555557,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 2294.211111111111,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 2296.5222222222224,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 2287.4150000000004,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 2285.262777777778,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 2276.4444444444443,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 2258.1,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 2253.911111111111,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 2205.6738888888885,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 2254.4888888888886,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 2262.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 2262.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 2235.436666666667,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 2201.9183333333335,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 2184.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 2184.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 2175.058888888889,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 2172.141111111111,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 2184.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 2224.6827777777776,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 2307.6227777777776,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 2297.6922222222224,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 2298.6672222222223,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 2327.101111111111,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 2384.467222222222,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 2358.6983333333333,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 2289.032777777778,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 2284.2733333333335,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 2200.733888888889,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 2179.9194444444443,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 2184.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 2190.572222222222,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 2293.33,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 2308.3016666666667,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 2318.391111111111,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 2353.4911111111114,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 2381.354444444444,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 2343.6616666666664,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 2325.2161111111113,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 2309.905,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 2309.8616666666667,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 2260.8516666666665,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 2164.89,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 2151.702222222222,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 2131.8122222222223,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 2197.4622222222224,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 2179.847222222222,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 2096.2283333333335,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 2090.168888888889,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 2103.6311111111113,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 2134.628888888889,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 2157.364444444445,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 2205.031111111111,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 2246.855,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 2292.5716666666667,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 2364.9238888888885,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 2354.3,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 2268.926111111111,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 2261.7761111111113,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 2236.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 2225.8166666666666,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 2210.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 2188.766666666667,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 2162.55,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 2201.55,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 2189.438333333333,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 2110.557222222222,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 2106.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 2106.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 2107.733333333333,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 2078.057222222222,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 2080.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 2080.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 2080.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 2103.3783333333336,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 2154.9305555555557,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 2241.7633333333333,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 2276.56,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 2259.6022222222223,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 2292.5355555555557,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 2269.8,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 2323.063888888889,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 2338.0716666666667,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 2293.438333333333,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 2313.7544444444447,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 2314.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 2334.366666666667,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 2294.7455555555553,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 2246.075,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 2159.105,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 2158.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 2155.2194444444444,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 2132.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 2123.7594444444444,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 2143.013888888889,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 2170.7472222222223,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 2222.646111111111,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 2284.0133333333333,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 2334.532777777778,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 2376.0316666666668,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 2406.733333333333,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 2411.7744444444443,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 2377.5338888888887,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 2362.815,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 2340.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 2309.608888888889,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 2239.0333333333333,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 2271.6994444444445,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 2307.9405555555554,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 2288.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 2271.8077777777776,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 2262.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 2257.9483333333333,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 2225.015,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 2048.7205555555556,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 2073.9766666666665,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 2148.6183333333333,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 2162.376666666667,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 706.03,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 4.781111111111111,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 227.92611111111108,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1.3,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 286707.95224923757,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
          ],
        },
        {
          name: 'M01',
          coreData: {
            totalConsumption: 5980793.6931575835,
            tendance: 4.432910498702796,
          },
          technologiesData: {
            MARVEL: {
              averageSavings: 761591.5123850309,
            },
            UV: {
              totalConsumption: 0.0,
              tendance: 0,
              averageSavings: 0,
              usageLimit: 0,
            },
            WW: {},
            WMist: {},
          },
          hoods: [
            {
              name: 'H10',
              coreData: {
                totalConsumption: 131.63333333333333,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 11.201666666666668,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 10.431666666666667,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 68.49944444444445,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 41.50055555555556,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 1612316.2,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H09',
              coreData: {
                totalConsumption: 0.0,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: false,
                  totalSavings: 0,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H12',
              coreData: {
                totalConsumption: 692886.5000134273,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 944.712235649547,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1634.9972222222223,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1738.0794444444443,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1598.5383333333332,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1189.5094444444444,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 975.6083333333332,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1024.87,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1169.9783333333332,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1061.2616666666668,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1513.7283333333335,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1547.2477777777779,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1591.3944444444444,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1318.0077777777778,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 895.4183333333334,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 857.2666666666668,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 842.3983333333333,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 836.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 849.4016666666668,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 845.5333333333333,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 857.6272222222221,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 875.0622222222222,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 862.9316666666667,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 902.8066666666667,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 977.7716666666668,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1398.65,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1610.7238888888887,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1539.0466666666666,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1309.99,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1081.7644444444443,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1276.99,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1287.0855555555554,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1466.8744444444444,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1454.7072222222223,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1406.9244444444444,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1363.6211111111108,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 904.7927777777778,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 856.5516666666667,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 851.3938888888889,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 856.906111111111,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 1100.4094444444445,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 1503.4616666666668,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1733.7772222222222,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1629.2833333333335,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1619.921111111111,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1426.7061111111109,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 931.8161111111111,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1003.1572222222222,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1425.82,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1506.3338888888889,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1497.815,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1490.8605555555553,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1446.5611111111111,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 909.4188888888889,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 857.0955555555555,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 836.55,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 846.0894444444443,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 846.2788888888888,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 857.6333333333333,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 857.2544444444444,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 857.6333333333333,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 878.0077777777778,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1107.0216666666668,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 971.4772222222222,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1186.026111111111,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 1432.75,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1350.5005555555556,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1096.5044444444443,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 892.3138888888889,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 990.5316666666668,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1007.4288888888889,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1107.7244444444443,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1207.2622222222224,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1236.5772222222222,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1167.802777777778,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 886.05,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 872.1166666666667,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 1013.815,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1038.7788888888888,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1363.4255555555555,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1440.45,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1388.9394444444445,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1266.4483333333333,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 929.8666666666667,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1066.2727777777777,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1067.6294444444443,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1282.9055555555556,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1357.565,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1409.808888888889,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1320.8066666666666,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 888.2927777777778,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 855.6288888888889,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 853.0316666666668,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1049.0088888888888,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1796.2144444444443,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1730.691111111111,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1673.1,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1598.7033333333334,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1447.7161111111109,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 989.6761111111111,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 969.3444444444444,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1102.7683333333332,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1461.136111111111,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1471.8244444444445,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1431.2894444444444,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1305.101111111111,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 889.9794444444444,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 858.7333333333332,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 865.1377777777778,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 864.7894444444445,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 870.2772222222222,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 880.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 872.8622222222222,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 865.5105555555555,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 858.1894444444443,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1067.8188888888888,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1667.6488888888891,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1701.5044444444443,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1731.2655555555557,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1635.1133333333335,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1103.5566666666666,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 898.4005555555556,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1158.788888888889,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1372.7083333333335,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1471.3416666666665,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1490.1333333333334,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1506.8044444444445,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1435.9155555555556,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 907.5122222222224,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 597.1961111111111,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 717.6583333333333,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 874.1944444444446,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1438.5738888888889,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1550.3827777777778,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1515.0361111111113,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1510.0861111111112,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1399.3405555555555,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 910.7877777777777,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 877.9283333333333,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 990.2566666666665,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1082.2227777777778,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1190.6033333333332,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1568.6916666666666,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1550.2055555555555,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1121.7311111111112,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 861.1044444444444,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 880.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 865.535,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 853.9666666666667,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 857.2666666666668,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 870.2588888888889,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 860.7683333333333,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 951.2372222222222,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1267.6827777777778,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1471.1888888888889,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1478.8827777777778,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1322.3344444444444,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1127.1944444444446,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 883.9172222222222,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 965.9588888888889,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1198.5783333333334,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1411.3916666666667,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1533.4794444444447,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1569.4983333333334,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1365.4911111111112,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 888.6105555555556,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 877.2255555555556,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 876.3394444444443,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 878.3744444444445,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1004.85,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1266.7783333333332,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1222.8883333333333,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1296.453888888889,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1054.0199999999998,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 960.0127777777778,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 858.1588888888889,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1302.503888888889,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1299.326111111111,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1498.646111111111,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1551.3727777777779,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1562.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1455.8316666666665,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 919.2822222222222,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 872.0861111111111,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 865.9138888888889,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 854.3333333333335,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 867.9,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 849.4138888888889,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 857.0527777777778,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1185.9894444444444,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1469.655,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1540.2322222222222,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1722.215,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1582.753333333333,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1310.4788888888888,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 999.3744444444445,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1330.5355555555557,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1215.9827777777778,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1494.1544444444444,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1562.1527777777778,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1570.7327777777778,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1530.8394444444443,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 934.6333333333333,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 852.5,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 855.6533333333333,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 846.0466666666666,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 842.8138888888889,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 846.34,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 944.4538888888889,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1186.466111111111,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1661.073333333333,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1813.0261111111113,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1609.0738888888889,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1004.5383333333334,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 884.62,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1092.5933333333332,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 974.4472222222222,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1367.6055555555556,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1543.666666666667,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1565.4405555555554,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1376.4422222222222,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 892.6377777777778,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 858.7394444444445,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 853.2394444444445,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 857.1261111111111,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 857.0405555555554,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 911.6127777777776,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 1359.49,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1600.7322222222222,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1705.3055555555557,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1508.631666666667,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 942.7733333333333,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 879.0161111111113,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 992.7866666666665,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1046.9005555555557,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1433.691111111111,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1567.1272222222221,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1589.041666666667,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1380.7444444444443,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 872.8011111111111,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 868.6333333333333,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 860.3344444444446,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 880.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 860.6705555555554,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 836.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 836.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 839.1105555555556,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 1407.3522222222223,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1703.5577777777776,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1771.3727777777779,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1661.171111111111,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1293.312777777778,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 950.6261111111111,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 866.8366666666666,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1026.85,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1073.1172222222222,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1339.751111111111,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1553.5605555555555,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1525.425,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1411.1472222222221,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 920.3394444444443,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 857.2544444444444,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 857.2788888888888,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 804.4422222222222,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 844.4455555555556,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 858.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1026.3672222222222,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1088.7372222222223,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1527.8511111111109,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1497.375,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1163.812222222222,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 990.2077777777778,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 665.8605555555556,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 783.1144444444445,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 771.7172222222223,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1262.5738888888889,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1559.5738888888889,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1579.2333333333333,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1325.2311111111112,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 702.9977777777779,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 677.3616666666667,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 666.2272222222222,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 680.7777777777778,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 672.9555555555556,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 677.2394444444444,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 814.7638888888889,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 1159.1555555555556,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1433.6605555555557,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1583.3888888888891,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1391.3716666666667,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1407.3766666666666,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1355.1022222222223,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 1371.5227777777777,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1420.9555555555555,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1473.8655555555556,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1507.513333333333,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1560.0383333333332,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1280.7544444444443,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 685.4161111111111,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 674.4283333333333,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 664.2777777777778,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 887.088888888889,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 1441.5805555555555,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1479.628333333333,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1607.815,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1617.556111111111,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1375.055,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1074.5655555555556,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 722.8161111111111,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 803.0977777777778,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 962.445,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1367.9661111111109,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1546.2394444444444,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1459.9811111111112,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1145.2283333333332,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 679.5922222222222,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 681.5111111111111,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 671.8494444444445,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 671.9838888888889,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 672.2222222222222,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 663.1838888888889,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 674.2327777777778,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 1375.7516666666666,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1646.975,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1712.1438888888888,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1681.6188888888887,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1384.5638888888889,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 804.4177777777778,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 678.8955555555555,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 771.5216666666668,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 884.1127777777776,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 999.1727777777778,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1315.7711111111112,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1423.3633333333335,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1280.6627777777776,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 720.8972222222222,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 661.8394444444443,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 689.8283333333333,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 1220.1444444444444,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1188.9288888888889,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1285.0199999999998,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1515.9038888888888,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1328.5372222222222,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 899.0544444444444,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 699.5083333333333,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 781.4888888888889,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1140.822222222222,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1302.956111111111,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1551.6416666666667,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1566.302222222222,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1204.1883333333333,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 684.3344444444446,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 668.4333333333333,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 1240.415,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 966.5272222222222,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1291.675,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1394.6961111111113,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1273.0055555555555,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1246.2755555555557,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 796.2961111111111,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 706.8783333333334,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 726.6722222222223,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 834.0383333333334,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1064.58,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1206.1316666666667,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 984.8972222222222,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 664.6383333333333,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 640.3038888888889,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 657.0605555555554,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 648.5355555555556,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 654.5977777777778,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 664.5955555555556,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 758.8716666666666,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 815.485,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 1031.5066666666667,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1287.6294444444443,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 1131.2338888888887,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 755.9933333333335,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 666.2516666666667,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 803.6477777777778,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 691.0933333333332,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 962.0416666666665,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1191.348888888889,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1185.1888888888889,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 879.0588888888889,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 681.0711111111111,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 671.7272222222222,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 661.4666666666667,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 702.8938888888889,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 880.3605555555556,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 634.8038888888889,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 547.8,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 493.4477777777778,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 531.0127777777778,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 622.1538888888889,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1039.5611111111111,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1245.701111111111,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 720.6894444444443,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 757.1666666666665,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 783.8416666666667,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 839.1227777777779,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 776.9727777777778,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 658.7227777777778,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 647.625,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 657.8,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 662.2061111111111,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 652.3,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 655.1477777777777,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 640.5727777777778,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 652.3855555555556,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 656.975,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 640.2672222222222,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 770.3666666666667,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 629.468888888889,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 655.8444444444444,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 808.39,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 636.2888888888888,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 551.1122222222222,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 591.4944444444444,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 644.9544444444444,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 646.5188888888888,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 629.7622222222221,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 633.0377777777778,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 631.5833333333333,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 620.0394444444445,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 617.3811111111112,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 633.2455555555556,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 616.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 616.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 614.1544444444445,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 618.2,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 618.2855555555556,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 768.5272222222222,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 826.3688888888889,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 722.6938888888888,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 665.3288888888889,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 629.475,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 650.8272222222223,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 629.5666666666666,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 652.8316666666667,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 694.9127777777778,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 708.3144444444443,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 665.7016666666666,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 635.7266666666667,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 617.5522222222222,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 643.9522222222223,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 656.2538888888889,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 651.646111111111,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 655.8933333333333,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 659.3461111111111,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 663.5933333333332,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 579.3883333333333,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 598.18,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 644.8016666666666,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 550.8677777777779,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 604.5538888888889,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 638.0794444444444,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 966.343888888889,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 723.9711111111112,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 643.9583333333333,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 1158.4711111111112,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1552.9188888888891,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 743.6855555555557,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 643.0538888888889,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 640.64,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 641.1594444444444,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 624.1277777777777,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 628.0266666666666,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 492.98333333333335,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 426.8366666666667,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 604.8472222222223,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 615.5783333333334,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 617.4666666666667,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 631.7666666666667,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 624.146111111111,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 635.0666666666666,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 629.9272222222222,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 664.1983333333333,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 659.3705555555556,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 573.0755555555555,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 613.3233333333334,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 654.7933333333333,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 587.1922222222222,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 631.3022222222222,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 705.3016666666667,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 678.8711111111111,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 638.2933333333333,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 640.9944444444444,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 642.0272222222222,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 559.2155555555556,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 185.46611111111113,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 609.4794444444444,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 686.5344444444444,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 1346.9133333333334,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1719.6727777777778,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1725.111666666667,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1783.4422222222224,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1601.8383333333334,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1501.8544444444447,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1086.463888888889,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 746.0933333333332,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 650.7111111111112,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 571.1444444444445,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 634.6144444444444,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 633.7833333333333,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 634.3944444444445,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 637.6333333333333,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 638.4277777777778,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 648.5661111111111,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 657.14,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 654.8605555555556,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 644.9055555555556,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 642.0394444444445,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 620.345,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 643.6161111111111,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 545.4472222222222,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 569.36,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 580.1583333333333,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 435.9422222222222,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 466.90111111111116,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 589.2944444444445,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 611.2394444444444,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 547.03,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 658.3988888888889,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 665.4816666666667,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 659.9511111111111,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 650.5216666666668,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 650.045,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 574.1877777777777,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 386.35666666666674,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 275.66611111111115,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 477.58333333333326,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 683.6744444444444,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 661.4238888888889,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 628.0022222222221,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 636.5822222222223,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 638.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 643.9155555555556,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 657.3783333333333,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 650.1427777777778,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 658.5272222222222,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 659.6333333333333,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 644.5511111111111,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 647.9977777777779,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 684.9822222222222,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 701.7511111111111,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 683.5094444444444,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 689.975,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 653.0333333333333,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 631.7238888888888,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 585.8416666666667,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 589.6855555555555,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 632.9522222222223,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 660.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 943913.4999865727,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H08',
              coreData: {
                totalConsumption: 993165.9192364955,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 998.7371601208459,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1419.7666666666667,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1818.793333333333,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1772.9266666666665,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1092.32,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1252.2133333333334,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1128.2799999999997,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1474.3066666666666,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1719.02,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1918.3333333333335,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 2300.1,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 2243.14,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 2388.9933333333333,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1895.7133333333334,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 974.22,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 814.18,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 812.98,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 801.02,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 845.2333333333335,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 860.1866666666667,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 822.9933333333333,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 860.5666666666667,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1260.4800000000002,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 961.5,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1008.7533333333334,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1105.0533333333333,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1281.5,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 952.0066666666667,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 932.5533333333333,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1742.6933333333334,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 2298.32,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 2377.38,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 2388.62,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 2273.1,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1172.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 804.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 816.4066666666668,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 822.8,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 815.2,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 814.3933333333333,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 848.0266666666665,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 864.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 864.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 853.3933333333333,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 821.3866666666668,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 838.6133333333332,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 920.7733333333333,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1998.0733333333335,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 2391.1866666666665,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 2203.833333333333,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1829.573333333333,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1507.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1137.0133333333333,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 2153.5933333333332,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 2304.2533333333336,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 2364.786666666667,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 2364.98,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1569.1466666666665,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 874.1666666666667,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 816.8,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 817.2066666666667,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 820.4066666666668,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 829.4066666666668,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 835.2066666666667,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 896.2333333333335,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1144.8266666666668,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 943.9533333333333,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1161.22,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 885.7866666666667,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1053.6666666666667,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1100.2533333333333,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 870.4133333333333,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1208.9666666666665,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 2194.5933333333332,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 2261.02,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 2355.2066666666665,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 2206.5333333333333,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1086.7333333333333,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 848.8,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 827.8,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 834.6,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 840.8,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 825.4,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 833.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 831.2266666666667,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 833.9933333333335,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 837.6066666666667,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 853.8066666666667,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 863.5533333333333,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 967.1666666666667,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1242.6066666666668,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1321.3866666666665,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1040.04,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 988.3933333333333,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 958.42,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 909.2066666666666,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1235.4333333333334,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 2227.0333333333333,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 2246.206666666667,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 2297.0066666666667,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 2288.78,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1703.36,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 880.8333333333333,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 835.4066666666668,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 828.1933333333334,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 834.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 834.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 827.4066666666665,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 816.8066666666667,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 880.9133333333333,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1398.5666666666666,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 969.2866666666667,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1029.86,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1341.8933333333332,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1009.44,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 867.2333333333332,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 869.3399999999998,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1158.6933333333334,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1522.0333333333335,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 2192.8333333333335,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 2341.0933333333332,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 2400.786666666667,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 2024.0866666666666,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 944.0133333333333,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 819.8133333333333,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 828.9933333333333,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 847.7933333333333,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 863.2,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 858.4,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 847.82,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 882.4866666666667,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1644.2133333333334,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1830.7,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 2196.746666666667,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1944.9133333333334,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1179.5533333333335,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 887.6466666666665,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 890.4066666666665,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1529.82,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 2159.96,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 2231.18,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 2276.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 2249.233333333333,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1504.24,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 994.68,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 818.2133333333333,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 818.4,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 823.6000000000001,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 826.0066666666667,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 578.4533333333334,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 694.7466666666666,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 837.78,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 816.6133333333332,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 948.7533333333334,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1463.2133333333331,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1386.5933333333335,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1297.4933333333333,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1344.14,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1220.4066666666665,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1971.2866666666666,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1897.2466666666667,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 2103.393333333333,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1976.68,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 2251.2933333333335,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 2295.866666666667,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1651.3066666666666,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 882.2,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 823.8133333333333,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 816.4,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 816.4066666666668,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 832.5866666666667,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 839.2,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 845.78,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 1335.6666666666665,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 2058.8133333333335,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 2184.98,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 2099.7999999999997,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1716.0933333333335,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1704.6866666666665,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1033.0266666666666,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 960.38,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1913.1333333333334,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 2188.8866666666668,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 2324.626666666667,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 2190.18,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1445.4933333333333,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 879.64,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 834.8,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 836.18,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 839.6,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 818.6333333333334,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 829.5333333333334,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1118.26,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 2019.52,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 2065.0333333333333,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1312.56,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 957.4266666666667,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1134.08,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1242.2,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1581.9533333333334,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 2216.9266666666667,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1928.0666666666666,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 2208.42,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 2320.7466666666664,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1688.7066666666665,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 868.9666666666667,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 820.9599999999998,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 823.8333333333333,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 836.4066666666665,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 820.9666666666667,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 838.3933333333333,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 839.5933333333332,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 877.2533333333333,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1809.773333333333,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 2204.8533333333335,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1757.5066666666667,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1664.0866666666666,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1858.7933333333335,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1072.0266666666666,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 861.6333333333333,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1667.9533333333331,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 2323.2733333333335,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 2370.2133333333336,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 2363.273333333333,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 2281.38,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1301.6466666666665,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 829.28,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 817.36,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 838.7933333333333,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 839.2066666666667,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 839.8333333333335,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 823.2,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 994.1933333333333,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1316.3333333333335,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1327.9866666666667,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1661.2,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1157.44,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 933.12,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 913.6866666666666,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1578.3666666666668,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 2174.4733333333334,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 2159.6133333333332,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 2317.453333333333,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 2058.12,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1093.1933333333334,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 814.08,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 801.36,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 832.5533333333333,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 861.36,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 844.2466666666667,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 838.8066666666667,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 821.44,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 823.5866666666667,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 830.96,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 916.1066666666666,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1466.34,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 1018.1666666666667,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1469.12,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1495.9866666666667,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 988.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 852.9,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 878.26,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 1752.4066666666668,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 2209.4733333333334,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 2165.6933333333336,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 2309.7466666666664,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 2344.4066666666668,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1686.753333333333,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 855.3666666666666,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 816.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 811.6000000000001,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 824.1600000000002,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 840.5466666666666,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 842.6533333333334,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 893.2666666666665,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 1601.6466666666668,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1757.3333333333335,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1882.1466666666668,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1394.2133333333331,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1072.2733333333333,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 891.4133333333333,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 820.3999999999999,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1452.4866666666667,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 2212.8,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 2150.5133333333333,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 2251.9466666666667,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 2261.3733333333334,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1503.1666666666665,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 929.3066666666667,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 834.4,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 823.58,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 831.8399999999998,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 824.3933333333333,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 817.5933333333332,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 834.1733333333334,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 838.3933333333333,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 804.5133333333333,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 831.0933333333332,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 840.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1022.96,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1632.9066666666665,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 2033.1066666666666,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 2210.7333333333336,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1816.36,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1224.9733333333334,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1624.18,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 2131.3266666666664,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 2329.86,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 2344.54,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 2304.9266666666667,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 2106.7,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1245.9066666666665,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 744.4066666666668,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 739.9933333333335,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 741.6,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 746.5466666666667,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 745.4666666666666,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 761.8866666666665,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 1679.5,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 2111.4733333333334,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 2278.9466666666667,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 2205.5800000000004,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 2002.9533333333334,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 2026.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1624.9333333333332,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 2082.133333333333,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 2326.3933333333334,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 2346.133333333333,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 2341.4666666666667,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 2322.6666666666665,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 2161.6066666666666,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1085.74,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 737.4733333333335,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 726.9333333333333,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 735.8533333333332,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 720.3933333333334,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 734.9466666666667,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 744.4,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 748.9266666666667,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 1092.4133333333332,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1854.1666666666665,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 2171.88,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1939.1333333333332,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1914.5599999999997,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1830.3866666666668,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1580.7466666666667,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1691.5266666666666,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 2395.7333333333336,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 2339.48,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 2341.6,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 2147.1866666666665,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1113.1000000000001,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 722.4,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 720.3933333333334,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 725.6066666666666,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 734.9333333333334,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 745.6066666666667,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 953.12,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 1908.36,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1875.6266666666666,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1918.7266666666665,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1560.4666666666665,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1857.0866666666666,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 998.9666666666666,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 774.08,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 1626.3933333333332,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 2315.0266666666666,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 2317.48,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 2325.8533333333335,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 2331.1133333333332,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1775.0933333333335,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 771.5133333333333,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 740.5133333333333,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 746.3866666666668,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 743.2,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 725.08,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 741.7133333333333,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 825.5266666666665,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1203.5533333333335,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1562.5866666666668,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1631.4866666666667,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1187.9533333333334,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 855.9666666666666,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 816.7933333333333,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 1199.3,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 2261.64,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 2318.12,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 2343.04,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 2376.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1883.72,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 774.6600000000002,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 731.72,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 745.6,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 743.6,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 741.5933333333335,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 836.4733333333335,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 1735.42,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 1382.66,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1679.293333333333,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1924.6333333333334,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1899.9333333333334,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1123.8133333333333,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 798.5133333333333,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1259.1333333333332,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 2251.7866666666664,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 2332.106666666667,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 2347.6,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 2249.4,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1190.82,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 734.3066666666667,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 721.5933333333332,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 723.3133333333334,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 742.2933333333333,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 741.72,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 738.8,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 766.0733333333333,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 1542.06,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1731.16,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 2024.6933333333334,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1889.573333333333,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 1836.7666666666669,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1166.48,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1031.5199999999998,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 2210.653333333333,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 2305.78,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 2351.6,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 2341.086666666667,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 2343.3,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1989.1466666666665,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 868.2,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 727.7266666666667,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 743.2,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 733.2,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 734.6866666666666,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 730.9,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 739.9933333333335,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 735.9666666666667,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 753.2133333333335,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 1352.86,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1621.3666666666666,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 2221.4066666666668,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 2171.9866666666667,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1875.0666666666668,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1582.2,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1754.0266666666666,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1874.3466666666666,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 2314.126666666667,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 2423.1133333333332,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 2448.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 2448.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 2238.9466666666667,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1145.18,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 721.5,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 733.3066666666667,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 740.7066666666667,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 721.5866666666667,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 728.9,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 742.72,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 741.7066666666667,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 745.2933333333333,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1002.0333333333334,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 2146.6,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 2241.02,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 2198.94,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 2083.16,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 1719.3066666666666,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 1347.66,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 2288.62,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 2349.0066666666667,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 2310.8333333333335,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1631.2866666666669,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 944.8266666666666,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 721.9933333333335,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 739.2933333333333,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 735.9,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 727.6666666666665,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 739.6066666666667,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 740.4,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 747.5933333333335,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 1287.62,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 2143.28,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 2211.74,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 2130.793333333333,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 2158.873333333333,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1813.1,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 1507.9333333333334,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 1565.48,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 2362.433333333333,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1663.3466666666666,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 2114.5,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 2400.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 2244.8466666666664,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1085.6533333333334,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 737.5933333333332,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 739.5133333333333,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 740.8933333333333,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 743.2,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 743.1866666666667,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 739.6066666666667,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 723.6066666666667,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 984.6466666666668,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 1736.7333333333333,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 1826.8133333333335,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 2130.5733333333333,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 2211.9,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 2201.4933333333333,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1420.5933333333335,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 885.8399999999998,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 1943.9266666666665,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 2315.2,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 2314.853333333333,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 2367.4466666666667,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 2435.0133333333333,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 1712.8866666666665,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 762.6133333333332,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 724.3066666666667,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 718.0466666666667,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 738.5333333333333,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 743.5733333333333,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 745.2733333333333,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 978.4333333333333,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1815.1266666666666,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1984.2533333333333,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 2097.02,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1894.0666666666668,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1610.8333333333335,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 1056.0333333333335,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 1944.726666666667,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 2296.7933333333335,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 2320.8866666666668,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1848.793333333333,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 823.28,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 732.72,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 722.3933333333333,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 732.48,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 998.78,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 2178.9933333333333,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1701.98,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1740.28,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 2172.68,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1970.32,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1849.1333333333332,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 1080.0133333333333,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 1776.9600000000003,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 2229.3866666666668,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 2336.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 2323.5266666666666,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 2084.233333333333,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1020.7333333333335,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 720.3333333333333,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 730.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 728.06,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 623.24,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 215.92666666666668,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 683.9733333333332,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 814.26,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 1269.06,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1431.5066666666667,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 2084.96,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 2230.9466666666667,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1992.8133333333335,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1154.0666666666666,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1100.8266666666666,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 1646.86,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1948.1733333333332,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 2164.52,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 2230.44,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 2224.3466666666664,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1979.26,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 968.0866666666666,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 732.4666666666667,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 730.4133333333333,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 742.3333333333335,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 735.2533333333333,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 763.7066666666667,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 1543.7533333333333,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 2071.5733333333333,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 2186.8733333333334,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 2280.5,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 2115.786666666667,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1386.56,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 873.94,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1925.84,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 2293.0333333333333,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 2283.38,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 2356.853333333333,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 2356.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 2303.62,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1692.3933333333332,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 800.4066666666665,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 763.2533333333334,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 749.94,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 731.9533333333333,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 724.06,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 738.34,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 731.66,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 725.9933333333333,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 761.02,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 1467.7933333333335,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1148.34,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1294.7466666666667,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 2312.1733333333336,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 2372.22,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 2301.68,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1912.58,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1572.1799999999996,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1991.9066666666665,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 2338.4,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 2328.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 2304.98,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 2202.8933333333334,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1203.4133333333332,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 730.3866666666665,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 740.7466666666667,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 722.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 721.2666666666668,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 730.74,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 721.6,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 725.1733333333334,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 721.9933333333335,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 728.5,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1289.5866666666668,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 2175.9066666666668,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 2195.18,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 2051.32,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1986.82,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1963.3866666666665,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 2320.106666666667,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 2361.4933333333333,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 2352.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 2347.2066666666665,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 2358.5066666666667,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 2322.046666666667,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1300.5133333333333,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 726.8533333333332,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 744.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 731.9466666666667,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 720.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 722.1095810137709,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 722.1095810137709,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 722.1095810137709,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 792434.0807635045,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H04',
              coreData: {
                totalConsumption: 0.0,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 0.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 0.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: false,
                  totalSavings: 0,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H07',
              coreData: {
                totalConsumption: 842128.043957704,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 856.2439577039274,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1462.875,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1561.3,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1483.63,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1461.34,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1577.47,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1360.305,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1294.46,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1633.08,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1766.005,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1743.63,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1722.86,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1765.205,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1391.74,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 748.66,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 945.21,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1430.43,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1485.58,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1562.08,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1571.425,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1645.275,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1585.155,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1147.765,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1315.115,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1465.46,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1665.775,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1677.07,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1744.265,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1189.755,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 667.92,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 670.2,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 901.795,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 1303.27,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1525.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1710.8,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1693.47,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1508.765,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1447.52,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1504.505,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1572.16,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1499.36,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1529.44,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1620.16,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1664.56,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1143.29,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 675.135,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 667.495,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 668.565,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 666.145,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 831.82,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1034.105,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1316.22,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 1628.86,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1663.795,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1344.43,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 791.245,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1162.62,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1656.155,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1701.58,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1735.2,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1657.32,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 885.55,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 704.98,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1473.835,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1667.71,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1684.94,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1763.845,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1582.48,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 799.12,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 667.335,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1260.055,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1733.85,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1676.995,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1699.21,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1665.815,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 913.49,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 668.855,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 666.145,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 821.76,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1133.605,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1483.875,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1617.88,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1666.35,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1211.99,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 673.52,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1111.495,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1586.37,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1696.175,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1711.045,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1555.53,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 809.61,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 1041.92,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 1672.335,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1728.305,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1695.475,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 1740.695,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1372.175,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 675.215,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1105.505,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1792.465,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1757.135,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1703.555,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1665.21,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 926.58,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 475.11,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 570.395,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 951.865,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1572.535,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1721.96,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1777.905,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1784.405,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1825.8,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1798.52,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1754.1,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1776.16,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1730.865,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1730.235,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1783.305,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1369.165,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 680.4,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 668.685,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 666.57,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1231.5500000000002,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1728.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1729.03,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1737.33,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1712.525,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1705.825,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1695.44,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1755.525,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1783.53,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1754.965,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1762.665,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1781.645,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1135.48,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 666.17,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 672.3,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 1044.18,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1574.11,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1594.8,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1440.37,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1695.945,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1771.63,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1796.525,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1794.445,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1796.225,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1785.605,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1832.06,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1836.0,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1814.42,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1195.655,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 667.545,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 809.665,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1560.9,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 1719.15,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1774.5,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1757.87,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1350.545,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 697.04,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 671.245,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1307.175,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1732.075,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1728.72,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1745.695,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1652.33,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 861.78,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 671.825,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 667.98,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 666.905,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1005.445,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1665.43,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1685.395,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1709.945,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1320.15,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 682.455,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 666.12,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1213.99,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1662.735,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1647.35,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1677.185,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1499.815,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 816.595,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 697.46,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 999.4,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1571.04,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1703.08,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1765.955,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1510.52,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 790.67,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1150.315,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1694.215,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1772.185,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1754.24,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1657.775,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 924.265,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 669.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 667.41,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 873.345,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1504.99,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1751.81,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1768.825,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 1547.24,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 735.825,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1134.66,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1739.345,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1786.495,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1775.91,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1705.44,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 964.665,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 666.71,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 651.675,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 664.0,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 671.14,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1316.445,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1713.795,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1732.9,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1752.22,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1779.67,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1747.005,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 1786.115,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1807.915,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1749.8,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1736.1,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1656.09,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1249.75,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 679.27,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 823.55,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1473.02,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1569.495,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1687.215,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1767.8,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1721.3,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 1673.9,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1695.6,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1727.695,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1751.1,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1735.2,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1737.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1344.61,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 697.485,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 968.335,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1733.165,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1667.815,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1675.78,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1654.31,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1663.095,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1686.8,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1742.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1742.41,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1732.095,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1742.7,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1762.695,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1767.905,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1382.085,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 677.525,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 855.485,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1298.07,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1632.315,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1521.775,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1195.02,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 676.845,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1170.805,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1610.125,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1586.765,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1631.24,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1364.875,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 752.17,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 669.005,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 667.805,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 811.395,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1493.28,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 1577.88,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1583.015,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 1591.835,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 1255.77,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 676.865,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 671.03,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1245.46,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1594.89,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1673.76,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1675.705,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1555.75,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 877.86,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 667.195,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 671.185,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 671.315,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 680.86,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 1294.83,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 1724.135,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1739.7,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1751.485,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1411.1,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 700.31,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 666.46,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1231.255,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1798.62,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1797.875,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1748.695,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1704.365,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 909.89,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 946.12,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1655.445,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 1735.97,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1748.18,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 1795.115,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1302.9799999999998,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 682.47,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1149.225,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 1789.535,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1817.25,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1797.665,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1011.405,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 668.055,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1256.835,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1702.2,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1700.37,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1727.105,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1788.75,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1800.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1783.425,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1712.165,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1741.37,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1736.33,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1750.955,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1791.45,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1275.8849999999998,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 668.705,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1177.0100000000002,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1643.435,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1599.365,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1628.465,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 1652.835,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 1643.085,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 1626.71,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1608.175,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1632.135,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1685.97,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1679.77,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1614.405,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1165.245,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 673.065,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 674.94,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 1300.1250000000002,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 1665.595,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1694.88,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1649.67,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1702.49,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 1723.5,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 1723.495,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1715.53,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1724.625,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1745.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1798.57,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1713.675,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1273.12,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 672.73,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 665.085,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 666.3,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 719.02,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 847.7,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1634.455,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1696.975,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 1721.825,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1417.095,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 707.405,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 668.05,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1271.485,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1722.3,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1780.375,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1805.53,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 1713.04,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 861.945,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 674.88,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1230.895,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1681.745,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1692.26,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1708.445,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1445.54,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 720.3,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 702.6,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1439.985,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 1751.285,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1807.08,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1810.08,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1714.025,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 892.67,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1066.63,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1643.02,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1661.755,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1648.01,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1515.155,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 767.045,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 666.055,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 1207.295,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1756.07,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1805.57,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1778.615,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1598.35,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 869.63,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 565.94,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 195.63,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 619.92,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 688.115,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 1308.515,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1725.1,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1674.91,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1606.335,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1389.76,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 717.485,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1172.83,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1722.89,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1725.65,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1736.75,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1660.58,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 891.81,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 665.4,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 666.9,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1196.495,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1717.24,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1731.64,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1768.59,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1790.395,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 1787.365,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1778.79,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1809.08,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1819.72,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1780.365,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1735.545,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1808.58,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1836.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1836.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 1820.295,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 1818.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 1374.425,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1121.08,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1371.29,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1439.135,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1383.555,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1409.13,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1610.14,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1676.865,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1584.72,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1597.1,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1558.695,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1477.055,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1492.385,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1207.8649999999998,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 683.67,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1027.59,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1414.04,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1365.715,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1445.05,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1538.33,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1535.245,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 1528.135,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1537.78,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1536.83,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1551.86,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1659.26,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 1621.8,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1398.22,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 722.335,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 666.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 497071.9560422961,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H05',
              coreData: {
                totalConsumption: 605675.9063427714,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1010.6888888888889,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1699.0944444444444,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1622.7222222222222,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1750.2722222222226,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1664.8944444444444,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1680.561111111111,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1612.9111111111113,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1623.4666666666665,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1714.9833333333333,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1725.588888888889,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1743.0388888888888,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1745.572222222222,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1921.4388888888889,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 1986.033333333333,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 1961.083333333333,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 1788.55,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 1807.3944444444444,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 1702.9777777777779,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 976.2277777777778,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 813.7055555555556,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 1353.666666666667,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1438.6277777777777,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1448.8055555555557,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1573.7444444444443,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1509.4222222222222,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1639.688888888889,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 1380.9222222222222,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 822.85,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1072.788888888889,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1638.8277777777778,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1791.6833333333332,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1776.9833333333331,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1675.338888888889,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1886.7833333333335,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 1820.6833333333332,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 1673.3111111111111,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 1705.7666666666667,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 1792.6888888888889,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 1487.572222222222,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 797.0777777777778,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1073.3277777777778,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1707.8777777777777,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1636.6166666666666,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1699.2777777777778,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 1650.1277777777777,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 882.1555555555555,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 787.5333333333333,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1182.5777777777778,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1709.4333333333332,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1768.9833333333333,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1622.7222222222222,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1512.4388888888889,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1778.0111111111112,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 1774.0277777777778,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 1710.5944444444444,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 1619.1833333333332,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 1355.9944444444448,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 790.6222222222221,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 780.1722222222222,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 790.8333333333333,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1111.7666666666667,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1609.5222222222221,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1693.666666666667,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1704.35,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1741.322222222222,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1728.3555555555554,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 1906.6722222222222,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 1840.661111111111,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 1731.1611111111113,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 1723.6722222222222,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 1717.8166666666666,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 934.1777777777777,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1286.6944444444446,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1854.1611111111113,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1853.9777777777779,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1787.8944444444446,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 990.25,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 884.088888888889,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1536.416666666667,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1723.822222222222,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1763.4944444444448,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1803.322222222222,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1802.3388888888887,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1775.3666666666666,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 1673.8,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 1697.0444444444445,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 949.1166666666666,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 811.7666666666665,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1521.4777777777779,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1719.8388888888887,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1698.3611111111109,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1610.4055555555556,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 1031.1944444444443,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1171.361111111111,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1721.5055555555552,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1777.9055555555556,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1773.177777777778,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1733.6944444444443,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1676.8555555555556,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 1117.1833333333334,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 792.8222222222222,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 788.1722222222223,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 781.4888888888889,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 799.6666666666667,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 781.5055555555556,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 792.6666666666667,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 783.1555555555556,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 787.5111111111111,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 544.9166666666666,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 651.75,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 784.8222222222222,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 797.8444444444445,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 796.15,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 798.1833333333333,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 785.8166666666666,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 789.5166666666668,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 785.4833333333332,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 799.6666666666667,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 799.1833333333334,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 794.661111111111,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 789.4777777777776,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 790.5166666666665,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 785.6666666666665,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 780.3333333333333,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 787.8111111111111,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 799.1888888888889,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 790.3277777777778,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 789.8055555555554,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 797.8666666666666,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 789.4666666666667,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 791.2055555555556,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 799.4722222222222,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 783.8611111111111,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 786.1333333333333,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 797.5111111111111,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 783.2,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 787.1277777777779,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 781.2111111111111,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 788.4666666666666,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 787.5388888888889,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 795.7944444444445,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 790.5388888888889,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 787.1277777777779,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 786.2944444444444,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 782.0055555555555,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 782.1166666666667,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 792.8888888888889,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 799.7888888888889,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 800.0,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 780.8777777777779,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 786.1222222222224,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 789.5444444444445,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 731.9777777777776,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 768.6833333333334,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 780.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 767.6333333333333,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 677.9777777777778,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 679.1222222222221,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 675.9944444444444,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 665.2166666666666,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 672.7777777777777,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 657.1,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 648.45,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 666.338888888889,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 668.45,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 660.1,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 657.45,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 650.6666666666666,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 661.9944444444444,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 639.7833333333333,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 620.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 611.6722222222222,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 640.8888888888889,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 641.8944444444445,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 620.2222222222223,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 620.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 627.4555555555555,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 652.1111111111111,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 660.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 673.7777777777778,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 667.5555555555557,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 658.2277777777776,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 640.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 646.4611111111111,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 660.7777777777778,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 678.0111111111111,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 680.0,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 656.4444444444446,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 640.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 632.661111111111,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 626.2166666666667,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 632.5555555555555,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 645.45,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 618.3388888888888,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 617.0166666666668,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 619.338888888889,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 618.3277777777778,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 620.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 628.4333333333334,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 649.2166666666667,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 531.0713073402777,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 647.945025,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 660.9561749999999,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 645.1314444444445,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 657.1920861111112,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 670.2487694444445,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 668.8846666666667,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 678.7464277777777,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 678.2967861111111,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 673.2881194444444,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 672.1516833333334,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 646.3134138888889,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 632.3043250000001,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 625.6317944444445,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 640.9215083333333,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 630.6006194444444,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 633.4047138888889,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 639.7812777777777,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 643.3480555555556,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 661.3337222222223,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 652.9081583333333,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 662.620038888889,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 665.6195472222222,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 674.6123805555555,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 672.4931833333335,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 661.4077138888888,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 648.1290555555555,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 648.1688972222222,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 635.8862805555556,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 660.5994972222222,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 670.7097944444445,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 670.7382527777777,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 665.8585972222222,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 649.8839861111111,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 658.0970611111111,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 667.5945555555555,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 644.761486111111,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 648.2637583333334,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 666.5700555555555,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 648.2466833333333,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 664.2042194444444,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 671.5369833333333,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 682.8064833333333,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 681.197638888889,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 651.3220805555555,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 667.7008000000001,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 671.5369833333333,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 669.8408666666667,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 659.8918333333334,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 650.5726777777777,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 652.2991499999999,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 641.7923333333333,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 664.0941805555557,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 669.9091666666666,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 671.6090777777779,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 680.1067361111111,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 651.0109361111112,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 629.8037861111111,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 641.8473527777778,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 645.9529416666667,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 653.9079944444445,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 649.9712583333334,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 633.7784666666668,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 658.8483611111111,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 651.496625,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 630.6689194444444,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 626.6999305555555,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 647.0248722222223,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 664.5286444444444,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 669.5183388888888,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 681.5524194444446,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 671.1859972222222,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 643.8678944444445,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 651.8931444444445,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 628.8798388888889,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 661.72455,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 670.7875805555555,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 667.9758972222222,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 671.9904194444445,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 675.5685805555555,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 670.9355638888889,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 647.4403638888888,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 629.4660805555555,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 649.4893638888889,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 652.9404111111112,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 663.8020083333333,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 682.5750222222223,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 671.0399111111111,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 652.0866611111112,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 638.2635,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 636.563588888889,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 657.480463888889,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 672.2446472222223,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 672.072,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 678.9835805555555,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 638.9692666666666,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 625.8291055555555,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 667.0159027777778,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 674.1437666666666,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 672.5880444444446,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 673.4398972222222,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 680.4121888888887,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 681.1521055555554,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 671.0152472222222,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 671.7286027777778,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 654.1394555555555,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 643.0141444444445,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 653.4071277777778,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 677.3311,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 645.9946805555555,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 643.3803083333333,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 646.5486694444445,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 678.3631888888889,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 679.7253944444445,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 654.6289388888889,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 658.2393527777776,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 673.3810833333333,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 673.1268555555555,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 656.7063972222221,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 661.1155416666667,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 666.3556694444444,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 677.8471444444444,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 674.6047916666665,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 655.30435,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 638.4057916666666,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 652.3560666666667,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 646.9717499999999,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 639.4302916666667,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 648.0493722222222,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 673.9464555555555,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 669.2584194444445,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 663.7318111111111,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 670.0799166666667,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 663.3277027777777,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 670.2525638888889,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 669.4557305555556,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 671.5028333333332,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 674.0602888888889,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 675.4338777777776,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 662.1134805555556,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 661.1971222222222,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 671.8708944444444,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 674.6636055555556,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 670.2772277777777,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 671.8006972222222,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 649.77205,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 638.7188333333334,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 622.0175861111111,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 615.614461111111,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 617.9176888888888,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 645.7651166666667,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 679.4958305555556,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 656.3535138888889,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 617.2346888888889,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 624.2354388888889,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 625.1745638888889,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 634.95095,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 655.2758916666667,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 668.9093305555557,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 676.5627250000001,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 680.8978777777778,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 664.1890416666668,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 663.900663888889,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 673.7984722222222,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 679.8392277777779,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 674.957675,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 667.3194583333333,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 659.1822722222222,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 671.7058361111111,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 645.0214055555556,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 641.9763638888888,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 657.0251305555555,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 628.9481388888888,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 628.4358888888888,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 665.3406555555556,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 652.3295055555556,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 673.0547611111111,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 676.1453361111111,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 663.0545027777777,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 662.51,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 668.8182638888889,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 671.0209388888887,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 675.2839972222223,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 656.9075027777778,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 644.6191944444445,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 673.3715972222221,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 676.2591694444444,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 674.9178333333333,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 670.4100333333332,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 679.2207333333333,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 664.8322000000001,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 663.0374277777778,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 678.4239,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 660.1840055555556,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 656.7974638888888,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 640.1284694444445,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 629.2175444444445,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 661.1212333333334,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 664.1473027777778,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 635.1937944444444,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 648.7361666666667,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 662.9198,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 671.4117666666666,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 673.0699388888888,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 668.8846666666667,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 672.4116027777777,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 666.6080000000001,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 669.3191305555555,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 664.8322000000001,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 672.0473361111111,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 666.1071333333333,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 659.9354694444445,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 680.1978027777778,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 673.4417944444446,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 632.0747611111111,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 648.1214666666667,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 663.8778972222223,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 639.3164583333333,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 626.8592972222223,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 625.8765361111111,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 645.2699416666667,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 642.39565,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 641.9137555555557,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 645.2168194444444,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 651.6028694444444,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 669.2489333333333,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 665.1281666666666,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 674.8438416666667,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 677.014263888889,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 670.706,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 668.8922555555555,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 665.2230277777777,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 675.5515055555557,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 682.2752611111111,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 660.0663777777777,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 640.9556583333333,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 650.4531527777777,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 678.9740944444445,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 648.1442333333333,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 672.2427499999999,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 572.3217472222223,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 181.42566944444442,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 596.993225,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 666.9608833333333,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 649.6240666666666,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 679.7936944444444,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 672.5482027777778,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 670.9222833333333,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 661.5405194444444,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 639.1570916666667,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 646.8446361111112,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 667.2132138888888,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 672.525436111111,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 676.435611111111,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 682.7533611111112,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 673.3260638888889,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 653.4754277777778,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 611.1446055555556,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 618.132075,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 624.6414444444445,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 625.9144805555555,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 620.6402027777779,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 620.2949083333333,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 656.8012583333334,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 645.6816388888889,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 617.6539750000001,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 652.3560666666667,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 658.0705,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 662.7585361111112,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 668.3951833333333,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 668.9966027777778,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 680.0384361111112,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 657.4463138888889,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 664.3085666666667,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 681.5998500000001,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 668.639925,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 668.00815,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 682.4479083333333,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 673.1363416666667,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 647.8900055555556,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 652.8474472222222,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 654.2475972222222,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 674.6920638888889,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 651.950061111111,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 645.2775305555556,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 669.4690111111112,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 667.3706833333334,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 656.3819722222222,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 665.8282416666667,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 679.96065,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 677.0427222222222,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 676.17,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 682.1064083333334,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 670.6737472222221,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 672.4286777777778,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 676.5285749999999,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 678.5320416666666,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 674.0033722222222,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 673.0813222222222,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 667.862063888889,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 670.1045805555556,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 628.0412666666666,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 627.9805555555555,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 674.9899277777778,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 659.6091472222222,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 661.5158555555555,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 667.2530555555554,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 648.9505527777778,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 648.3775916666667,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 647.4157,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 660.8726972222222,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 651.872275,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 669.34,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 673.1116777777778,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 675.025975,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 672.3015638888887,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 670.1235527777778,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 669.5809472222222,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 670.8293194444444,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 680.1674472222222,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 683.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 682.1917833333333,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 659.5825861111111,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 642.985686111111,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 656.1296416666665,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 638.9446027777778,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 666.7540861111111,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 642.1831611111111,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 638.1914055555557,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 642.5531194444444,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 630.0826777777777,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 634.4861305555555,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 634.7443937644342,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 292885.2968914507,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H06',
              coreData: {
                totalConsumption: 416698.1757159148,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 685.6055555555555,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 725.8930555555556,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 687.8452777777778,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 693.6966666666667,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 769.45,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 685.0463888888889,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 675.8491666666665,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 677.5175,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 692.3705555555556,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 688.1355555555556,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 688.2333333333335,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 688.5175,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 686.8522222222222,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 681.7158333333333,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 674.9508333333333,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 690.5341666666667,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 683.9158333333332,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 681.2697222222222,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 682.2841666666667,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 686.4855555555556,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 683.463611111111,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 683.4666666666667,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 679.7022222222222,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 680.8144444444446,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 692.5477777777778,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 692.45,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 682.1741666666667,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 681.7311111111111,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 682.1833333333333,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 673.4780555555554,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 671.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 674.3947222222222,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 683.7477777777779,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 686.125,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 688.4136111111111,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 684.1113888888889,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 682.6386111111111,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 683.2833333333333,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 687.1302777777778,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 687.2311111111111,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 692.45,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 682.088611111111,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 690.7113888888889,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 686.1219444444446,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 682.3666666666667,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 683.1030555555554,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 741.6963888888889,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 704.5377777777777,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 689.7916666666667,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 682.55,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 762.9538888888889,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 723.9802777777778,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 684.7530555555554,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 693.7333333333335,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 692.725,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 684.9363888888889,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 685.9416666666667,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 683.925,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 691.625,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 683.65,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 685.3886111111111,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 687.4969444444446,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 731.4725,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 829.021111111111,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 683.286388888889,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 681.7280555555554,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 678.7886111111111,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 680.8969444444446,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 687.7719444444446,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 682.5530555555556,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 671.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 671.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 671.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 684.1938888888889,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 682.2780555555556,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 682.8219444444444,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 688.8811111111111,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 688.9666666666667,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 685.6666666666667,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 682.3666666666667,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 678.6113888888889,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 680.9886111111111,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 678.6113888888889,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 673.8416666666667,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 682.55,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 682.5530555555556,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 683.4666666666667,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 690.8886111111111,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 694.8363888888889,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 690.3477777777778,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 682.4552777777778,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 683.0144444444444,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 742.0966666666667,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 688.27,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 691.9855555555556,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 684.4811111111111,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 722.3088888888889,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 687.72,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 675.5833333333333,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 688.1325,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 687.0477777777778,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 689.4158333333332,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 691.8144444444446,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 482.3775,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 575.4497222222221,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 682.9227777777778,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 689.15,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 681.4530555555556,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 679.0666666666667,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 686.3511111111111,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 754.1752777777776,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 681.8319444444445,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 672.5491666666667,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 681.0833333333333,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 679.25,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 683.3688888888889,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 683.5675,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 689.0491666666667,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 682.6447222222222,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 688.6763888888889,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 685.4008333333335,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 682.0794444444446,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 683.9402777777779,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 686.4794444444445,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 689.0705555555556,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 692.1658333333332,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 692.8166666666667,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 692.8166666666667,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 690.5402777777778,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 675.5833333333333,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 686.2930555555555,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 686.3266666666666,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 687.9430555555555,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 683.2038888888889,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 684.2,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 725.6394444444444,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 833.6716666666666,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 705.0113888888889,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 683.2802777777778,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 685.1166666666667,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 689.9597222222222,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 690.9069444444444,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 682.3666666666667,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 686.1066666666667,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 688.1569444444444,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 688.6763888888889,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 690.5372222222222,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 691.4233333333334,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 686.1433333333333,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 682.1833333333333,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 686.5497222222222,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 798.1569444444444,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 708.0302777777778,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 687.6069444444444,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 683.65,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 692.3369444444444,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 683.2833333333333,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 682.4797222222222,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 683.9066666666668,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 682.8433333333332,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 683.5430555555555,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 682.6630555555556,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 719.3908333333334,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 698.3319444444445,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 683.7233333333332,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 685.7797222222222,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 685.1166666666667,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 688.4197222222222,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 684.6338888888889,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 692.0833333333333,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 692.0161111111111,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 687.0202777777778,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 690.3630555555557,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 686.6475,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 683.4025,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 681.9327777777778,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 675.4580555555555,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 682.6141666666667,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 683.0358333333332,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 686.8338888888889,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 683.9494444444443,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 682.0,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 692.8808333333334,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 682.1191666666666,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 686.8308333333333,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 693.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 648.6761111111111,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 681.5202777777778,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 690.146111111111,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 700.6480555555555,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 492.8825,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 520.7461111111111,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 454.1380555555555,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 440.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 445.9338888888888,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 453.26416666666665,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 454.78583333333336,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 458.3363888888889,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 450.6333333333334,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 442.2,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 449.89388888888885,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 529.2283333333332,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 461.74944444444446,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 448.4975,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 447.21111111111117,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 450.6272222222222,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 455.0975,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 455.34194444444444,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 451.4247222222222,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 452.40555555555557,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 450.26666666666665,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 450.6363888888889,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 467.0538888888889,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 556.8047222222223,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 445.5611111111111,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 452.28333333333336,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 453.07166666666666,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 459.37222222222226,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 446.9086111111111,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 442.25805555555564,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 454.8591666666666,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 517.5194444444445,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 537.7808333333334,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 467.8422222222222,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 449.7105555555556,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 454.85,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 452.5216666666667,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 454.42833333333334,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 451.36972222222226,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 457.06527777777774,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 521.9469444444444,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 451.18333333333334,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 451.1863888888889,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 452.7019444444444,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 455.8980555555556,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 448.98027777777776,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 450.2177777777778,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 450.6852777777778,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 448.4363888888889,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 450.6363888888889,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 451.59888888888884,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 454.9325,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 444.9988888888889,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 455.76666666666665,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 453.7988888888889,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 452.4147222222223,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 456.7994444444445,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 540.0847222222222,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 452.0022222222222,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 456.9247222222222,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 552.4474999999999,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 596.2366666666667,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 450.9511111111111,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 449.75944444444445,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 451.18333333333334,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 459.0636111111111,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 454.30305555555555,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 451.4125,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 457.7588888888889,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 496.05722222222226,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 526.9366666666667,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 446.5480555555556,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 449.02916666666664,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 442.5238888888889,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 446.2730555555556,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 454.70944444444444,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 456.27083333333326,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 453.9363888888889,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 452.3261111111111,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 453.5238888888889,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 449.49055555555555,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 450.4927777777778,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 455.8125,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 452.37805555555553,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 440.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 447.19277777777774,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 448.9863888888889,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 445.1333333333334,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 449.16666666666674,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 526.3072222222222,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 462.9502777777778,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 448.43333333333334,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 450.81666666666666,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 452.28333333333336,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 445.28,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 440.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 442.97305555555556,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 450.4438888888889,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 460.9397222222222,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 453.5636111111111,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 452.24055555555555,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 447.8436111111111,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 455.47638888888883,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 456.27694444444444,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 542.5291666666667,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 643.6008333333333,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 456.53666666666663,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 455.18,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 449.86333333333334,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 450.8563888888889,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 451.91666666666674,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 440.5133333333334,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 449.38666666666666,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 445.6466666666667,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 451.8066666666666,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 459.2102777777777,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 456.8636111111111,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 450.26666666666665,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 448.6166666666666,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 455.7972222222222,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 453.8997222222222,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 452.32,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 453.90583333333336,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 444.18305555555554,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 445.1669444444444,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 460.3316666666667,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 447.2997222222222,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 444.61694444444436,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 446.2027777777778,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 440.3941666666667,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 373.4622222222222,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 127.06833333333333,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 417.22388888888884,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 450.4163888888889,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 454.94166666666666,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 480.8925,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 684.8263888888889,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 457.71916666666664,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 440.33611111111117,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 441.8638888888889,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 450.44694444444445,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 453.0472222222222,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 456.8422222222222,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 454.11972222222226,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 453.01666666666665,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 446.38916666666665,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 440.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 448.0972222222223,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 584.7936111111112,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 592.7472222222223,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 461.29416666666674,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 455.0058333333334,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 451.18333333333334,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 445.4725,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 444.57722222222225,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 446.07444444444445,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 454.66666666666674,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 451.73333333333335,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 454.3061111111111,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 449.9,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 445.8636111111111,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 441.62555555555554,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 449.5486111111111,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 446.23333333333335,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 443.45583333333326,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 447.5411111111111,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 457.5786111111111,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 520.3305555555555,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 443.0922222222222,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 440.20472222222224,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 448.98333333333335,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 449.9,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 449.9,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 449.71666666666664,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 451.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 452.6713888888889,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 452.44527777777773,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 454.85305555555556,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 450.11910795742523,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 450.11910795742523,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 401580.06613068376,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H11',
              coreData: {
                totalConsumption: 580920.3166666667,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 1099.0933333333332,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1055.6505555555555,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 827.0655555555556,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1097.2655555555557,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 939.995,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 753.4916666666667,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 1060.0683333333334,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 1036.0544444444445,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 772.4461111111111,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 781.9661111111111,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 760.7327777777778,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 655.7677777777778,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 717.99,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 581.8127777777777,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 555.2283333333332,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 561.1627777777777,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 552.6383333333333,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 547.295,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 557.6627777777777,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 644.8983333333333,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 856.271111111111,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 667.59,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 920.2044444444443,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 883.5283333333333,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 711.0794444444443,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 808.9394444444446,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 1049.5877777777778,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1088.9044444444444,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 943.7011111111111,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 786.2788888888889,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 798.2294444444445,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 752.4144444444445,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 556.9666666666667,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 558.9422222222223,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 546.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 546.0,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 549.3911111111112,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 555.9088888888889,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 548.3255555555556,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 551.7166666666667,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 743.2405555555556,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1090.285,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1057.4666666666665,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1103.336111111111,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 896.595,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 742.4355555555554,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 959.4666666666666,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 895.1288888888889,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 821.7377777777779,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 933.1777777777777,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 822.8655555555556,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 736.5555555555557,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 570.8305555555555,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 560.1166666666667,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 628.0244444444445,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 646.7416666666667,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 1159.0211111111112,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 1155.6883333333333,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 940.4772222222222,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1126.125,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 1077.793888888889,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1264.903888888889,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1066.3216666666667,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 997.1499999999999,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 955.3794444444445,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 968.345,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 965.8677777777779,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 633.9511111111111,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 553.5833333333333,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 557.0833333333333,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 563.0333333333333,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 560.8166666666666,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 554.6294444444444,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 572.5727777777778,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 988.7033333333334,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1019.4488888888889,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1092.9955555555555,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1195.1255555555554,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1176.8283333333334,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1105.9883333333332,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 1159.7794444444444,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1179.2666666666667,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1125.5766666666666,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1028.3155555555556,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1014.8872222222221,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1002.5127777777776,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1001.3577777777778,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 981.5205555555556,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 771.96,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 561.2833333333333,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 548.6833333333334,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 563.2588888888889,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 560.353888888889,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 745.9355555555554,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1212.8822222222223,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 929.0555555555557,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1092.42,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1165.7333333333333,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 985.4366666666667,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 942.0366666666665,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 970.7172222222222,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1230.3316666666665,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1166.9738888888887,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 986.5566666666667,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 993.3972222222222,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 987.5872222222222,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 991.5616666666667,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 737.0144444444444,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 553.5872222222223,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 549.1422222222222,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 559.0666666666666,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 566.9961111111112,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 655.7638888888889,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 888.3894444444444,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 653.978888888889,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 984.7366666666667,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1059.3683333333333,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 966.6027777777778,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1129.8,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 1062.0088888888888,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 1101.0066666666667,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1206.411111111111,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1049.9105555555554,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1053.8577777777778,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1028.296111111111,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1025.9822222222222,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 962.6011111111111,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 646.7494444444444,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 563.6088888888888,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 572.6,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 568.0655555555555,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 396.38666666666666,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 471.6211111111112,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 771.2755555555556,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1234.8622222222223,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 896.8633333333332,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1115.0261111111113,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1142.7072222222223,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1058.1005555555557,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1064.591111111111,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1088.1383333333333,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 1127.552222222222,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1132.7244444444445,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1071.21,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1080.9205555555557,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1029.1477777777777,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1021.7938888888889,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 982.9283333333333,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 920.9238888888889,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 598.08,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 554.7422222222222,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 675.9083333333334,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 970.0366666666665,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1089.2427777777777,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1125.0011111111112,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1142.256111111111,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1029.9683333333332,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 1014.7588888888889,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1074.745,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 973.2294444444444,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1052.2205555555556,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1055.53,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1033.931111111111,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1011.5233333333333,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 912.3527777777778,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 603.0033333333333,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 562.9050000000001,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 569.2322222222223,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 565.9344444444445,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 561.9988888888889,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 572.0011111111111,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 566.4283333333334,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 578.021111111111,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 898.7066666666666,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1214.7177777777777,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1264.3283333333334,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1215.6005555555557,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1149.3222222222223,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1013.2888888888889,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1081.531111111111,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 991.3672222222224,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1012.4955555555555,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1044.2638888888887,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1056.0666666666666,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1034.9072222222223,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 920.3327777777778,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 621.8566666666667,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 561.5361111111112,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 572.6972222222222,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 563.4066666666666,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 567.3344444444444,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 564.1027777777778,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 562.5666666666666,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 560.4277777777778,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 820.1977777777778,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 961.9788888888887,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 917.5016666666666,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 939.8549999999999,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1017.0766666666666,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1115.9399999999998,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 1163.8938888888888,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1105.3661111111112,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1044.785,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1055.5066666666667,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1058.1666666666665,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1057.4277777777777,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1039.9977777777779,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 918.9755555555556,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 600.5611111111111,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 551.4638888888888,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 553.6961111111111,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 552.7627777777777,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 561.4038888888889,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 656.7438888888889,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 710.0372222222222,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 1082.5966666666668,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1086.4933333333333,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 830.0677777777778,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1025.7372222222223,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 960.1122222222224,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 1017.1000000000001,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1156.917222222222,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1026.3011111111111,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1017.0494444444445,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1037.3999999999999,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1006.3822222222221,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 624.5205555555556,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 551.9733333333334,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 556.6827777777777,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 566.3000000000001,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 573.3000000000001,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 562.2438888888889,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 697.7133333333333,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1156.1161111111112,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 790.3272222222222,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 1045.5316666666668,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1029.7116666666666,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 1137.503888888889,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1169.528888888889,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 1198.2094444444444,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 1102.2122222222222,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1182.5566666666666,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1049.4866666666667,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1026.235,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1014.5838888888889,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1005.13,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 715.0305555555556,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 558.281111111111,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 546.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 562.0377777777777,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 561.3105555555555,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 566.0666666666666,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 563.9627777777778,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 560.3188888888889,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 807.7533333333333,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1020.9033333333333,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 996.1233333333334,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1043.668888888889,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 1095.4105555555554,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 956.9505555555555,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 1135.82,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 1338.2055555555555,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1096.1805555555557,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1044.1705555555557,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1033.9933333333333,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1046.328888888889,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1002.3805555555556,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 799.6177777777776,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 551.1916666666666,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 571.0522222222222,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 574.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 569.485,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 570.3483333333334,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 565.7516666666667,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 560.0,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 526.3844444444444,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 550.6705555555556,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 560.0816666666667,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 926.9438888888889,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 947.7688888888888,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1054.2855555555554,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1105.3661111111112,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1109.1616666666666,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 996.9438888888889,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1074.9161111111111,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 1112.1483333333333,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1080.765,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1027.8488888888887,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1031.3294444444443,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1020.0555555555557,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1040.6122222222223,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 860.1444444444445,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 486.55055555555555,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 458.64777777777783,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 456.7111111111111,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 461.06277777777774,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 461.06277777777774,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 461.07444444444445,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 460.6038888888889,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 460.1372222222222,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 472.3716666666667,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 820.9677777777778,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 951.6966666666667,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1096.6083333333333,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1106.1555555555556,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 1109.2705555555556,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 1234.3138888888889,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1043.6922222222222,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 983.4183333333334,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1028.1366666666668,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1051.466111111111,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1030.3222222222223,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 658.7116666666667,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 460.985,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 455.78166666666664,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 457.4888888888889,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 459.2661111111112,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 458.88500000000005,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 461.14055555555547,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 469.58722222222224,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 868.031111111111,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 950.6661111111111,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1066.5355555555554,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1086.295,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1114.2755555555555,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 1067.3094444444444,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1180.7755555555555,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1014.1483333333333,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1052.7844444444445,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1039.1927777777778,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1038.562777777778,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1082.8961111111112,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 798.6066666666667,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 467.2266666666667,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 461.06666666666666,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 450.80000000000007,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 461.77444444444444,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 551.2888888888889,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 909.0200000000001,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 884.0066666666665,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1013.6272222222221,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1285.6238888888888,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1087.4811111111112,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1077.548888888889,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 1136.8894444444445,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 1224.4866666666667,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1072.6761111111111,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1136.4227777777776,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1123.3211111111113,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1074.1850000000002,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 979.9066666666668,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 515.8066666666666,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 454.6927777777778,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 456.4699999999999,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 459.8261111111112,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 457.6288888888888,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 461.8211111111111,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 610.89,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 888.58,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 943.7827777777778,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 1217.3038888888887,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 767.6705555555557,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 672.4433333333333,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 1102.608888888889,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 1183.035,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1032.2977777777776,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1010.6677777777778,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1040.6861111111111,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1075.9388888888889,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 884.485,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 466.11444444444436,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 453.5261111111111,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 453.4366666666667,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 460.5300000000001,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 448.06611111111107,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 460.2888888888889,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 456.47777777777776,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 466.1261111111111,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 816.896111111111,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 747.2383333333332,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 898.0649999999998,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 829.1072222222222,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 813.3455555555556,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 884.2594444444444,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 1081.2277777777779,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1166.2700000000002,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1074.4105555555554,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1082.9622222222222,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1092.6572222222223,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1065.4194444444445,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 997.9083333333334,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 587.6033333333332,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 453.83722222222224,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 457.9711111111111,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 478.1077777777778,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 760.83,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 748.3622222222224,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 934.4222222222222,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1119.7005555555554,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 770.8088888888889,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 1135.6566666666668,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1205.5166666666667,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 1158.6905555555556,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 975.5588888888888,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 978.8411111111111,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 996.38,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1026.903888888889,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1086.921111111111,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 934.9472222222223,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 537.3433333333334,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 460.83722222222224,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 458.9472222222222,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 456.8472222222222,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 454.94166666666666,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 554.6877777777777,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 824.1488888888889,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 982.1077777777778,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1019.7172222222222,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1039.6866666666667,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1014.1211111111111,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1005.4372222222222,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1016.6916666666667,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1028.5994444444445,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1047.55,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1036.7622222222221,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1040.0366666666666,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 869.6255555555555,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 486.1033333333333,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 459.66666666666674,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 455.93333333333334,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 461.7666666666667,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 464.8272222222222,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 913.6594444444444,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 978.9305555555557,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 683.896111111111,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 780.5933333333332,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 940.0844444444446,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 999.4327777777778,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1022.4627777777777,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1068.0211111111112,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1031.2516666666666,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1072.5088888888888,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 962.1111111111112,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 529.3361111111111,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 461.53722222222217,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 470.17055555555555,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 913.4883333333332,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1147.506111111111,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1212.3494444444445,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1051.9366666666667,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 900.1377777777776,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 673.3455555555556,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1005.9933333333335,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1063.716111111111,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1155.49,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1153.3977777777777,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1065.9327777777778,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 636.8794444444444,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 457.56666666666666,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 459.9,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 461.7705555555556,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 451.96666666666664,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 499.16222222222217,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1042.716111111111,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1052.0494444444446,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 874.338888888889,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 965.7433333333335,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 1123.6438888888888,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 1083.1061111111112,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1087.3216666666667,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1028.5955555555556,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1032.7333333333333,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1024.9944444444443,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 986.9961111111111,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 634.515,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 459.6238888888889,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 459.4838888888888,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 461.53333333333336,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 560.6027777777778,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 881.615,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 959.8322222222222,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1096.4722222222222,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1050.7505555555554,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1086.088888888889,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 1234.3255555555554,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 1153.2966666666666,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1010.2555555555556,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 984.3322222222222,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1038.4655555555555,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1050.038888888889,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1028.7744444444443,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 906.1966666666667,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 509.58444444444444,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 451.45722222222224,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 460.40944444444443,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 456.87055555555554,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 527.9594444444444,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 1090.7905555555556,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 877.0494444444444,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1027.7322222222222,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1170.7966666666666,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1161.618888888889,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1152.931111111111,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 1077.5566666666666,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 1062.6661111111111,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 982.0105555555556,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1011.9277777777777,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1053.3211111111113,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1050.5794444444443,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1000.4205555555557,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 643.3855555555556,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 459.66277777777776,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 461.4905555555556,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 385.2138888888889,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 128.32166666666666,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 423.78,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 663.0983333333335,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 901.4483333333333,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1025.045,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1204.1633333333334,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1040.8961111111112,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1176.3305555555555,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 1080.8,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 1053.437777777778,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 477.6644444444444,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 841.1277777777779,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1039.4572222222223,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1032.1072222222222,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1025.7294444444444,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 692.723888888889,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 451.54277777777776,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 461.06666666666666,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 459.42944444444447,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 457.7922222222223,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 460.85277777777776,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 462.0388888888889,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 836.2666666666668,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1115.306111111111,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 960.8433333333332,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1039.465,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1021.1366666666668,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1051.6216666666667,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 1069.7633333333333,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 1044.0694444444443,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 996.838888888889,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1018.0411111111111,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1022.4627777777777,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1020.8294444444443,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1004.6244444444445,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 982.5705555555555,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 990.2005555555555,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 690.2,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 454.73555555555555,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 448.0,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 588.4899999999999,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 948.5194444444444,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 939.1355555555556,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1020.25,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 1126.6383333333333,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 1047.8027777777777,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 973.1983333333333,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 973.175,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 952.7,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 957.8644444444444,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 959.2333333333332,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 979.1327777777776,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 857.2938888888889,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 483.15944444444443,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 461.73944444444436,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 460.6038888888889,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 503.97277777777776,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1038.5744444444445,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1117.1494444444443,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1207.3133333333333,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1212.2911111111111,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1094.0611111111111,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 995.7772222222223,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 1134.5249999999999,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1013.1216666666666,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 948.2122222222222,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 938.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 954.3916666666668,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 978.6311111111111,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 969.2394444444444,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 738.0294444444444,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 462.20611111111117,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 462.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 462.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 460758.45851851086,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H01',
              coreData: {
                totalConsumption: 682895.6301812689,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 705.9901812688823,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 1089.46,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 1376.195,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 1356.23,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 1354.08,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 1242.08,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 891.785,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 835.33,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1125.45,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1249.07,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1288.395,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1292.2050000000002,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1245.335,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1371.995,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 1217.8100000000002,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 850.63,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 706.17,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 683.375,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 670.965,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 673.17,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 649.665,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 668.73,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 925.235,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 1205.4399999999998,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1228.215,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1215.225,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1028.17,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 734.15,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 718.905,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1175.385,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1299.2700000000002,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1272.7150000000001,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1244.4150000000002,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1311.2,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1297.105,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 1039.61,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 746.76,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 677.98,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 677.26,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 682.945,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 661.04,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 653.555,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 668.56,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 930.515,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 1331.455,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 1304.5300000000002,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 1281.16,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 1164.8749999999998,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 722.075,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 1065.205,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1344.94,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1335.255,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1279.9599999999998,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1292.7,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1306.1950000000002,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1305.87,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 1085.455,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 736.42,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 668.405,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 673.5,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 662.395,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 660.75,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 667.17,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 1052.88,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1320.59,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1267.795,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1227.45,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1237.2050000000002,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1314.91,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1484.855,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 1314.895,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 985.79,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 711.905,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 687.6,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 682.65,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 682.8,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 670.655,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 663.15,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 657.75,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 653.84,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 663.145,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 667.805,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 715.16,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 1293.195,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1439.14,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1287.155,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1260.0,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1254.3049999999998,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1310.5200000000002,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1452.295,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1352.895,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 1098.99,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 729.645,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 660.605,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 663.75,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 651.755,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 661.045,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 667.495,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 673.045,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 740.455,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 1202.0600000000002,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1322.955,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1290.925,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1263.01,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1242.0,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1299.11,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1345.66,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 1255.285,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 873.615,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 666.915,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 665.845,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 667.34,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 679.96,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 670.8,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 684.435,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 676.36,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 716.705,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1134.235,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1286.22,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1305.44,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1313.24,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1318.685,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 1389.34,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 1294.0900000000001,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 884.565,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 654.76,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 645.44,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 471.355,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 547.29,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 653.9949999999999,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 685.07,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1088.56,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1301.595,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1353.23,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1272.17,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1280.1,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 1183.445,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 844.29,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1273.725,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1297.635,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1344.74,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1354.64,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1353.795,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1293.93,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 1274.29,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 1010.815,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 683.335,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 665.57,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 630.4649999999999,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 643.03,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 656.535,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 664.065,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 658.12,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1022.135,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1321.2,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1300.285,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1351.665,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1214.175,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 803.61,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 1150.16,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1300.685,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1372.595,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1367.905,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1348.97,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1292.305,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1303.16,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 1260.19,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 864.01,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 680.575,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 663.005,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 664.5,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 650.44,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 653.355,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 673.33,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 681.77,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 698.645,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 1153.61,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 1450.255,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 1328.055,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 1352.875,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 1273.0000000000002,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 1191.7300000000002,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 1007.35,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1269.81,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1296.205,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1334.1,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1333.625,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1371.98,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1342.395,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 1244.37,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 929.12,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 666.945,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 650.275,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 643.97,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 632.8249999999999,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 650.225,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 667.025,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 667.375,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 649.6750000000001,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 661.925,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 669.075,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1046.465,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1330.875,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1321.84,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1314.0,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1333.745,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1375.2,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 1295.51,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 1088.51,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 706.83,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 670.075,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 650.875,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 652.32,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 673.03,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 670.67,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 676.505,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 661.38,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 653.705,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 648.295,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 718.14,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 923.595,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1313.985,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1316.405,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1318.68,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1330.32,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1351.62,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 1329.775,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 1271.8449999999998,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 893.695,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 661.08,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 656.82,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 659.1,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 681.42,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 678.48,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 666.72,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 675.185,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 665.28,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 659.52,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 660.9,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 775.21,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1239.3949999999998,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1326.835,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1343.215,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1397.065,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 1407.935,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 1271.4950000000001,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 820.53,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 662.815,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 655.99,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 658.32,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 673.5,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 662.885,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 664.015,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 657.61,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 665.695,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 665.095,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 666.11,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 969.675,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1384.75,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1341.575,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1332.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1322.895,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1290.0049999999999,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 1422.825,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 1195.4050000000002,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 740.295,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 652.69,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 635.8900000000001,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 641.805,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 645.9,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 618.395,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 639.81,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 657.905,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 682.685,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1068.485,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1360.93,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1371.6,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1356.995,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1289.585,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 1072.255,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 851.29,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1324.05,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1410.78,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1353.8,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1364.31,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1427.415,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1377.79,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 1267.07,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 903.6,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 656.895,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 632.9,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 639.2099999999999,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 651.2,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 653.205,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 677.31,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 1071.405,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1350.7,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1373.8,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1372.595,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1291.19,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 957.595,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 693.905,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1162.995,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1408.505,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1408.4,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1397.8,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1411.505,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1465.9,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 1334.115,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 916.185,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 649.105,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 636.1999999999999,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 641.195,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 641.9999999999999,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 651.695,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 667.0,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 1017.185,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 1557.425,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 1524.005,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 1356.33,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 1013.005,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 679.205,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 1033.0,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 1306.975,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1356.8,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1332.0,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1330.6,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1392.385,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1461.095,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 1300.86,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 963.1,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 655.635,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 642.29,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 664.895,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 654.5150000000001,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 655.9,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 652.705,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 653.405,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 657.695,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 655.105,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 651.9,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 933.405,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1373.79,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1368.005,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1372.18,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1396.225,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1305.8349999999998,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 1277.5950000000003,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 1112.505,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 701.005,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 658.11,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 661.29,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 666.995,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 682.41,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 670.21,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 676.885,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 666.81,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 668.35,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 670.71,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 673.97,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 965.275,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1293.025,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1324.435,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1359.695,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1365.175,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1484.5,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 1373.9,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 1192.075,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 760.855,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 636.69,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 650.095,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 654.69,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 673.795,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 656.015,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 658.585,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 659.32,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 660.39,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 673.8,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 649.715,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 656.405,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 757.905,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 1230.43,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1360.27,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1379.025,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1397.015,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1369.58,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1383.69,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1463.79,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 1196.65,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 759.4,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 650.405,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 662.105,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 654.685,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 654.52,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 655.885,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 665.62,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 651.555,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 670.605,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 1015.165,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 1247.94,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1289.25,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 1329.125,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1356.9,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1361.34,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1477.735,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1418.29,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 1271.235,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 952.585,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 660.065,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 647.255,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 652.055,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 656.62,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 664.88,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 819.785,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1267.94,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1299.675,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1306.645,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1279.7800000000002,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 1097.485,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 1251.445,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1313.1,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1302.9799999999998,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1300.425,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1287.9,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1324.11,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1367.615,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 1273.505,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 900.185,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 659.775,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 642.8149999999999,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 654.97,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 659.63,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 649.49,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 651.975,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 669.675,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 694.4,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1070.865,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1262.16,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1354.58,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1304.185,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 1142.035,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 711.105,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 777.79,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1204.29,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1323.58,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1339.725,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1343.54,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1405.345,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1358.86,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 1278.145,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 842.89,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 682.13,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 664.21,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 662.7,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 668.1,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 665.03,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 654.07,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 1068.585,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 1647.92,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 1503.96,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 1355.435,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 1316.25,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 1148.295,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 699.615,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 971.59,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 1299.1450000000002,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 1336.895,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 1376.195,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 1433.23,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 1446.9,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 1374.79,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 1235.87,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 863.195,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 645.77,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 645.3899999999999,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 658.735,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 663.96,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 677.705,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 652.135,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 648.97,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 661.805,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 665.7,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 666.43,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 804.74,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 591.615,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 424.045,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 432.06,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 427.015,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 336.23,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 399.17,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 585.97,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 578.6250000000001,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 518.4,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 538.02,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 558.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 558.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 558.955,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 625.5,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 671.165,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 685.8,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 670.74,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 665.64,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 652.8600000000001,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 639.73,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 635.87,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 769.98,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1165.9999999999998,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 1171.08,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1152.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1152.36,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1213.355,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 1424.155,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 915.505,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 658.25,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 652.745,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 655.565,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 653.045,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 663.66,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 660.845,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 660.31,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 656.76,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 659.04,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 903.32,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 1232.1,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1195.145,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1183.1449999999998,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1178.15,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1254.495,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1338.37,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 1182.5099999999998,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 808.685,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 674.65,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 662.05,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 559.385,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 181.475,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 590.54,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 650.705,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 663.05,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 666.9,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 666.28,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 660.38,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 652.7650000000001,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 902.46,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 1222.3,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1210.7600000000002,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1190.3500000000001,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1197.3749999999998,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1242.8950000000002,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 1380.46,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 1262.535,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 846.04,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 683.055,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 662.355,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 650.45,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 670.645,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 994.75,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1207.11,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1188.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1186.285,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1217.52,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 815.23,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 842.33,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1183.925,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1165.2,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1156.155,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1152.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1172.0049999999999,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1307.58,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1307.015,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 1052.725,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 724.55,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 685.8,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 669.255,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 659.355,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 653.79,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 963.765,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1216.585,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1111.03,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1085.79,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1125.87,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 751.695,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 856.1,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 1161.8649999999998,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1104.26,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1098.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1098.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1107.48,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1306.94,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 1296.2299999999998,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 995.93,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 703.315,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 691.47,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 671.36,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 666.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 649.46,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 649.84,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 660.38,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 1002.62,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 1290.955,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 1286.655,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 1297.8149999999998,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 1279.76,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 1150.68,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 749.465,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1025.925,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1089.57,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1080.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1058.095,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 760.58,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 684.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 668.965,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 665.06,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 648.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 648.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 656304.3698187311,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H03',
              coreData: {
                totalConsumption: 831758.7393766679,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 992.0833333333333,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 978.5694444444446,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 999.5833333333333,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 996.2638888888889,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 983.1041666666665,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 998.2708333333333,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 1546.2152777777778,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 1627.0416666666665,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 1532.166666666667,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 1569.9166666666665,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 1476.9027777777778,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 1270.1875,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 972.6805555555557,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 957.6875,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 957.7291666666667,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 966.8541666666665,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 968.9791666666665,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 970.0,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 994.0763888888888,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 1690.6597222222222,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 2011.7083333333335,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 1777.9236111111109,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 1573.0902777777778,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 1003.6458333333335,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 996.4444444444443,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 1179.0902777777776,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 1534.1875,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 1480.1666666666665,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 1442.3055555555557,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 1584.9513888888891,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 1385.1875,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 979.5902777777778,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 976.2569444444446,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 991.0763888888889,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 995.6111111111111,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 998.1388888888889,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 989.3611111111111,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 985.0,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 989.7986111111111,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 981.6666666666667,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 986.8680555555554,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 1308.375,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 1673.7013888888887,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 1513.1180555555557,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 1516.8680555555557,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 1688.8472222222222,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 1351.1736111111109,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 969.7847222222222,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 971.0486111111111,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 971.2430555555554,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 964.1666666666667,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 972.2916666666667,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 950.4166666666667,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 963.5486111111111,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 1019.3472222222223,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 1970.3611111111113,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 2180.5972222222226,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 2116.4513888888887,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 1984.1875000000002,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 1099.486111111111,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 990.5277777777778,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 1301.5208333333335,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 1486.6527777777778,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 1404.791666666667,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 1422.3125,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 1610.6597222222224,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 1611.4027777777778,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 968.1458333333333,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 971.6736111111111,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 1003.9583333333333,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 1012.2847222222223,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 978.1180555555557,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 979.1597222222222,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 999.375,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 974.7847222222223,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 1005.3958333333335,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 1840.1527777777776,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 1921.3125000000002,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 1764.7777777777776,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 1830.4027777777776,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 1378.5416666666665,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 980.4166666666667,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 1341.1388888888887,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 1558.7638888888891,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 1490.0069444444443,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 1457.0902777777778,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 1577.2361111111113,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 1751.5347222222222,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 1053.6250000000002,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 977.7083333333333,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 959.5833333333333,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 1011.6597222222222,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 1001.0486111111111,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 994.7986111111111,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 957.2986111111111,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 961.8680555555554,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 1113.763888888889,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 1992.7777777777778,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 1872.7916666666665,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 1751.854166666667,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 1905.1319444444443,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 1662.8125,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 1005.8680555555557,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 976.2361111111112,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 1372.5138888888887,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 1516.2847222222222,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 1470.0208333333335,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 1428.5555555555557,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 1535.9861111111113,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 1149.8472222222222,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 936.1875,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 997.9166666666665,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 981.0555555555557,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 986.8680555555554,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 984.7986111111112,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 982.2708333333333,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 988.5486111111111,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 905.2777777777777,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 701.3402777777778,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 1073.1944444444443,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 1863.3680555555557,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 2017.1111111111109,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 1429.9027777777778,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 974.1666666666667,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 1281.7152777777778,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 1589.0,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 1550.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 1546.6388888888887,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 1757.1180555555557,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 1430.1944444444443,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 974.3819444444443,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 970.6041666666665,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 979.1666666666665,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 993.1041666666667,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 688.4305555555557,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 804.9027777777777,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 966.2222222222223,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 1043.111111111111,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 1714.4513888888887,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 1763.1944444444443,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 1731.8888888888887,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 1748.7430555555557,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 1288.6666666666667,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 966.2638888888889,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 1320.2638888888887,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 1733.2083333333335,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 1596.7013888888891,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 1610.3750000000002,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 1698.6249999999998,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 1532.0833333333335,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 986.5277777777777,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 993.5763888888889,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 978.7569444444446,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 986.8472222222222,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 989.5763888888889,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 1047.8194444444443,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 1627.166666666667,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 1676.1944444444446,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 1699.854166666667,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 1785.4513888888887,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 1351.6944444444443,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 971.2430555555554,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 1386.2777777777778,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 1717.416666666667,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 1620.8888888888891,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 1620.7777777777778,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 1823.3472222222222,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 1569.0138888888887,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 975.2361111111112,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 963.9791666666667,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 971.8472222222223,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 964.9930555555554,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 975.4166666666667,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 989.1736111111111,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 1234.6458333333333,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 1616.2638888888887,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 1582.2569444444443,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 1594.5833333333335,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 1715.1527777777776,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 1391.0694444444443,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 986.9097222222223,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 970.6527777777778,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 954.5833333333333,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 953.3333333333333,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 953.9236111111111,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 1011.4861111111111,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 1761.5347222222222,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 2014.3055555555557,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 1925.979166666667,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 1871.125,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 1203.3611111111113,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 1368.1458333333335,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 1600.2083333333335,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 1571.8333333333335,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 1581.4236111111109,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 1761.5486111111109,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 1553.020833333333,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 999.4861111111111,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 960.6666666666667,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 1010.6111111111112,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 1884.1875000000002,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 2013.8263888888891,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 1899.0902777777776,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 1969.1527777777778,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 1228.8819444444443,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 994.3333333333333,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 1408.3541666666665,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 1693.9652777777778,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 1544.4166666666665,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 1577.8333333333335,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 1874.6388888888887,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 1606.9236111111109,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 964.7638888888889,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 973.6597222222222,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 998.7430555555554,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 980.25,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 1093.388888888889,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 2021.625,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 2018.2222222222222,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 1984.8055555555554,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 2010.5347222222222,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 1333.9236111111113,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 966.5069444444446,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 964.3402777777778,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 1463.5,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 1702.3194444444446,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 1588.8472222222222,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 1590.1597222222222,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 1775.9652777777778,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 1573.3958333333335,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 958.3472222222222,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 995.9861111111111,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 1000.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 994.4305555555554,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 959.4236111111112,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 950.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 968.2152777777778,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 1478.375,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 1878.3263888888891,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 1940.6041666666665,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 2077.166666666667,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 1571.229166666667,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 971.5138888888889,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 968.8888888888888,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 1145.923611111111,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 1650.1041666666665,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 1594.2777777777778,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 1591.0208333333335,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 1774.9722222222222,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 1960.7777777777778,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 1108.7777777777776,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 978.8958333333333,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 993.1944444444443,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 978.8958333333333,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 939.1388888888889,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 965.2777777777778,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 975.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 1036.5625,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 1843.3819444444446,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 1757.8055555555554,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 1679.4930555555557,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 1837.8194444444443,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 1448.5902777777778,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 635.6597222222222,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 633.7916666666666,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 1358.8680555555557,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 1704.8055555555557,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 1606.104166666667,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 1624.4583333333335,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 1740.1875,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 1526.2152777777778,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 664.9583333333333,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 653.4791666666667,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 665.6875,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 730.8958333333333,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 1780.6805555555557,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 1834.9861111111109,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 1740.8402777777778,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 1776.9444444444446,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 1311.4930555555557,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 645.7013888888888,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 625.1458333333333,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 1194.3333333333333,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 1675.5347222222222,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 1654.7152777777778,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 1640.5625,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 1740.2916666666665,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 1573.9027777777778,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 634.9861111111111,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 610.1388888888889,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 648.0486111111112,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 632.7847222222222,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 614.4444444444445,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 619.7291666666666,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 984.4097222222222,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 1645.2708333333335,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 1573.6111111111109,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 1567.3472222222222,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 1711.6180555555557,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 1746.1944444444443,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 734.1319444444443,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 635.9652777777778,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 648.3263888888889,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 643.3263888888889,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 634.4375,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 696.125,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 1772.2847222222222,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 1998.1527777777778,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 1922.0555555555557,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 1910.0486111111109,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 1065.9930555555557,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 611.5416666666666,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 618.4652777777778,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 1239.9166666666665,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 1836.1319444444443,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 1722.8194444444443,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 1688.0347222222222,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 1907.1527777777778,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 1585.4722222222222,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 711.6388888888889,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 697.0555555555554,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 1932.875,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 2178.2361111111113,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 2049.534722222222,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 2097.3263888888887,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 1453.3680555555557,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 695.3888888888889,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 615.5347222222222,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 1161.0555555555554,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 1799.395833333333,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 1695.5833333333335,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 1678.4513888888887,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 1871.0416666666665,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 1432.625,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 679.6111111111111,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 598.8680555555555,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 601.375,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 803.0763888888888,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 2035.0833333333335,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 2054.7916666666665,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 1965.5486111111109,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 1960.8680555555557,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 1111.138888888889,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 629.8333333333333,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 626.6875,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 1344.6597222222222,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 1749.0486111111109,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 1679.9583333333335,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 1618.0277777777778,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 1769.5694444444446,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 1460.9375,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 616.6875,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 617.4861111111112,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 616.7708333333334,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 620.7152777777778,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 620.125,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 739.7847222222222,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 1942.3055555555557,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 2061.979166666667,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 1921.4722222222222,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 1950.3125,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 924.9930555555557,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 624.5833333333334,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 1153.8958333333333,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 1745.8888888888891,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 1664.9652777777778,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 1626.1597222222222,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 1861.1249999999998,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 1487.8888888888891,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 614.1388888888889,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 606.2361111111112,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 600.4236111111111,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 613.4444444444443,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 604.8888888888888,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 618.0833333333333,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 636.9097222222222,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 1382.5138888888887,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 1626.5625,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 1575.3125,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 1618.4375,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 1461.4097222222222,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 678.6319444444446,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 1080.5555555555554,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 1542.0902777777778,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 1533.6458333333335,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 1512.9097222222222,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 1598.5486111111113,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 1391.1111111111113,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 630.7430555555555,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 632.1875,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 642.3958333333334,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 640.9236111111112,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 639.4791666666666,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 625.4166666666666,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 625.1041666666666,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 639.1666666666666,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 766.8472222222222,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 1687.9444444444443,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 1655.1527777777778,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 1666.4722222222224,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 1657.8194444444446,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 990.375,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 631.6041666666667,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 637.5972222222222,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 1093.4930555555554,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 1575.0972222222222,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 1571.5763888888887,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 1570.7083333333335,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 1736.8888888888891,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 1304.576388888889,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 629.2638888888889,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 645.4236111111111,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 626.1527777777777,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 636.7569444444445,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 660.1319444444443,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 665.3194444444446,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 658.0138888888889,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 664.0763888888889,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 640.7430555555557,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 627.1736111111111,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 629.5,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 631.3263888888889,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 645.4097222222222,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 633.2361111111111,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 627.5,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 704.8680555555554,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 1690.9513888888887,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 1843.8541666666665,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 1817.3541666666665,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 1853.5416666666665,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 1186.0763888888887,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 645.2430555555557,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 664.7569444444446,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 1096.8263888888887,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 1475.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 1461.1666666666665,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 1450.916666666667,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 1667.3680555555557,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 1551.0069444444443,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 633.2083333333334,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 601.25,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 600.4166666666666,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 600.2916666666666,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 627.9166666666666,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 1483.9027777777778,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 1756.25,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 1640.0,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 1669.3472222222222,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 1365.9375,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 652.3125,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 633.4166666666666,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 1055.701388888889,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 1550.4236111111113,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 1525.0,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 1525.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 1638.9513888888887,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 1638.0486111111109,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 654.5138888888889,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 578.6875,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 575.8333333333334,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 575.9166666666666,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 610.4930555555555,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 781.0972222222222,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 1804.5208333333335,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 1908.6388888888887,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 1776.1805555555554,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 1800.666666666667,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 1243.2916666666667,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 654.5486111111111,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 638.4027777777778,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 1124.513888888889,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 1571.5069444444443,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 1550.0,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 1555.9097222222222,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 1570.3888888888887,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 1031.6180555555557,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 653.2638888888889,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 651.5902777777778,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 654.1597222222223,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 663.7569444444446,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 650.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 553.5902777777777,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 181.51388888888886,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 617.0416666666667,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 662.8472222222222,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 736.2638888888889,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 731.7291666666667,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 1199.7638888888887,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 1862.9305555555554,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 1978.9444444444446,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 1382.7638888888887,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 610.9791666666666,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 613.8194444444445,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 961.7777777777778,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 1562.9166666666665,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 1550.2847222222222,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 1562.7152777777778,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 1655.2291666666665,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 1500.6597222222222,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 642.7083333333333,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 605.8333333333334,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 602.4305555555555,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 605.0694444444445,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 644.2291666666666,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 1052.1180555555557,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 1590.4236111111109,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 1546.4583333333335,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 1560.1944444444446,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 1701.125,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 988.5138888888889,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 654.2916666666666,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 1110.8402777777778,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 1525.0,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 1512.8541666666665,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 1500.0,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 1544.25,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 1869.354166666667,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 1150.9444444444443,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 602.8541666666666,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 612.9027777777778,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 619.2291666666666,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 625.0,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 851.4583333333333,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 1701.1805555555554,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 1629.0972222222222,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 1418.8680555555557,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 1353.2708333333335,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 1161.1875,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 668.8055555555557,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 665.0625,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 986.6041666666665,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 1405.8472222222222,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 1375.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 1384.2222222222222,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 1421.2708333333335,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 1772.458333333333,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 909.9583333333333,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 601.6527777777778,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 600.8333333333334,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 622.5486111111112,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 617.0833333333334,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 637.9652777777777,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 662.1319444444446,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 675.0,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 671.2569444444443,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 652.8611111111111,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 666.2986111111111,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 661.8263888888889,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 1117.0833333333333,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 1384.5277777777778,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 1350.3611111111109,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 1383.4861111111113,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 1545.2916666666665,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 1852.229166666667,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 891.2847222222222,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 603.7361111111111,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 600.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 615.6717716672468,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 615.6717716672468,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 1028384.0240318915,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
            {
              name: 'H02',
              coreData: {
                totalConsumption: 334532.8283333333,
                airflow: [
                  {
                    key: '2022-04-08 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-08 09:00:00+00:00',
                    value: 452.7713888888889,
                  },
                  {
                    key: '2022-04-08 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-08 11:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-08 12:00:00+00:00',
                    value: 442.27861111111116,
                  },
                  {
                    key: '2022-04-08 13:00:00+00:00',
                    value: 446.41055555555556,
                  },
                  {
                    key: '2022-04-08 14:00:00+00:00',
                    value: 458.15,
                  },
                  {
                    key: '2022-04-08 15:00:00+00:00',
                    value: 451.7608333333334,
                  },
                  {
                    key: '2022-04-08 16:00:00+00:00',
                    value: 444.26666666666665,
                  },
                  {
                    key: '2022-04-08 17:00:00+00:00',
                    value: 452.0158333333334,
                  },
                  {
                    key: '2022-04-08 18:00:00+00:00',
                    value: 633.2924999999999,
                  },
                  {
                    key: '2022-04-08 19:00:00+00:00',
                    value: 562.0247222222222,
                  },
                  {
                    key: '2022-04-08 20:00:00+00:00',
                    value: 983.7191666666668,
                  },
                  {
                    key: '2022-04-08 21:00:00+00:00',
                    value: 638.5058333333334,
                  },
                  {
                    key: '2022-04-08 22:00:00+00:00',
                    value: 446.93944444444446,
                  },
                  {
                    key: '2022-04-08 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-09 00:00:00+00:00',
                    value: 443.98333333333335,
                  },
                  {
                    key: '2022-04-09 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-09 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-09 03:00:00+00:00',
                    value: 442.2833333333333,
                  },
                  {
                    key: '2022-04-09 04:00:00+00:00',
                    value: 454.05583333333334,
                  },
                  {
                    key: '2022-04-09 05:00:00+00:00',
                    value: 454.5941666666667,
                  },
                  {
                    key: '2022-04-09 06:00:00+00:00',
                    value: 445.12138888888893,
                  },
                  {
                    key: '2022-04-09 07:00:00+00:00',
                    value: 450.80694444444447,
                  },
                  {
                    key: '2022-04-09 08:00:00+00:00',
                    value: 455.4488888888889,
                  },
                  {
                    key: '2022-04-09 09:00:00+00:00',
                    value: 458.8725,
                  },
                  {
                    key: '2022-04-09 10:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 11:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 12:00:00+00:00',
                    value: 456.4547222222222,
                  },
                  {
                    key: '2022-04-09 13:00:00+00:00',
                    value: 458.7166666666667,
                  },
                  {
                    key: '2022-04-09 14:00:00+00:00',
                    value: 457.00722222222225,
                  },
                  {
                    key: '2022-04-09 15:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 16:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 17:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-09 19:00:00+00:00',
                    value: 451.48222222222216,
                  },
                  {
                    key: '2022-04-09 20:00:00+00:00',
                    value: 444.9891666666666,
                  },
                  {
                    key: '2022-04-09 21:00:00+00:00',
                    value: 457.7202777777778,
                  },
                  {
                    key: '2022-04-09 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-09 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-10 00:00:00+00:00',
                    value: 443.41666666666663,
                  },
                  {
                    key: '2022-04-10 01:00:00+00:00',
                    value: 444.7011111111111,
                  },
                  {
                    key: '2022-04-10 02:00:00+00:00',
                    value: 450.77861111111116,
                  },
                  {
                    key: '2022-04-10 03:00:00+00:00',
                    value: 454.1786111111111,
                  },
                  {
                    key: '2022-04-10 04:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 05:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 06:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 07:00:00+00:00',
                    value: 450.34888888888884,
                  },
                  {
                    key: '2022-04-10 08:00:00+00:00',
                    value: 447.24638888888893,
                  },
                  {
                    key: '2022-04-10 09:00:00+00:00',
                    value: 448.6583333333333,
                  },
                  {
                    key: '2022-04-10 10:00:00+00:00',
                    value: 456.3177777777778,
                  },
                  {
                    key: '2022-04-10 11:00:00+00:00',
                    value: 451.7655555555555,
                  },
                  {
                    key: '2022-04-10 12:00:00+00:00',
                    value: 456.03444444444443,
                  },
                  {
                    key: '2022-04-10 13:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 14:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 15:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 16:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-10 17:00:00+00:00',
                    value: 445.8202777777778,
                  },
                  {
                    key: '2022-04-10 18:00:00+00:00',
                    value: 451.7938888888889,
                  },
                  {
                    key: '2022-04-10 19:00:00+00:00',
                    value: 444.9702777777778,
                  },
                  {
                    key: '2022-04-10 20:00:00+00:00',
                    value: 450.36777777777775,
                  },
                  {
                    key: '2022-04-10 21:00:00+00:00',
                    value: 443.2655555555555,
                  },
                  {
                    key: '2022-04-10 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-10 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 02:00:00+00:00',
                    value: 452.06305555555554,
                  },
                  {
                    key: '2022-04-11 03:00:00+00:00',
                    value: 449.2202777777778,
                  },
                  {
                    key: '2022-04-11 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 05:00:00+00:00',
                    value: 449.51777777777784,
                  },
                  {
                    key: '2022-04-11 06:00:00+00:00',
                    value: 454.325,
                  },
                  {
                    key: '2022-04-11 07:00:00+00:00',
                    value: 452.62972222222226,
                  },
                  {
                    key: '2022-04-11 08:00:00+00:00',
                    value: 443.8416666666667,
                  },
                  {
                    key: '2022-04-11 09:00:00+00:00',
                    value: 455.1797222222222,
                  },
                  {
                    key: '2022-04-11 10:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-11 11:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-11 12:00:00+00:00',
                    value: 448.9463888888889,
                  },
                  {
                    key: '2022-04-11 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 14:00:00+00:00',
                    value: 446.5333333333333,
                  },
                  {
                    key: '2022-04-11 15:00:00+00:00',
                    value: 442.4297222222222,
                  },
                  {
                    key: '2022-04-11 16:00:00+00:00',
                    value: 450.64166666666665,
                  },
                  {
                    key: '2022-04-11 17:00:00+00:00',
                    value: 453.18694444444446,
                  },
                  {
                    key: '2022-04-11 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-11 19:00:00+00:00',
                    value: 458.4333333333333,
                  },
                  {
                    key: '2022-04-11 20:00:00+00:00',
                    value: 442.14166666666665,
                  },
                  {
                    key: '2022-04-11 21:00:00+00:00',
                    value: 443.12861111111107,
                  },
                  {
                    key: '2022-04-11 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-11 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 01:00:00+00:00',
                    value: 443.4213888888889,
                  },
                  {
                    key: '2022-04-12 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 08:00:00+00:00',
                    value: 442.27861111111116,
                  },
                  {
                    key: '2022-04-12 09:00:00+00:00',
                    value: 449.225,
                  },
                  {
                    key: '2022-04-12 10:00:00+00:00',
                    value: 454.8963888888889,
                  },
                  {
                    key: '2022-04-12 11:00:00+00:00',
                    value: 446.1036111111111,
                  },
                  {
                    key: '2022-04-12 12:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-12 13:00:00+00:00',
                    value: 444.9702777777778,
                  },
                  {
                    key: '2022-04-12 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 16:00:00+00:00',
                    value: 442.2833333333333,
                  },
                  {
                    key: '2022-04-12 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 18:00:00+00:00',
                    value: 486.61083333333335,
                  },
                  {
                    key: '2022-04-12 19:00:00+00:00',
                    value: 537.4644444444444,
                  },
                  {
                    key: '2022-04-12 20:00:00+00:00',
                    value: 454.1833333333333,
                  },
                  {
                    key: '2022-04-12 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-12 22:00:00+00:00',
                    value: 431.66305555555556,
                  },
                  {
                    key: '2022-04-12 23:00:00+00:00',
                    value: 436.75361111111107,
                  },
                  {
                    key: '2022-04-13 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 03:00:00+00:00',
                    value: 449.50361111111107,
                  },
                  {
                    key: '2022-04-13 04:00:00+00:00',
                    value: 443.56305555555554,
                  },
                  {
                    key: '2022-04-13 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-13 09:00:00+00:00',
                    value: 451.7655555555555,
                  },
                  {
                    key: '2022-04-13 10:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-13 11:00:00+00:00',
                    value: 458.83,
                  },
                  {
                    key: '2022-04-13 12:00:00+00:00',
                    value: 564.291388888889,
                  },
                  {
                    key: '2022-04-13 13:00:00+00:00',
                    value: 443.84638888888884,
                  },
                  {
                    key: '2022-04-13 14:00:00+00:00',
                    value: 440.8666666666666,
                  },
                  {
                    key: '2022-04-13 15:00:00+00:00',
                    value: 438.9966666666667,
                  },
                  {
                    key: '2022-04-13 16:00:00+00:00',
                    value: 445.8202777777778,
                  },
                  {
                    key: '2022-04-13 17:00:00+00:00',
                    value: 534.4752777777778,
                  },
                  {
                    key: '2022-04-13 18:00:00+00:00',
                    value: 461.9325,
                  },
                  {
                    key: '2022-04-13 19:00:00+00:00',
                    value: 508.15361111111116,
                  },
                  {
                    key: '2022-04-13 20:00:00+00:00',
                    value: 455.3213888888889,
                  },
                  {
                    key: '2022-04-13 21:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-13 22:00:00+00:00',
                    value: 454.33444444444444,
                  },
                  {
                    key: '2022-04-13 23:00:00+00:00',
                    value: 442.5713888888889,
                  },
                  {
                    key: '2022-04-14 00:00:00+00:00',
                    value: 444.26194444444445,
                  },
                  {
                    key: '2022-04-14 01:00:00+00:00',
                    value: 448.93694444444446,
                  },
                  {
                    key: '2022-04-14 02:00:00+00:00',
                    value: 445.8438888888889,
                  },
                  {
                    key: '2022-04-14 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 04:00:00+00:00',
                    value: 452.2,
                  },
                  {
                    key: '2022-04-14 05:00:00+00:00',
                    value: 442.85,
                  },
                  {
                    key: '2022-04-14 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 08:00:00+00:00',
                    value: 453.18694444444446,
                  },
                  {
                    key: '2022-04-14 09:00:00+00:00',
                    value: 458.7166666666667,
                  },
                  {
                    key: '2022-04-14 10:00:00+00:00',
                    value: 450.49527777777774,
                  },
                  {
                    key: '2022-04-14 11:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-14 12:00:00+00:00',
                    value: 446.11777777777775,
                  },
                  {
                    key: '2022-04-14 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 17:00:00+00:00',
                    value: 446.38222222222225,
                  },
                  {
                    key: '2022-04-14 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-14 19:00:00+00:00',
                    value: 442.43444444444447,
                  },
                  {
                    key: '2022-04-14 20:00:00+00:00',
                    value: 454.1833333333333,
                  },
                  {
                    key: '2022-04-14 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-14 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 04:00:00+00:00',
                    value: 309.53694444444443,
                  },
                  {
                    key: '2022-04-15 05:00:00+00:00',
                    value: 375.275,
                  },
                  {
                    key: '2022-04-15 06:00:00+00:00',
                    value: 446.8166666666667,
                  },
                  {
                    key: '2022-04-15 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 09:00:00+00:00',
                    value: 457.9941666666666,
                  },
                  {
                    key: '2022-04-15 10:00:00+00:00',
                    value: 452.3511111111111,
                  },
                  {
                    key: '2022-04-15 11:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 14:00:00+00:00',
                    value: 448.51666666666665,
                  },
                  {
                    key: '2022-04-15 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-15 16:00:00+00:00',
                    value: 508.0025,
                  },
                  {
                    key: '2022-04-15 17:00:00+00:00',
                    value: 575.0108333333333,
                  },
                  {
                    key: '2022-04-15 18:00:00+00:00',
                    value: 451.78916666666663,
                  },
                  {
                    key: '2022-04-15 19:00:00+00:00',
                    value: 444.83333333333337,
                  },
                  {
                    key: '2022-04-15 20:00:00+00:00',
                    value: 442.9727777777778,
                  },
                  {
                    key: '2022-04-15 21:00:00+00:00',
                    value: 443.0058333333334,
                  },
                  {
                    key: '2022-04-15 22:00:00+00:00',
                    value: 440.4558333333334,
                  },
                  {
                    key: '2022-04-15 23:00:00+00:00',
                    value: 433.3347222222222,
                  },
                  {
                    key: '2022-04-16 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 09:00:00+00:00',
                    value: 442.69416666666666,
                  },
                  {
                    key: '2022-04-16 10:00:00+00:00',
                    value: 442.4391666666667,
                  },
                  {
                    key: '2022-04-16 11:00:00+00:00',
                    value: 457.9894444444445,
                  },
                  {
                    key: '2022-04-16 12:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-16 13:00:00+00:00',
                    value: 453.2058333333334,
                  },
                  {
                    key: '2022-04-16 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 16:00:00+00:00',
                    value: 442.8452777777778,
                  },
                  {
                    key: '2022-04-16 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 18:00:00+00:00',
                    value: 442.28805555555556,
                  },
                  {
                    key: '2022-04-16 19:00:00+00:00',
                    value: 513.8297222222222,
                  },
                  {
                    key: '2022-04-16 20:00:00+00:00',
                    value: 511.97388888888884,
                  },
                  {
                    key: '2022-04-16 21:00:00+00:00',
                    value: 443.0058333333334,
                  },
                  {
                    key: '2022-04-16 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-16 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 10:00:00+00:00',
                    value: 454.87277777777774,
                  },
                  {
                    key: '2022-04-17 11:00:00+00:00',
                    value: 449.9286111111111,
                  },
                  {
                    key: '2022-04-17 12:00:00+00:00',
                    value: 458.4333333333333,
                  },
                  {
                    key: '2022-04-17 13:00:00+00:00',
                    value: 458.0272222222222,
                  },
                  {
                    key: '2022-04-17 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-17 15:00:00+00:00',
                    value: 447.5108333333334,
                  },
                  {
                    key: '2022-04-17 16:00:00+00:00',
                    value: 446.4152777777778,
                  },
                  {
                    key: '2022-04-17 17:00:00+00:00',
                    value: 505.8019444444445,
                  },
                  {
                    key: '2022-04-17 18:00:00+00:00',
                    value: 555.3947222222222,
                  },
                  {
                    key: '2022-04-17 19:00:00+00:00',
                    value: 459.93027777777775,
                  },
                  {
                    key: '2022-04-17 20:00:00+00:00',
                    value: 497.61833333333334,
                  },
                  {
                    key: '2022-04-17 21:00:00+00:00',
                    value: 435.6438888888889,
                  },
                  {
                    key: '2022-04-17 22:00:00+00:00',
                    value: 426.98333333333335,
                  },
                  {
                    key: '2022-04-17 23:00:00+00:00',
                    value: 425.0,
                  },
                  {
                    key: '2022-04-18 00:00:00+00:00',
                    value: 439.5727777777778,
                  },
                  {
                    key: '2022-04-18 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 09:00:00+00:00',
                    value: 446.25,
                  },
                  {
                    key: '2022-04-18 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 11:00:00+00:00',
                    value: 454.3013888888889,
                  },
                  {
                    key: '2022-04-18 12:00:00+00:00',
                    value: 456.33194444444445,
                  },
                  {
                    key: '2022-04-18 13:00:00+00:00',
                    value: 443.98333333333335,
                  },
                  {
                    key: '2022-04-18 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-18 18:00:00+00:00',
                    value: 445.4,
                  },
                  {
                    key: '2022-04-18 19:00:00+00:00',
                    value: 494.0341666666667,
                  },
                  {
                    key: '2022-04-18 20:00:00+00:00',
                    value: 458.2491666666667,
                  },
                  {
                    key: '2022-04-18 21:00:00+00:00',
                    value: 435.36527777777775,
                  },
                  {
                    key: '2022-04-18 22:00:00+00:00',
                    value: 433.89666666666665,
                  },
                  {
                    key: '2022-04-18 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 09:00:00+00:00',
                    value: 443.5252777777777,
                  },
                  {
                    key: '2022-04-19 10:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-19 11:00:00+00:00',
                    value: 446.13666666666666,
                  },
                  {
                    key: '2022-04-19 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-19 14:00:00+00:00',
                    value: 434.80805555555554,
                  },
                  {
                    key: '2022-04-19 15:00:00+00:00',
                    value: 439.28,
                  },
                  {
                    key: '2022-04-19 16:00:00+00:00',
                    value: 443.8133333333333,
                  },
                  {
                    key: '2022-04-19 17:00:00+00:00',
                    value: 528.1333333333333,
                  },
                  {
                    key: '2022-04-19 18:00:00+00:00',
                    value: 456.62472222222226,
                  },
                  {
                    key: '2022-04-19 19:00:00+00:00',
                    value: 454.0133333333334,
                  },
                  {
                    key: '2022-04-19 20:00:00+00:00',
                    value: 446.9866666666666,
                  },
                  {
                    key: '2022-04-19 21:00:00+00:00',
                    value: 432.53666666666663,
                  },
                  {
                    key: '2022-04-19 22:00:00+00:00',
                    value: 435.31805555555553,
                  },
                  {
                    key: '2022-04-19 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 10:00:00+00:00',
                    value: 442.2833333333333,
                  },
                  {
                    key: '2022-04-20 11:00:00+00:00',
                    value: 445.2252777777778,
                  },
                  {
                    key: '2022-04-20 12:00:00+00:00',
                    value: 512.4272222222222,
                  },
                  {
                    key: '2022-04-20 13:00:00+00:00',
                    value: 456.6436111111111,
                  },
                  {
                    key: '2022-04-20 14:00:00+00:00',
                    value: 432.19666666666666,
                  },
                  {
                    key: '2022-04-20 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 18:00:00+00:00',
                    value: 454.8586111111111,
                  },
                  {
                    key: '2022-04-20 19:00:00+00:00',
                    value: 450.3913888888889,
                  },
                  {
                    key: '2022-04-20 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-20 21:00:00+00:00',
                    value: 439.62944444444446,
                  },
                  {
                    key: '2022-04-20 22:00:00+00:00',
                    value: 431.3325,
                  },
                  {
                    key: '2022-04-20 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 08:00:00+00:00',
                    value: 448.5591666666667,
                  },
                  {
                    key: '2022-04-21 09:00:00+00:00',
                    value: 539.1172222222222,
                  },
                  {
                    key: '2022-04-21 10:00:00+00:00',
                    value: 442.28805555555556,
                  },
                  {
                    key: '2022-04-21 11:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 17:00:00+00:00',
                    value: 445.22055555555556,
                  },
                  {
                    key: '2022-04-21 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-04-21 19:00:00+00:00',
                    value: 455.2175,
                  },
                  {
                    key: '2022-04-21 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-21 22:00:00+00:00',
                    value: 426.60555555555555,
                  },
                  {
                    key: '2022-04-21 23:00:00+00:00',
                    value: 436.1538888888889,
                  },
                  {
                    key: '2022-04-22 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 04:00:00+00:00',
                    value: 440.5738888888889,
                  },
                  {
                    key: '2022-04-22 05:00:00+00:00',
                    value: 414.0302777777777,
                  },
                  {
                    key: '2022-04-22 06:00:00+00:00',
                    value: 435.3936111111111,
                  },
                  {
                    key: '2022-04-22 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 09:00:00+00:00',
                    value: 442.27861111111116,
                  },
                  {
                    key: '2022-04-22 10:00:00+00:00',
                    value: 444.36583333333334,
                  },
                  {
                    key: '2022-04-22 11:00:00+00:00',
                    value: 444.4508333333334,
                  },
                  {
                    key: '2022-04-22 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 17:00:00+00:00',
                    value: 474.41333333333336,
                  },
                  {
                    key: '2022-04-22 18:00:00+00:00',
                    value: 479.0033333333333,
                  },
                  {
                    key: '2022-04-22 19:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-22 20:00:00+00:00',
                    value: 454.7736111111111,
                  },
                  {
                    key: '2022-04-22 21:00:00+00:00',
                    value: 479.00805555555553,
                  },
                  {
                    key: '2022-04-22 22:00:00+00:00',
                    value: 425.0,
                  },
                  {
                    key: '2022-04-22 23:00:00+00:00',
                    value: 425.0,
                  },
                  {
                    key: '2022-04-23 00:00:00+00:00',
                    value: 441.2491666666667,
                  },
                  {
                    key: '2022-04-23 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 07:00:00+00:00',
                    value: 435.9508333333334,
                  },
                  {
                    key: '2022-04-23 08:00:00+00:00',
                    value: 435.8563888888889,
                  },
                  {
                    key: '2022-04-23 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 10:00:00+00:00',
                    value: 456.2516666666667,
                  },
                  {
                    key: '2022-04-23 11:00:00+00:00',
                    value: 451.81277777777774,
                  },
                  {
                    key: '2022-04-23 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 13:00:00+00:00',
                    value: 453.04055555555556,
                  },
                  {
                    key: '2022-04-23 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-23 15:00:00+00:00',
                    value: 446.8166666666667,
                  },
                  {
                    key: '2022-04-23 16:00:00+00:00',
                    value: 442.09444444444443,
                  },
                  {
                    key: '2022-04-23 17:00:00+00:00',
                    value: 454.9388888888889,
                  },
                  {
                    key: '2022-04-23 18:00:00+00:00',
                    value: 451.7277777777778,
                  },
                  {
                    key: '2022-04-23 19:00:00+00:00',
                    value: 460.6952777777778,
                  },
                  {
                    key: '2022-04-23 20:00:00+00:00',
                    value: 506.0333333333333,
                  },
                  {
                    key: '2022-04-23 21:00:00+00:00',
                    value: 443.60555555555555,
                  },
                  {
                    key: '2022-04-23 22:00:00+00:00',
                    value: 439.4547222222222,
                  },
                  {
                    key: '2022-04-23 23:00:00+00:00',
                    value: 440.8619444444445,
                  },
                  {
                    key: '2022-04-24 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-24 08:00:00+00:00',
                    value: 437.75,
                  },
                  {
                    key: '2022-04-24 09:00:00+00:00',
                    value: 443.1333333333334,
                  },
                  {
                    key: '2022-04-24 10:00:00+00:00',
                    value: 442.85,
                  },
                  {
                    key: '2022-04-24 11:00:00+00:00',
                    value: 453.9897222222222,
                  },
                  {
                    key: '2022-04-24 12:00:00+00:00',
                    value: 458.06027777777774,
                  },
                  {
                    key: '2022-04-24 13:00:00+00:00',
                    value: 451.1611111111111,
                  },
                  {
                    key: '2022-04-24 14:00:00+00:00',
                    value: 445.0222222222222,
                  },
                  {
                    key: '2022-04-24 15:00:00+00:00',
                    value: 446.9063888888889,
                  },
                  {
                    key: '2022-04-24 16:00:00+00:00',
                    value: 453.5316666666667,
                  },
                  {
                    key: '2022-04-24 17:00:00+00:00',
                    value: 494.7802777777777,
                  },
                  {
                    key: '2022-04-24 18:00:00+00:00',
                    value: 556.8208333333333,
                  },
                  {
                    key: '2022-04-24 19:00:00+00:00',
                    value: 697.1086111111111,
                  },
                  {
                    key: '2022-04-24 20:00:00+00:00',
                    value: 560.1594444444444,
                  },
                  {
                    key: '2022-04-24 21:00:00+00:00',
                    value: 508.3330555555556,
                  },
                  {
                    key: '2022-04-24 22:00:00+00:00',
                    value: 433.415,
                  },
                  {
                    key: '2022-04-24 23:00:00+00:00',
                    value: 436.135,
                  },
                  {
                    key: '2022-04-25 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 08:00:00+00:00',
                    value: 442.5713888888889,
                  },
                  {
                    key: '2022-04-25 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 11:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 13:00:00+00:00',
                    value: 433.9769444444444,
                  },
                  {
                    key: '2022-04-25 14:00:00+00:00',
                    value: 433.4102777777778,
                  },
                  {
                    key: '2022-04-25 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 17:00:00+00:00',
                    value: 452.0063888888888,
                  },
                  {
                    key: '2022-04-25 18:00:00+00:00',
                    value: 502.0477777777778,
                  },
                  {
                    key: '2022-04-25 19:00:00+00:00',
                    value: 503.47388888888884,
                  },
                  {
                    key: '2022-04-25 20:00:00+00:00',
                    value: 459.5052777777778,
                  },
                  {
                    key: '2022-04-25 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-25 22:00:00+00:00',
                    value: 439.72388888888884,
                  },
                  {
                    key: '2022-04-25 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 11:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 12:00:00+00:00',
                    value: 443.4213888888889,
                  },
                  {
                    key: '2022-04-26 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 16:00:00+00:00',
                    value: 446.59,
                  },
                  {
                    key: '2022-04-26 17:00:00+00:00',
                    value: 523.3922222222222,
                  },
                  {
                    key: '2022-04-26 18:00:00+00:00',
                    value: 443.04833333333335,
                  },
                  {
                    key: '2022-04-26 19:00:00+00:00',
                    value: 444.5452777777778,
                  },
                  {
                    key: '2022-04-26 20:00:00+00:00',
                    value: 447.66666666666663,
                  },
                  {
                    key: '2022-04-26 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-26 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 10:00:00+00:00',
                    value: 442.08027777777784,
                  },
                  {
                    key: '2022-04-27 11:00:00+00:00',
                    value: 445.8863888888889,
                  },
                  {
                    key: '2022-04-27 12:00:00+00:00',
                    value: 445.4,
                  },
                  {
                    key: '2022-04-27 13:00:00+00:00',
                    value: 437.75,
                  },
                  {
                    key: '2022-04-27 14:00:00+00:00',
                    value: 440.50305555555553,
                  },
                  {
                    key: '2022-04-27 15:00:00+00:00',
                    value: 433.58027777777784,
                  },
                  {
                    key: '2022-04-27 16:00:00+00:00',
                    value: 456.6436111111111,
                  },
                  {
                    key: '2022-04-27 17:00:00+00:00',
                    value: 546.9608333333333,
                  },
                  {
                    key: '2022-04-27 18:00:00+00:00',
                    value: 494.0436111111111,
                  },
                  {
                    key: '2022-04-27 19:00:00+00:00',
                    value: 551.2344444444444,
                  },
                  {
                    key: '2022-04-27 20:00:00+00:00',
                    value: 473.63416666666666,
                  },
                  {
                    key: '2022-04-27 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-27 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 09:00:00+00:00',
                    value: 444.27611111111116,
                  },
                  {
                    key: '2022-04-28 10:00:00+00:00',
                    value: 443.1333333333334,
                  },
                  {
                    key: '2022-04-28 11:00:00+00:00',
                    value: 451.3452777777778,
                  },
                  {
                    key: '2022-04-28 12:00:00+00:00',
                    value: 442.28805555555556,
                  },
                  {
                    key: '2022-04-28 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-28 16:00:00+00:00',
                    value: 449.9475,
                  },
                  {
                    key: '2022-04-28 17:00:00+00:00',
                    value: 502.9213888888889,
                  },
                  {
                    key: '2022-04-28 18:00:00+00:00',
                    value: 444.2005555555555,
                  },
                  {
                    key: '2022-04-28 19:00:00+00:00',
                    value: 580.7152777777777,
                  },
                  {
                    key: '2022-04-28 20:00:00+00:00',
                    value: 447.4966666666667,
                  },
                  {
                    key: '2022-04-28 21:00:00+00:00',
                    value: 427.9041666666667,
                  },
                  {
                    key: '2022-04-28 22:00:00+00:00',
                    value: 433.9911111111111,
                  },
                  {
                    key: '2022-04-28 23:00:00+00:00',
                    value: 438.6661111111111,
                  },
                  {
                    key: '2022-04-29 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 10:00:00+00:00',
                    value: 443.77555555555557,
                  },
                  {
                    key: '2022-04-29 11:00:00+00:00',
                    value: 482.7338888888889,
                  },
                  {
                    key: '2022-04-29 12:00:00+00:00',
                    value: 477.4072222222223,
                  },
                  {
                    key: '2022-04-29 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-29 16:00:00+00:00',
                    value: 442.3588888888889,
                  },
                  {
                    key: '2022-04-29 17:00:00+00:00',
                    value: 459.35416666666663,
                  },
                  {
                    key: '2022-04-29 18:00:00+00:00',
                    value: 524.2422222222222,
                  },
                  {
                    key: '2022-04-29 19:00:00+00:00',
                    value: 459.9869444444445,
                  },
                  {
                    key: '2022-04-29 20:00:00+00:00',
                    value: 443.0625,
                  },
                  {
                    key: '2022-04-29 21:00:00+00:00',
                    value: 441.3625,
                  },
                  {
                    key: '2022-04-29 22:00:00+00:00',
                    value: 432.7302777777778,
                  },
                  {
                    key: '2022-04-29 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 10:00:00+00:00',
                    value: 455.3827777777778,
                  },
                  {
                    key: '2022-04-30 11:00:00+00:00',
                    value: 444.47916666666663,
                  },
                  {
                    key: '2022-04-30 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 14:00:00+00:00',
                    value: 442.595,
                  },
                  {
                    key: '2022-04-30 15:00:00+00:00',
                    value: 445.46611111111116,
                  },
                  {
                    key: '2022-04-30 16:00:00+00:00',
                    value: 456.45,
                  },
                  {
                    key: '2022-04-30 17:00:00+00:00',
                    value: 467.2166666666667,
                  },
                  {
                    key: '2022-04-30 18:00:00+00:00',
                    value: 488.9530555555555,
                  },
                  {
                    key: '2022-04-30 19:00:00+00:00',
                    value: 469.7808333333333,
                  },
                  {
                    key: '2022-04-30 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-04-30 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 11:00:00+00:00',
                    value: 442.2833333333333,
                  },
                  {
                    key: '2022-05-01 12:00:00+00:00',
                    value: 442.2833333333333,
                  },
                  {
                    key: '2022-05-01 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-01 15:00:00+00:00',
                    value: 451.69472222222225,
                  },
                  {
                    key: '2022-05-01 16:00:00+00:00',
                    value: 457.2386111111111,
                  },
                  {
                    key: '2022-05-01 17:00:00+00:00',
                    value: 516.3325,
                  },
                  {
                    key: '2022-05-01 18:00:00+00:00',
                    value: 502.2036111111111,
                  },
                  {
                    key: '2022-05-01 19:00:00+00:00',
                    value: 539.7122222222222,
                  },
                  {
                    key: '2022-05-01 20:00:00+00:00',
                    value: 519.4161111111111,
                  },
                  {
                    key: '2022-05-01 21:00:00+00:00',
                    value: 439.6719444444445,
                  },
                  {
                    key: '2022-05-01 22:00:00+00:00',
                    value: 432.71611111111116,
                  },
                  {
                    key: '2022-05-01 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 09:00:00+00:00',
                    value: 442.27861111111116,
                  },
                  {
                    key: '2022-05-02 10:00:00+00:00',
                    value: 446.3113888888889,
                  },
                  {
                    key: '2022-05-02 11:00:00+00:00',
                    value: 447.0386111111111,
                  },
                  {
                    key: '2022-05-02 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 13:00:00+00:00',
                    value: 441.9386111111111,
                  },
                  {
                    key: '2022-05-02 14:00:00+00:00',
                    value: 436.9613888888889,
                  },
                  {
                    key: '2022-05-02 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-02 18:00:00+00:00',
                    value: 447.1472222222222,
                  },
                  {
                    key: '2022-05-02 19:00:00+00:00',
                    value: 448.74333333333334,
                  },
                  {
                    key: '2022-05-02 20:00:00+00:00',
                    value: 442.5713888888889,
                  },
                  {
                    key: '2022-05-02 21:00:00+00:00',
                    value: 441.7166666666667,
                  },
                  {
                    key: '2022-05-02 22:00:00+00:00',
                    value: 438.8833333333334,
                  },
                  {
                    key: '2022-05-02 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 10:00:00+00:00',
                    value: 443.4025,
                  },
                  {
                    key: '2022-05-03 11:00:00+00:00',
                    value: 491.01666666666665,
                  },
                  {
                    key: '2022-05-03 12:00:00+00:00',
                    value: 483.3666666666666,
                  },
                  {
                    key: '2022-05-03 13:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 14:00:00+00:00',
                    value: 440.5738888888889,
                  },
                  {
                    key: '2022-05-03 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 16:00:00+00:00',
                    value: 447.72333333333336,
                  },
                  {
                    key: '2022-05-03 17:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-03 18:00:00+00:00',
                    value: 456.3980555555556,
                  },
                  {
                    key: '2022-05-03 19:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-03 22:00:00+00:00',
                    value: 436.83861111111116,
                  },
                  {
                    key: '2022-05-03 23:00:00+00:00',
                    value: 439.78055555555557,
                  },
                  {
                    key: '2022-05-04 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 08:00:00+00:00',
                    value: 442.6186111111112,
                  },
                  {
                    key: '2022-05-04 09:00:00+00:00',
                    value: 442.7886111111111,
                  },
                  {
                    key: '2022-05-04 10:00:00+00:00',
                    value: 450.8352777777777,
                  },
                  {
                    key: '2022-05-04 11:00:00+00:00',
                    value: 443.6527777777777,
                  },
                  {
                    key: '2022-05-04 12:00:00+00:00',
                    value: 450.78805555555556,
                  },
                  {
                    key: '2022-05-04 13:00:00+00:00',
                    value: 442.8594444444445,
                  },
                  {
                    key: '2022-05-04 14:00:00+00:00',
                    value: 437.7311111111111,
                  },
                  {
                    key: '2022-05-04 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 17:00:00+00:00',
                    value: 445.1686111111111,
                  },
                  {
                    key: '2022-05-04 18:00:00+00:00',
                    value: 452.7147222222222,
                  },
                  {
                    key: '2022-05-04 19:00:00+00:00',
                    value: 455.3686111111112,
                  },
                  {
                    key: '2022-05-04 20:00:00+00:00',
                    value: 445.3433333333333,
                  },
                  {
                    key: '2022-05-04 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-04 22:00:00+00:00',
                    value: 438.3166666666667,
                  },
                  {
                    key: '2022-05-04 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 04:00:00+00:00',
                    value: 374.6847222222222,
                  },
                  {
                    key: '2022-05-05 05:00:00+00:00',
                    value: 124.90277777777777,
                  },
                  {
                    key: '2022-05-05 06:00:00+00:00',
                    value: 408.4911111111111,
                  },
                  {
                    key: '2022-05-05 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 09:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 10:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 11:00:00+00:00',
                    value: 442.5666666666667,
                  },
                  {
                    key: '2022-05-05 12:00:00+00:00',
                    value: 450.8258333333334,
                  },
                  {
                    key: '2022-05-05 13:00:00+00:00',
                    value: 445.5888888888889,
                  },
                  {
                    key: '2022-05-05 14:00:00+00:00',
                    value: 433.2638888888889,
                  },
                  {
                    key: '2022-05-05 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 16:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 17:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 18:00:00+00:00',
                    value: 442.6091666666666,
                  },
                  {
                    key: '2022-05-05 19:00:00+00:00',
                    value: 458.09805555555556,
                  },
                  {
                    key: '2022-05-05 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-05 21:00:00+00:00',
                    value: 438.8361111111111,
                  },
                  {
                    key: '2022-05-05 22:00:00+00:00',
                    value: 426.7472222222222,
                  },
                  {
                    key: '2022-05-05 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 08:00:00+00:00',
                    value: 452.4786111111111,
                  },
                  {
                    key: '2022-05-06 09:00:00+00:00',
                    value: 447.72333333333336,
                  },
                  {
                    key: '2022-05-06 10:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-06 11:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-06 12:00:00+00:00',
                    value: 442.52416666666664,
                  },
                  {
                    key: '2022-05-06 13:00:00+00:00',
                    value: 448.7952777777778,
                  },
                  {
                    key: '2022-05-06 14:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 16:00:00+00:00',
                    value: 447.4211111111111,
                  },
                  {
                    key: '2022-05-06 17:00:00+00:00',
                    value: 445.3622222222222,
                  },
                  {
                    key: '2022-05-06 18:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 19:00:00+00:00',
                    value: 444.25722222222225,
                  },
                  {
                    key: '2022-05-06 20:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-06 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-07 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-07 01:00:00+00:00',
                    value: 444.3233333333334,
                  },
                  {
                    key: '2022-05-07 02:00:00+00:00',
                    value: 444.79083333333335,
                  },
                  {
                    key: '2022-05-07 03:00:00+00:00',
                    value: 457.06861111111107,
                  },
                  {
                    key: '2022-05-07 04:00:00+00:00',
                    value: 456.6861111111111,
                  },
                  {
                    key: '2022-05-07 05:00:00+00:00',
                    value: 442.5997222222223,
                  },
                  {
                    key: '2022-05-07 06:00:00+00:00',
                    value: 445.10722222222216,
                  },
                  {
                    key: '2022-05-07 07:00:00+00:00',
                    value: 458.4333333333333,
                  },
                  {
                    key: '2022-05-07 08:00:00+00:00',
                    value: 445.3575,
                  },
                  {
                    key: '2022-05-07 09:00:00+00:00',
                    value: 442.3258333333334,
                  },
                  {
                    key: '2022-05-07 10:00:00+00:00',
                    value: 446.8261111111111,
                  },
                  {
                    key: '2022-05-07 11:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 12:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 13:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 14:00:00+00:00',
                    value: 443.3836111111111,
                  },
                  {
                    key: '2022-05-07 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-07 16:00:00+00:00',
                    value: 449.1211111111112,
                  },
                  {
                    key: '2022-05-07 17:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 19:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 20:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-07 21:00:00+00:00',
                    value: 446.7788888888889,
                  },
                  {
                    key: '2022-05-07 22:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-07 23:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 00:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 07:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 08:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 09:00:00+00:00',
                    value: 443.1663888888889,
                  },
                  {
                    key: '2022-05-08 10:00:00+00:00',
                    value: 451.31694444444446,
                  },
                  {
                    key: '2022-05-08 11:00:00+00:00',
                    value: 444.01166666666666,
                  },
                  {
                    key: '2022-05-08 12:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 13:00:00+00:00',
                    value: 447.98305555555555,
                  },
                  {
                    key: '2022-05-08 14:00:00+00:00',
                    value: 453.03583333333336,
                  },
                  {
                    key: '2022-05-08 15:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 16:00:00+00:00',
                    value: 448.2286111111111,
                  },
                  {
                    key: '2022-05-08 17:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-08 18:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-08 19:00:00+00:00',
                    value: 459.0,
                  },
                  {
                    key: '2022-05-08 20:00:00+00:00',
                    value: 454.1502777777777,
                  },
                  {
                    key: '2022-05-08 21:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-08 22:00:00+00:00',
                    value: 441.6836111111112,
                  },
                  {
                    key: '2022-05-08 23:00:00+00:00',
                    value: 433.24027777777775,
                  },
                  {
                    key: '2022-05-09 00:00:00+00:00',
                    value: 441.7166666666667,
                  },
                  {
                    key: '2022-05-09 01:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 02:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 03:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 04:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 05:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 06:00:00+00:00',
                    value: 442.0,
                  },
                  {
                    key: '2022-05-09 07:00:00+00:00',
                    value: 442.0,
                  },
                ],
                alarms: {
                  criticals: [
                    {
                      name: 'CMOD_Fire_AL',
                      description: '',
                      values: [],
                    },
                  ],
                  warnings: [],
                  totalResolved: 0,
                },
              },
              technologiesData: {
                MARVEL: {
                  active: true,
                  totalSavings: 930267.1716666666,
                  alarms: {
                    criticals: [
                      {
                        name: 'MRV_Hood_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                UV: {
                  active: false,
                  totalConsumptionHrs: 0.0,
                  totalSavingsHrs: 0,
                  projectedDateToChange: '',
                  alarms: {
                    criticals: [
                      {
                        name: 'UV_FiltersRemoved_AL',
                        description: '',
                        values: [],
                      },
                    ],
                    warnings: [],
                    totalResolved: 0,
                  },
                },
                WW: {
                  active: false,
                },
                WMist: {
                  active: false,
                },
              },
            },
          ],
        },
      ],
    },
  ],
};

export default template;
