import { LocalReportStoreType } from '../types/AppTypes'
import {RootStateType} from '../../store';

// TODO: check the naming convention of this type
export interface AirflowTimeSeriesType {
    id: number;
    name: string;
    data: {
      key: string;
      value: number;
    }[];
}

export const selectMarvelAirTSData = (report : RootStateType) : AirflowTimeSeriesType[] => {
    const reporting : LocalReportStoreType = report.reporting;
    const job = reporting.data.jobs[0];
    const systems = job.systems;

    const AirflowTimeSeries : AirflowTimeSeriesType[] = [];

    systems.forEach((item, index) => {
      const systemName = item.systemName;
      const hoods = item.hoods;

      hoods.forEach((itemH, indexH) => {
        const name = systemName + '_' + itemH.hoodsName;
        const localAirTS = itemH.core.airflowActual_CMH;
        // const localAirTSTemperature = itemH.technologies.MARVEL.temperature[0].values

        const resultPerHood = {
          id: AirflowTimeSeries.length,
          name,
          data: localAirTS.map((itemAir, indexAir) => {
            return { ...itemAir, value: itemAir.value === null ? 0 : itemAir.value };
          }),
          // dataT : localAirTSTemperature.map((item, index) => {
          //   return { ...item, value: item.value === null ? 0 : item.value };
          // }),
        };
        AirflowTimeSeries.push(resultPerHood);

      });

    });
    return AirflowTimeSeries;
  };