import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { store } from './store';
import { Provider } from 'react-redux';
// import components
import {BrowserRouter, Routes, Route, Router} from 'react-router-dom';
import {Box, styled} from '@mui/material';
import Footer from './components/Footer'
import Topbar from './scenes/global/Topbar';
import {LeftSidebar} from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import TechMarvelSavings from './scenes/techMarvelSavings';
import TechMarvelShares from './scenes/techMarvelShares';
import TechMarvelTimeSeries from './scenes/techMarvelTimeSeries';
// import WarningsAlarms from './scenes/warningsAlarms';
import EnergyParameters from './scenes/energyParameters';
// import Documentation from './scenes/documentation';

// import redux utils and fetchReportThunk thunk
import {AppDispatch} from './store';
import {fetchReportThunk} from './utils/helpers/thunks/AsyncFetchReport';

// import selectors
import {selectLoadingState} from './utils/selectors/SelectLoadingState';
import {selectEnergyParams} from './utils/selectors/SelectEnergy';
import {calculateEnergy} from './utils/helpers/thunks/AsyncCalculateEnergy';
import {selectCurrentView} from './utils/selectors/SelectCurrentView';
import ComingSoon from './scenes/comingSoon';


const CustomBox = styled(Box)({
    backgroundColor: '#f5f6f8',
}) as typeof Box;

// This part could be replaced by token for API call
const path = 'ExampleReport.json';

const componentsObject : {path : string; element : JSX.Element}[] = [
    {
        path: '/',
        element: <Dashboard/>
    },
    {
        path: '/tech-marvel-savings',
        element: <TechMarvelSavings/>
    },
    {
        path: '/tech-marvel-shares',
        element: <TechMarvelShares/>
    },
    {
        path: '/tech-marvel-timeseries',
        element: <TechMarvelTimeSeries/>
    },
    {
        path: '/energy-parameters',
        element: <EnergyParameters/>
    },
    {
        path: '/coming-soon',
        element: <ComingSoon/>
    },
]


// MAIN COMPONENT
const FoodServiceReport: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const currentView = useSelector(selectCurrentView)
    const loadingStatus = useSelector(selectLoadingState)

    const currentComponent = componentsObject.filter((component)=>{ return component.path === currentView})[0]

    // Get "query" from link. See https://nodejs.org/api/url.html#class-urlsearchparams for more details
    const query = new URLSearchParams(window.location.search);
    // Getting token from query parameters
    const token = query.get('t');

    useEffect(() => {
        if (loadingStatus === 'loaded') {
            dispatch(calculateEnergy())
        }
    }, [loadingStatus, dispatch])

    useEffect(() => {
        dispatch(fetchReportThunk(token));
    }, [dispatch, token]);

    return (
            <React.StrictMode>
                    <Box className='fsReport' sx={{  display: 'flex', position: 'relative'}}>
                        <Topbar/>
                        <LeftSidebar />
                        <div className='fsReportContent'>
                            <CustomBox>
                                {currentComponent.element}
                            </CustomBox>
                            <Footer></Footer>
                        </div>
                    </Box>
            </React.StrictMode>
    );
};

export default FoodServiceReport;
