import { ResponsiveLine } from '@nivo/line';
import React from 'react';

interface LineChartProps {
  data: { id: number; data: { value: number; key: string }[] }[]; //id = 0, value = y = 4390, key = x = 2023-02-03 11:00
}

// LieChart based on Responsive Line from @nivo
// LineChart is used for timeseries garphics
const LineChart: React.FC<LineChartProps> = ({ data }) => {

  const dataToPlot = data.map((item, index) => {
    const id = item.id + 1;
    const localData = item.data.map((itemH, indexH) => ({
      x: itemH.key,
      y: itemH.value,
    }));
    return {
      id,
      data: localData,
    };
  });

  return (
    <ResponsiveLine
      data={dataToPlot}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: '#141414',
            },
          },
          legend: {
            text: {
              fill: '#141414',
            },
          },
          ticks: {
            line: {
              stroke: '#141414',
              strokeWidth: 1,
            },
            text: {
              fill: '#141414',
            },
          },
        },
        legends: {
          text: {
            fill: '#141414',
          },
        },
        tooltip: {
          container: {
            color: '#040509',
          },
        },
      }}
      curve='monotoneX'
      margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%dT%H:%M:%SZ',
        useUTC: false,
        precision: 'hour',
      }}
      xFormat='time:%Y-%m-%d %H:%M'
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=' >-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Days of the Month',
        legendOffset: 36,
        legendPosition: 'middle',
        format: (value) => `[${value.getDate()}/${value.getMonth() + 1}]`,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'AirFlow',
        legendOffset: -50,
        legendPosition: 'middle',
      }}
      enablePoints={false}
      colors={{ scheme: 'nivo' }}
      useMesh={true}
      enableGridX={false}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;
