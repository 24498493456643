import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { HistoryRouter as BrowserRouter } from 'redux-first-history/rr6';
import { QueryClientProvider, QueryClient } from 'react-query';

import { store, history } from 'store';

import App from './App';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
