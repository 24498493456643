// Import Core
import { useSelector } from 'react-redux';

// Import Components
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../../components/Header';
import StatBox from '../../components/StatBox';

// Import Icons
import WindPowerIcon from '@mui/icons-material/WindPower';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Co2Icon from '@mui/icons-material/Co2';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ReactComponent as Wrong } from 'assets/vector/wrong.svg';

// CSS
import './dashboard.css';

// Import Selectors
import { selectLoadingState } from '../../utils/selectors/SelectLoadingState';
import { selectGlobalInfo } from '../../utils/selectors/SelectGlobalInfo';
import { selectEnergyValues, selectEnergyParams } from '../../utils/selectors/SelectEnergy';
import LoadingScreen from './LoadingScreen';



// MAIN COMPONENT
const Dashboard = () => {

  const loadState = useSelector(selectLoadingState);
  const globalInfo = useSelector(selectGlobalInfo);
  const energy = useSelector(selectEnergyValues)
  const energyParams = useSelector(selectEnergyParams)

  const energyConsumption: { heating: number, cooling: number, fan: number } = energy.actual;
  const energyMax: { heating: number, cooling: number, fan: number } = energy.max
  const electricityCost: number = energyParams.costOfElectricity
  const coolingCost: number = energyParams.costOfElectricity
  const heatingCost: number = (energyParams.costOfElectricity * energyParams.electricHeating + energyParams.costOfGas * energyParams.gasHeating) / 100;
  const emissionRate: number = energyParams.erC



  const rows = [
    {
      name: (
        <>
          <WindPowerIcon
            sx={{
              marginRight: '1.25rem',
              color: 'green',
              fontSize: '1.125rem',
            }}
          />{' '}
          Fan
        </>
      ),
      consumptionKWh: energyConsumption.fan,
      consumptionEuro: electricityCost * energyConsumption.fan,
      savingskWh: energyMax.fan - energyConsumption.fan,
      savingsEuro: electricityCost * (energyMax.fan - energyConsumption.fan),
    },
    {
      name: (
        <>
          <LocalFireDepartmentIcon
            sx={{
              marginRight: '1.25rem',
              color: 'green',
              fontSize: '1.125rem',
            }}
          />{' '}
          Heating
        </>
      ),
      consumptionKWh: energyConsumption.heating,
      consumptionEuro: heatingCost * energyConsumption.heating,
      savingskWh: energyMax.heating - energyConsumption.heating,
      savingsEuro: heatingCost * (energyMax.heating - energyConsumption.heating),
    },
    {
      name: (
        <>
          <AcUnitIcon
            sx={{
              marginRight: '1.25rem',
              color: 'green',
              fontSize: '1.125rem',
            }}
          />{' '}
          Cooling
        </>
      ),
      consumptionKWh: energyConsumption.cooling,
      consumptionEuro: coolingCost * energyConsumption.cooling,
      savingskWh: energyMax.cooling - energyConsumption.cooling,
      savingsEuro:
        coolingCost * (energyMax.cooling - energyConsumption.cooling),
    },
  ];

  const rowsMobileFan = [
    {
      name: 'Consumption KWh',
      result: energyConsumption.fan,
    },
    {
      name: 'Cost',
      result: electricityCost * energyConsumption.fan,
    },
    {
      name: 'Savings kWh',
      result: energyMax.fan - energyConsumption.fan,
    },
    {
      name: 'Cost Savings',
      result: electricityCost * (energyMax.fan - energyConsumption.fan),
    },
  ];

  const rowsMobileHeat = [
    {
      name: 'Consumption KWh',
      result: energyConsumption.heating,
    },
    {
      name: 'Cost',
      result: heatingCost * energyConsumption.heating,
    },
    {
      name: 'Savings kWh',
      result: energyMax.heating - energyConsumption.heating,
    },
    {
      name: 'Cost Savings',
      result: heatingCost * (energyMax.heating - energyConsumption.heating),
    },
  ];
  const rowsMobileCooling = [
    {
      name: 'Consumption KWh',
      result: energyConsumption.cooling,
    },
    {
      name: 'Cost',
      result: coolingCost * energyConsumption.cooling,
    },
    {
      name: 'Savings kWh',
      result: energyMax.cooling - energyConsumption.cooling,
    },
    {
      name: 'Cost Savings',
      result:
        coolingCost * (energyMax.cooling - energyConsumption.cooling),
    },
  ];

  const totalSavings =
    heatingCost * (energyMax.heating - energyConsumption.heating) +
    electricityCost * (energyMax.fan - energyConsumption.fan) +
    coolingCost * (energyMax.cooling - energyConsumption.cooling);

  const co2Reduction: number = totalSavings * emissionRate;

  return (
    <Box>
      <Box className='mainBox'>
        <Box>
          <LoadingScreen loadingState={loadState} />
        </Box>
        <Box className='boxHeader'>
          <Header
            title={`DASHBOARD - ${globalInfo.jobName}`}
            subtitle={`Welcome to your dashboard for ${new Date(
              globalInfo.dateFrom
            ).toLocaleString('default', { month: 'long' })} ${new Date(
              globalInfo.dateFrom
            ).getFullYear()}`}
          />
        </Box>
        {totalSavings == 0 ? (
          <div style={{
            height: '70vh',
            display: 'flex',
            width: '750px',
            paddingTop: '4rem',
            marginLeft: '2rem',
            paddingLeft: '1.125rem',
            paddingRight: '1.125rem',
            justifyContent: 'center'
          }}>
            <Typography fontFamily={'Roboto'}>
              <div className="Page">
                <div className="Page__icon">
                  <Wrong />
                </div>
                <p style={{ fontSize: '1.3rem', marginTop: '1.5rem' }}>Energy calculation at this moment is unavailable</p>
                {/* <p>Please contact <i><a href="mailto:contact@halton.com" style={{ color: '#2499d5' }}><u>contact@halton.com</u></a></i></p> */}
              </div>
            </Typography>
          </div>
        ) :
          (
            <>
              <Box className='boxOverviewDetails'>
                <Typography className='typoOverviewDetails'>OVERVIEW</Typography>
              </Box>
              <Box id='boxGlobalSavingsCo2'>
                <Box className='boxSavingsCo2'>
                  <StatBox
                    title={`${totalSavings.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'EUR',
                    })}`}
                    subtitle='TOTAL SAVINGS'
                    progress={0}
                    increase=''
                    icon={<AccountBalanceIcon className='iconSavingsCo2' />}
                  />
                </Box>
                <Box className='boxCo2'>
                  <StatBox
                    title={`${co2Reduction.toFixed(0)}`}
                    subtitle={'CO2 Reduction [kg/eq.CO2]'}
                    progress={0}
                    increase=''
                    icon={<Co2Icon className='iconCo2' />}
                  />
                </Box>
              </Box>
              <Box className='boxOverviewDetails'>
                <Typography className='typoOverviewDetails'>DETAILS</Typography>
              </Box>
              <Box className='boxTableContainer'>
                <TableContainer component={Paper}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#2499D5' }}>
                        <TableCell className='tableCellTitle'>Item</TableCell>
                        <TableCell className='tableCellTitle'>
                          Consumption kWh
                        </TableCell>
                        <TableCell className='tableCellTitle'>Cost</TableCell>
                        <TableCell className='tableCellTitle'>
                          Savings kWh
                        </TableCell>
                        <TableCell className='tableCellTitle'>
                          Cost Savings
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow >
                          <TableCell className='tableCellItems'>
                            <Box display={'flex'} justifyContent={'start'}>
                              {row.name}
                            </Box>
                          </TableCell>
                          <TableCell className='tableCellItems'>
                            {row.consumptionKWh.toFixed(1)}
                          </TableCell>
                          <TableCell className='tableCellItems'>
                            {row.consumptionEuro.toFixed(1)}
                          </TableCell>
                          <TableCell className='tableCellItems'>
                            {row.savingskWh.toFixed(1)}
                          </TableCell>
                          <TableCell className='tableCellItems'>
                            {row.savingsEuro.toFixed(1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className='boxTableContainerMobile'>
                <TableContainer component={Paper}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className='tableCellTitleMobile' sx={{ backgroundColor: '#2499D5' }}>Fan</TableCell>
                        <TableCell sx={{ backgroundColor: '#2499D5' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsMobileFan.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell className='tableCellItemsMobile'>
                            {row.name}{' '}
                          </TableCell>
                          <TableCell className='tableCellItemsMobile'>
                            {row.result.toFixed(1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className='boxTableContainerMobile'>
                <TableContainer component={Paper}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className='tableCellTitleMobile' sx={{ backgroundColor: '#2499D5' }}>Heat</TableCell>
                        <TableCell sx={{ backgroundColor: '#2499D5' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsMobileHeat.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell className='tableCellItemsMobile'>
                            {row.name}{' '}
                          </TableCell>
                          <TableCell className='tableCellItemsMobile'>
                            {row.result.toFixed(1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className='boxTableContainerMobile'>
                <TableContainer component={Paper}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className='tableCellTitleMobile' sx={{ backgroundColor: '#2499D5' }}>Cooling</TableCell>
                        <TableCell sx={{ backgroundColor: '#2499D5' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsMobileCooling.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell className='tableCellItemsMobile'>
                            {row.name}{' '}
                          </TableCell>
                          <TableCell className='tableCellItemsMobile'>
                            {row.result.toFixed(1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
      </Box>
    </Box>
  );
};

export default Dashboard;
