import React from 'react';
import { Typography, Box } from '@mui/material';

interface HeaderProps {
  title: string; // dashboard - fr
  subtitle: string; // welcome to your dashboard
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => {

  return (
    <Box>
      <Typography variant='h2' color='black' fontWeight='bold' sx={{ mb: '5px' }}>
        {title}
      </Typography>
      <Typography variant='h4' color='#424242'>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
