import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TemplateBase } from 'types/api';
import { useQueryParams } from 'hooks/useQueryParams';
import dummyData from 'mocks/template';
import { ENDPOINT_BASE } from 'constants/index';


export const useTemplateData = (): {
  data: TemplateBase | undefined;
  isLoading: boolean;
} => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const token = query.get('t');

  const baseUrl = `${ENDPOINT_BASE}template?token=`;

  const { data, isLoading } = useQuery<TemplateBase>([token], async () => {
    try {
      if (token) {
        const response = await fetch(`${baseUrl}${token}`);

        if (response.status === 401) {
          navigate('/noaccess');
        }

        if (response.status === 404) {
          navigate('/error');
        }

        const json = await response.json();
        return json as TemplateBase;
      }

      return undefined;
    } catch (error) {
      return dummyData as any;
    }
  });

  if (!token) {
    return {
      data: undefined,
      isLoading: false,
    };
  }

  return { data, isLoading };
};
