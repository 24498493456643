import { createAsyncThunk } from '@reduxjs/toolkit';
import { EnergyDataType, EnergyParamsType } from '../../types/AppTypes';
import { RootStateType } from '../../../store';
import { KeyValueItems } from '../../types/AppTypes';
import { calculateEnergyConsumption } from '../EnergyCalculation';

// Define Interfaces and Types
interface WeatherTempHRType {
    temperature: number;
    humidity: number;
}

// Thunk for Energy Calculation for store State
export const calculateEnergy = createAsyncThunk<EnergyDataType>(
    'energy/calculate',
    async (_: void, thunkAPI) => {
        const state = thunkAPI.getState() as RootStateType;

        const weather: Array<WeatherTempHRType> = state.reporting.data.jobs[0].weather?.historicalData?.data.map((item, index) => {
            return {
                temperature: item.main.temp,
                humidity: item.main.humidity
            }
        })

        const energyParams: EnergyParamsType = state.energy.params;

        //  list all the airflow arrays in one array - this array is to be used to aggregate on Date&Time basis
        const totalAirflowEachHood: KeyValueItems[] = [];
        state.reporting.data.jobs[0].systems.forEach((system, indexS) => {
            system.hoods.forEach((hood, indexH) => {
                totalAirflowEachHood.push(hood.core.airflowActual_CMH)
            })
        })

        const totalMaxAirflowEachHood: KeyValueItems[] = [];
        state.reporting.data.jobs[0].systems.forEach((system, indexS) => {
            system.hoods.forEach((hood, indexH) => {
                totalMaxAirflowEachHood.push(hood.core.airflowDesign_CMH)
            })
        })

        const totalAirflow: Array<number> = [];
        const totalAirflowLength = totalAirflowEachHood[0].length

        for (let j = 0; j < totalAirflowLength; j++) {
            let value = 0

            for (let i = 0; i < totalAirflowEachHood.length; i++) {
                value = value + totalAirflowEachHood[i][j].value
            }
            totalAirflow.push(value)
        }

        const totalMaxAirflow: Array<number> = [];
        const totalMaxAirflowLength = totalMaxAirflowEachHood[0].length

        for (let j = 0; j < totalMaxAirflowLength; j++) {
            let value = 0

            for (let i = 0; i < totalMaxAirflowEachHood.length; i++) {
                value = value + totalMaxAirflowEachHood[i][j].value
            }
            totalMaxAirflow.push(value)
        }

        const EnergyParams = state.energy.params

        const energyConsumption = await calculateEnergyConsumption(weather, totalAirflow, EnergyParams);
        const energyMax = await calculateEnergyConsumption(weather, totalMaxAirflow, EnergyParams);

        return {
            actual: energyConsumption,
            max: energyMax
        }
    }
)