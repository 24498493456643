import { FC, useState } from 'react';
import { useTemplateData } from './useTemplateData';
import { SensorDataObject } from '../../types/types';
import AirQuality from './AirQuality';
import { Grid } from '@mui/material';
import SensorCard from './SensorCard';
import * as R from 'ramda';
import { IoTSystem, StructuralElement } from '../../types/api';
import Loading from 'components/Loading';

// TEMPORARY CONSTANTS TO INDICATE MEASUREMENTS
export const THERMAL_COMFORT = 'Tindex_ST';
export const AIR_QUALITY = 'AQIindex_ST';
export const VIRAL_RISK = 'VIndex_CL';

function isNotNull(value: any) {
  return R.isNil(value) ? [] : value;
}

const AirQualityIndex: FC = () => {
  const { data, isLoading } = useTemplateData();
  const [selectedSensors, setSelectedSensors] = useState<SensorDataObject[][]>(
    []
  );

  if (isLoading) {
    return <Loading isCentered={true} />;
  }

  if (!data) {
    return <div />;
  }

  const sensors: SensorDataObject[] = R.flatten(
    isNotNull(data.systems).map((s: IoTSystem) =>
      isNotNull(s.structure).map((st: StructuralElement) =>
        isNotNull(st.subElements).map((sub: StructuralElement) =>
          sub.subElements
            ?.filter((subsub) => subsub.data !== undefined)
            .map((subsub) => subsub.data as SensorDataObject[])
        )
      )
    )
  );

  // Split into arrays of 3.
  // Example: [[Thermal, AirQuality, ViralInfection], [Thermal, AirQuality, ViralInfection], [Thermal, AirQuality, ViralInfection]]
  const sensorChuncks =
    sensors.length > 0
      ? R.splitEvery(3, R.flatten(sensors)).filter((x) => x.length !== 0)
      : [];

  if (sensorChuncks.length === 1 || selectedSensors?.length === 1) {
    // Single sensor
    const sensor =
      selectedSensors?.length === 1 ? selectedSensors[0] : sensorChuncks[0];
    return (
      <AirQuality
        key={sensor[0].variableName}
        data={sensor}
        goBack={() => setSelectedSensors([])}
        isBackButtonVisible={sensorChuncks.length > 1}
      />
    );
  } else if (sensorChuncks.length > 1) {
    // Multiple sensors
    return (
      <Grid container spacing={2} className="Sensors">
        {sensorChuncks.map((sensor) => (
          <Grid key={sensor[0]?.variableName} item xs={12} sm={6} lg={4}>
            <SensorCard
              sensorData={sensor}
              openSensorDetails={() => setSelectedSensors([sensor])}
            />
          </Grid>
        ))}
      </Grid>
    );
  } else {
    return <div />;
  }
};

export default AirQualityIndex;
