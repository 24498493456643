import { configureStore } from '@reduxjs/toolkit';
import reportingReducer from './slices/AppSlice';
import energyReducer from './slices/EnergySlice'
import navigationReducer from './slices/NavigationSlice'
// import {LocalReportStoreType} from './utils/types/ReportStoreType';

export const store = configureStore({
    reducer: {
        reporting: reportingReducer,
        energy : energyReducer,
        navigation : navigationReducer,
    },
});

// export interface StateType {
//     reporting : LocalReportStoreType;
// }

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;