import React from 'react';
import { Box } from '@mui/material';
import './global.css'



const Topbar = () => {
  return (
    <></>
    // <Box display='flex' sx={{backgroundColor: '#2499D5', position: 'fixed', top: 0, left: 0, right: 0, height: '5px', paddingBottom: '3px', marginBottom: '60px', zIndex: 1500}} justifyContent={'space-between'} p={2} >
      // {/* <Box display={'flex'} sx={{backgroundColor: 'white'}} borderRadius='3px'>
      // </Box> */}

    // </Box>
  );
};

export default Topbar;
