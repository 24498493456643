import {LocalReportStoreType, SystemData} from '../types/AppTypes';
import { RootStateType} from '../../store'
import {createSelector} from '@reduxjs/toolkit';

export interface MarvelSavingsDataType {
    id: number;
    name: string;
    consumption: number;
    savings: number;
}

const SystemsSelector = (state : RootStateType) : SystemData[] => {
    return state.reporting.data.jobs[0].systems
}

export const selectMarvelSavingsData = createSelector([SystemsSelector], (systems: SystemData[]): MarvelSavingsDataType[] => {
    const aggTechMARVELSavingsData: MarvelSavingsDataType[] = [];

    systems.forEach((item, index) => {
        const systemName = item.systemName;
        const hoods = item.hoods;

        hoods.forEach((itemH, indexH) => {
            const name = systemName + '_' + itemH.hoodsName;
            const consumption = itemH.core.consumptionAgg;
            const savings = itemH.technologies.MARVEL.savingsAgg_CM;
            const resultPerHood = {
                id: aggTechMARVELSavingsData.length,
                name,
                consumption,
                savings,
            };

            aggTechMARVELSavingsData.push(resultPerHood);

        });

    });
    return aggTechMARVELSavingsData;
})

// export const selectMarvelSavingsData = (report : StateType) : MarvelSavingsDataType[] => {
//     const localReport : LocalReportStoreType = report.reporting;
//     const job = localReport.data.jobs[0];
//     const systems = job.systems;
//     let aggTechMARVELSavingsData : MarvelSavingsDataType[] = [];
//
//
//     systems.forEach((item, index) => {
//       const systemName = item.systemName;
//       const hoods = item.hoods;
//
//       hoods.forEach((itemH, indexH) => {
//         const name = systemName + '_' + itemH.hoodsName;
//         const consumption = itemH.core.totalConsumption;
//         const savings = itemH.technologies.MARVEL.totalSavings;
//         const resultPerHood = {
//           id: aggTechMARVELSavingsData.length,
//           name,
//           consumption,
//           savings,
//         };
//
//         aggTechMARVELSavingsData.push(resultPerHood);
//
//       });
//
//     });
//     return aggTechMARVELSavingsData;
//   };