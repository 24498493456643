import react from 'react'
import Dashboard from '../scenes/dashboard';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {energySlice} from './EnergySlice';

const AppComponent = {
    dashboard : <Dashboard/>
}

interface NavigationStateType {
    actual: string

}

export const initialState: NavigationStateType = {
    actual: '/'
}

export const navigationSlice = createSlice(
    {
        name: 'navigation',
        initialState,
        reducers : {
            changed : (state, action) => {
                state.actual = action.payload
            }
        }
    }
)

export const {changed} = navigationSlice.actions
export default navigationSlice.reducer;