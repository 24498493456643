import {LocalReportStoreType, ReportData, SystemData} from '../types/AppTypes'
import {RootStateType} from '../../store';
import {createSelector} from '@reduxjs/toolkit';

export interface GlobalInfoType {
    description : string;
    dateFrom: string;
    dateTo: string;
    jobName : string;
}

const ReportSelector = (state : RootStateType) : LocalReportStoreType => {
    return state.reporting
}

export const selectGlobalInfo = createSelector([ReportSelector], (report: LocalReportStoreType) => {
    const result = {
        description : report.templateDescription,
        dateFrom: report.data.dateFrom,
        dateTo: report.data.dateTo,
        jobName : report.data.jobs[0].jobName
    };
    return result;
})

// export const selectGlobalInfo = (report : {reporting : LocalReportStoreType}) : GlobalInfoType  => {
//     const result = {
//       description : report.reporting.templateDescription,
//       dateFrom: report.reporting.data.dateFrom,
//       dateTo: report.reporting.data.dateTo,
//       jobName : report.reporting.data.jobs[0].jobName
//     };
//     return result;
//   };
