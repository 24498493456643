import React from 'react';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {Box, Typography} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

interface CustomTimePickerProps {
    start: Date | null;
    end: Date | null;
    handleStartChange: (newValue: Date) => void;
    handleEndChange: (newValue: Date) => void;
}

// The CustomTimePicker is used to trim the time range for TimeSeries visualisation of the airflow
const CustomTimePicker = (props: CustomTimePickerProps) => {
    const [start, setStart] = React.useState<Date | null>(new Date(props.start!));
    const [end, setEnd] = React.useState<Date | null>(new Date(props.end!));

    const handleStartChange = (newValue: Date | null) => {
        if(newValue == null) {
            // console.log('date is null')
        } else {
            setStart(newValue);
            props.handleStartChange(newValue);
        }
    };

    const handleEndChange = (newValue: Date | null) => {
        if(newValue == null) {
            // console.log('date is null')
        } else {
            setEnd(newValue);
            props.handleEndChange(newValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mt={'20px'} width={'550px'} display={'flex'} justifyContent='space-between'>
                <Typography>FROM</Typography>
                <DateTimePicker
                    label='Date&Time'
                    value={start ?? null}
                    onChange={(date: Date | null) => handleStartChange(date)}
                    // onChange={(date: Date | null) => {
                    //     date == null ? console.log('date is null') : handleStartChange(date)
                    // }}
                    // renderInput={(params) => <TextField {...params} />}
                />
                <Typography>TO</Typography>
                <DateTimePicker
                    label='Date&Time'
                    value={end ?? null}
                    onChange={(date: Date | null) => handleEndChange(date)}
                    // onChange={(value: Date | null) => {
                    //     value == null ? console.log('date is null') : handleEndChange(value)
                    // }}
                    // renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        </LocalizationProvider>
    );
};
export default CustomTimePicker;
