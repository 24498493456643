import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TemplateGetTemplateSucceeded,
  TemplateGetTemplateFailed,
  TemplateGetTemplateRequested,
} from 'store/actions/template/types';

import { TemplateStateType } from './types';

const templateReducerInitialState: TemplateStateType = {
  isPending: false,
  data: undefined,
  error: undefined,
};

const templateSlice = createSlice({
  name: 'template',
  initialState: templateReducerInitialState,
  reducers: {
    getTemplateRequested: (
      state: TemplateStateType,
      action: PayloadAction<TemplateGetTemplateRequested>
    ) => {
      state.isPending = true;
      state.data = undefined;
    },
    getTemplateSucceeded: (
      state: TemplateStateType,
      action: PayloadAction<TemplateGetTemplateSucceeded>
    ) => {
      state.isPending = false;
      state.data = action.payload.data;
    },
    getTemplateFailed: (
      state: TemplateStateType,
      action: PayloadAction<TemplateGetTemplateFailed>
    ) => {
      state.isPending = false;
      state.error = action.payload.error;
    },
  },
});

export const { getTemplateRequested, getTemplateSucceeded, getTemplateFailed } =
  templateSlice.actions;

const templateReducer = templateSlice.reducer;

export { templateReducer };

export * from './types';
