import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Import types
import { EnergyParamsType, EnergyType } from '../utils/types/AppTypes';
import { calculateEnergy } from '../utils/helpers/thunks/AsyncCalculateEnergy';

export const initialState: EnergyType = {
    params: {
        elevation: 100,
        fanEfficiency: 0.7,
        heaterEfficiency: 0.9,
        copAHU: 3.2,
        dPDesign: 500,
        airflowReduction: 0,
        roomHumidity: 50,
        roomTemperature: 21,
        supplyTemperature: 19,
        electricHeating: 80,
        gasHeating: 20,
        costOfElectricity: 0.17,
        costOfGas: 0.003,
        erC: 0.238,
        erHThermal: -1,
        operationalHours: -1,
    },
    values:
    {
        actual: {
            heating: 250,
            cooling: 250,
            fan: 250,

        },
        max: {
            heating: 250,
            cooling: 250,
            fan: 250,
        }

    }
}
export const energySlice = createSlice(
    {
        name: 'energy',
        initialState,
        reducers: {
            paramsUpdated: (state, action: PayloadAction<EnergyParamsType>) => {
                state.params = action.payload;
            }
        },
        extraReducers: builder => {
            builder.addCase(calculateEnergy.pending, (state) => {
            });
            builder.addCase(calculateEnergy.fulfilled, (state, action) => {
                state.values = action.payload;
            })
        }

    }
)

export const { paramsUpdated: paramsUpdated } = energySlice.actions
export default energySlice.reducer;
