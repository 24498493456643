import jwt_decode from 'jwt-decode';
import { useQueryParams } from './useQueryParams';

export interface TemplateToken {
  system_ids: string[];
  template_id: string;
  jti: string;
  exp: number;
  iss: string;
  aud: string;
}

export interface UseTemplate {
  parsedToken: TemplateToken | undefined;
  rawToken: string;
}

export const useTemplateToken: () => UseTemplate = () => {
  const query = useQueryParams();

  const result: UseTemplate = {
    parsedToken: undefined,
    rawToken: '',
  };

  const rawToken = query.get('token');

  if (!rawToken) {
    return result;
  }

  try {
    const parsedToken = jwt_decode(rawToken) as TemplateToken;
    result.parsedToken = parsedToken;
    result.rawToken = rawToken;
  } catch (error) {}

  return result;
};
