import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import BarChart from '../../components/BarChart';
import { BarDatum } from '@nivo/bar';
import { ReactComponent as Wrong } from 'assets/vector/wrong.svg';

// import selectors
import { MarvelSavingsDataType } from '../../utils/selectors/SelectMarvelSavingsData';

import './index.css';


// Defintion of the columns of the table
interface Column {
    field: string;
    headerName: string;
    flex: number;
}

const columns: Column[] = [
    {
        field: 'id',
        headerName: 'ID',
        flex: 1,
    },
    {
        field: 'name',
        headerName: 'HOOD',
        flex: 2,
    },
    {
        field: 'consumption',
        headerName: 'Consumption [m3]',
        flex: 4,
    },
    {
        field: 'savings',
        headerName: 'Savings [m3]',
        flex: 4,
    },
];
// ---------------------------------------------------------------

// Definition of the bar chart------------------------------------
interface INivoCustomBarChartProps {
    data: MarvelSavingsDataType[];
}

const NivoCustomBarChart = (props: INivoCustomBarChartProps) => {
    const { data } = props;
    const result: BarDatum[] | any = data.map((item) => {
        if (item != undefined) {
            return {
                id: item?.name,
                savings: item?.savings,
                consumption: item?.consumption
            };
        } else {
            return {}
        }
    });
    return (<BarChart data={result} />);
};
// ---------------------------------------------------------------

// Handle the selection of an item in the table-------------------
const handleSelection = <T,>(selectedItem: number[], data: T[], setData: (data: T[]) => void): void => {
    const selectedData = selectedItem.map((item) => data[item]);
    setData(selectedData);
};
// ---------------------------------------------------------------


// Help screen show ----------------------------------------------
interface IMainScreenProps {
    shouldDisplayTable: boolean;
    data: any;
    marvelSavingsData: any;
    setData: any;

}
export const MainScreen = (props: IMainScreenProps) => {
    const handleSelectItem = (selectedItem: number[]) => {
        handleSelection<MarvelSavingsDataType>(selectedItem, props.marvelSavingsData, props.setData);
    };
    if (props.shouldDisplayTable) {
        return (
            <Box m='20px' marginTop='60px' display='flex' justifyContent='space-between'>
                {props.data.length === 0 ? (
                    <Box
                        m='60px'
                        width='50vw'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                    >
                        <Box marginBottom='50px'>
                            <div className='loader'></div>
                        </Box>
                        <Box m='20px'>PLEASE SELECT AN ITEM</Box>
                    </Box>
                ) : (
                    <Box m='40px 0 0 0' height='75vh' width='50vw'>
                        <NivoCustomBarChart data={props.data} />
                    </Box>
                )}
                <Box height='75vh' width='25vw'>
                    <DataGrid
                        keepNonExistentRowsSelected
                        rows={props.marvelSavingsData.map((item: MarvelSavingsDataType, index: number) => {
                            return {
                                ...item,
                                consumption: item.consumption.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }),
                                savings: item.savings.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }),
                            };
                        })}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        checkboxSelection
                        onRowSelectionModelChange={(item: any) => handleSelectItem(item)}
                    />
                </Box>
            </Box>)
    } else {
        return <div style={{
            height: '80vh',
            display: 'flex',
            paddingTop: '10rem',
            alignItems: 'center',
            padding: '1.125rem',
            justifyContent: 'center'
        }}>
            <Typography fontFamily={'Roboto'}>
                <div className="Page">
                    <div className="Page__icon">
                        <Wrong />
                    </div>
                    <p style={{ fontSize: '1.3rem', marginTop: '1.5rem' }}>Savings at this moment are unavailable</p>
                    {/* <p>Please contact <i><a href="mailto:contact@halton.com" style={{ color: '#2499d5' }}><u>contact@halton.com</u></a></i></p> */}
                </div>
            </Typography>
        </div>
    }
}
// ---------------------------------------------------------------

export default MainScreen;
