import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import {  Box } from '@mui/material';

interface PieDataItem {
  name: string; // M01_H01
  share: number; // 50%
}

interface PieChartProps {
  data: PieDataItem[]; //name et share
}


// Pie based on Responsive Pie from @nivo
// Pie is used for shares garphics
const PieChart: React.FC<PieChartProps> = ({ data }) => {
  const dataToPlot = data.map((item) => ({
    id: item.name + '_id',
    label: item.name + '_label',
    value: item.share,
  }));

  return (
    <Box height={'100%'}>
      <ResponsivePie
        data={dataToPlot}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: '#141414',
                fontSize: 14,
              },
            },
            legend: {
              text: {
                fill: '#141414',
                fontSize: 14,
              },
            },
            ticks: {
              line: {
                stroke: '#141414',
                strokeWidth: 1,
              },
              text: {
                fill: '#141414',
                fontSize: 14,
              },
            },
          },
          labels : {
            text: {
              fontSize: 14,
            }
          },
          legends: {
            text: {
              fill: '#141414',
              fontSize: 14,
            },
          },
        }}
        margin={{ top: 40, right: 80, bottom: 120, left: 80 }}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLabels={false}
        arcLinkLabel={(d) => `${d.id}: ${d.value.toFixed(2) + ' %'}`}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={'#141414'}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        animate={false}
      />
    </Box>
  );
};

export default PieChart;
