import { FC } from 'react';
import { ReactComponent as ThumbsUp } from 'assets/vector/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from 'assets/vector/thumbs-down.svg';
import { DataValue } from './dataValueGetters';

export const Bar: FC<{
  data: DataValue | undefined;
}> = ({ data }) => {
  if (!data) {
    return <div />;
  }
  const { graphValue, description, type } = data;
  return (
    <div className="Bar__wrapper" data-type={type}>
      <div className="Bar__title">
        <div className="Bar__badge">{description}</div>
      </div>
      <div className="Bar__graph">
        <div className="Bar__icon-start">
          <ThumbsDown />
        </div>

        <div className="Bar__background">
          <div className="Bar__value" style={{ width: `${graphValue}%` }}></div>
        </div>

        <div className="Bar__icon-end">
          <ThumbsUp />
        </div>
      </div>
    </div>
  );
};
