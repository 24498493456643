import React, {  ChangeEvent } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
} from '@mui/material';

interface InputCardProps {
  name: string;
  description: string;
  value: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}


// The input Card is used for Energy parameters
const InputCard: React.FC<InputCardProps> = ({ name, description, value, handleChange }) => {


  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '2px',
        display: 'flex',
        justifyContent: 'space-around',
        justifyItems: 'center',
        p: '5px',
        m: '5px',
        width: '200px',
      }}
    >
      <FormControl sx={{ margin: '5px', width: '100%', height: '60px' }}>
        <InputLabel>{name}</InputLabel>
        <Input type={'number'} value={value} onChange={handleChange} />
        <FormHelperText>{description}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default InputCard;
