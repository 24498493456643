import { FC } from 'react';

import { ReactComponent as Logo } from 'assets/vector/halton-logo.svg';
import { ReactComponent as Wrong } from 'assets/vector/wrong.svg';

import './index.scss';

const Error: FC = () => {
  return (
    <div className="Page">
      <div className="Page__icon">
        <Wrong />
      </div>
      <h1>Something went wrong</h1>
      <p>An error has occured. Please try again later.</p>
      <div className="Page__logo">
        <Logo />
      </div>
    </div>
  );
};

export default Error;
