import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { LocalReportStoreType} from '../utils/types/AppTypes';
import { fetchReportThunk } from '../utils/helpers/thunks/AsyncFetchReport';
import {RootStateType} from '../store';

export const initialState: LocalReportStoreType = {
  loaded: false,
  status: 'idle',
  templateDescription: 'FS Monthly report',
  data: {
    dateFrom: '2000-01-01T00:00:00.000Z',
    dateTo: '2000-01-30T00:00:00.000Z',
    jobs: [
      {
        jobName: 'Loading',
        location: { latitude: 0.0, longitude: 0.0 },
        weather: {
          historicalData: {
            data: [
              {
                timestamp: '2000-00-00T00:00:00',
                main: {
                  feelsLike: 0,
                  temp: 0,
                  pressure: 0,
                  humidity: 0,
                  tempMin: 0,
                  tempMax: 0,
                },
              },
            ],
          },
        },
        // connection: {
        //   values: [{ key: '2000-00-00T00:00:00', value: 0 }],
        // },
        core: {
          consumptionAgg: 0.0,
        },
        technologies: {
          MARVEL: {
            active: true,
            savingsAgg: 0.0,
          },
        },
        systems: [
          {
            systemName: 'M00',
            core: {
              consumptionAgg: 0.0,
            },
            technologies: {
              MARVEL: {
                active: true,
                savingsAgg: 0.0,
              },
            },
            hoods: [
              {
                hoodsName: 'H00',
                core: {
                  consumptionAgg: 0.0,
                  airflowActual_CMH: [{ key: '2000-00-00T00:00:00', value: 0.0 }],
                  airflowDesign_CMH: [{ key: '2000-00-00T00:00:00', value: 0.0 }],
                },
                technologies: {
                  MARVEL: {
                    savingsAgg_CM: 0.0,
                    savingsAgg_Perc: 0.0,
                    active: true,
                    airflowPercAvgHr_Perc : [{ key: '2000-00-00T00:00:00', value: 0.0 }],
                    temperature: [
                      {
                        variable: 'VAR3',
                        values: [{ key: '2000-00-00T00:00:00', value: 0.0 }],
                      },
                    ],
                    alarms: [
                      {
                        variable: 'VAR4',
                        values: [{ key: '2000-00-00T00:00:00', value: 0.0 }],
                      }
                    ]
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};



export const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    loaded: (state, action: PayloadAction<LocalReportStoreType>) => {
      state.data = action.payload.data;
      state.loaded = true;
      state.status = 'loaded';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportThunk.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchReportThunk.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
      state.loaded = true;
    });
  },
});

export const { loaded } = reportingSlice.actions;
export default reportingSlice.reducer;




// ----------------------------------------------
// ----------------------------------------------
// ----------------------------------------------

// export const getReportingThunk = async (dispatch, getState) => {
//   console.log('getReportingThunk');
//   const report = await getReport();
//   dispatch({ type: 'reporting/loaded', payload: report });
// };

// interface MetaDataState {
//   metaData: {
//     tempUnit: string;
//     airflowUnit: string;
//     pressureUnit: string;
//     massUnit: string;
//     currency: string;
//     elevation: number;
//     fanEfficiency: number;
//     copAHU: number;
//     heaterEfficiency: number;
//     dPDesign: number;
//     airflowReduction: number;
//     roomHumidity: number;
//     roomTemperature: number;
//     supplyTemperature: number;
//     electricHeating: number;
//     emissionRate: number;
//     gasHeating: number;
//     costOfElectricity: number;
//     costOfGas: number;
//     erC: number;
//     erHThermal: number;
//     operationalHours: number;
//   };
// }

// const metaDataInitialState: MetaDataState = {
//   metaData: {
//     tempUnit: '°C',
//     airflowUnit: 'M3/h',
//     pressureUnit: 'Pa',
//     massUnit: 'kg',
//     currency: 'Euro',
//     elevation: 100,
//     fanEfficiency: 0.6,
//     copAHU: 3,
//     heaterEfficiency: 0.9,
//     dPDesign: 900,
//     airflowReduction: 0.3,
//     roomHumidity: 50,
//     roomTemperature: 25,
//     supplyTemperature: 18,
//     electricHeating: 100,
//     emissionRate: 22,
//     gasHeating: 0,
//     costOfElectricity: 0.13,
//     costOfGas: 0.01,
//     erC: 10,
//     erHThermal: 11,
//     operationalHours: 4000,
//   },
// };

// export const metaDataReducer = (state = metaDataInitialState, action) => {
//   switch (action.type) {
//     case 'metaData/updated':
//       return { ...state, metaData: action.payload };

//     default:
//       return state;
//   }
// };

// interface EnergyState {
//   energy_consumption: {
//     fan: number;
//     heat: number;
//     cooling: number;
//   };
//   energy_max: {
//     fan: number;
//     heat: number;
//     cooling: number;
//   };
//   electric_cost: number;
//   heating_cost: number;
// }

// const EnergyInitialState: EnergyState = {
//   energy_consumption: {
//     fan: 0,
//     heat: 0,
//     cooling: 0,
//   },
//   energy_max: {
//     fan: 0,
//     heat: 0,
//     cooling: 0,
//   },
//   electric_cost: 0,
//   heating_cost: 0,
// };


// export const getEnergyThunk = async (dispatch, getState) => {
//   const weather = getState().report.data.jobs[0].weather.historicalData.datas.map((item) => {
//     return {
//       temperature: item.main.temp - 273.15,
//       humidity: item.main.humidity,
//     };
//   });

//   const metaData = getState().metaData.metaData;
//   const airTimeSeriesConsumption_flatlist = [];

//   getState().report.data.jobs[0].systems.map((itemS, indexS) => {
//     itemS.hoods.map((itemH, indexH) => {
//       airTimeSeriesConsumption_flatlist.push(itemH.core.airTimeSeriesConsumption);
//     });
//   });
//   console.log(airTimeSeriesConsumption_flatlist);
//   const totalAirflow = airTimeSeriesConsumption_flatlist[0].map((item, index) => {
//     let value = 0;
//     for (let i = 0; i < airTimeSeriesConsumption_flatlist.length; i++) {
//       value += airTimeSeriesConsumption_flatlist[i][index].value;
//       // value = airTimeSeriesConsumption_flatlist[0][index]
//     }
//     return {
//       key: item.key,
//       value: value,
//     };
//   });

//   const airTimeSeriesConsumption_max_flatlist = [];

//   getState().report.data.jobs[0].systems.map((itemS, indexS) => {
//     itemS.hoods.map((itemH, indexH) => {
//       airTimeSeriesConsumption_max_flatlist.push(itemH.core.airflow[0].values);
//     });
//   });

//   const totalAirflow_max = airTimeSeriesConsumption_max_flatlist[0].map((item, index) => {
//     let value = 0;
//     for (let i = 0; i < airTimeSeriesConsumption_max_flatlist.length; i++) {
//       value += airTimeSeriesConsumption_max_flatlist[i][index].value;
//       // value = airTimeSeriesConsumption_flatlist[0][index]
//     }
//     return {
//       key: item.key,
//       value: value,
//     };
//   });

//   console.log(totalAirflow);
//   console.log(metaData);

//   console.log('Energy Calculation');
//   const energy_consumption = await calculateEnergyConsumption(weather, totalAirflow, metaData);
//   const energy_max = await calculateEnergyConsumption(weather, totalAirflow_max, metaData);

//   const electric_cost = metaData.costOfElectricity;
//   const heating_cost = (metaData.costOfGas * metaData.gasHeating + metaData.costOfElectricity * metaData.electricHeating) / 100;
//   console.log(energy_consumption);
//   dispatch({ type: 'energy_consumption/updated', payload: { energy_consumption, heating_cost, electric_cost } });
//   dispatch({ type: 'energy_max/updated', payload: { energy_max, heating_cost, electric_cost } });
// };

// export const energyReducer = (state = EnergyInitialState, action) => {
//   switch (action.type) {
//     case 'energy_consumption/updated':
//       return { ...state, energy_consumption: action.payload.energy_consumption, electric_cost: action.payload.electric_cost, heating_cost: action.payload.heating_cost };
//     case 'energy_max/updated':
//       return { ...state, energy_max: action.payload.energy_max, electric_cost: action.payload.electric_cost, heating_cost: action.payload.heating_cost };
//     default:
//       return state;
//   }
// };
