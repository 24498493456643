import { ReportData } from '../../types/AppTypes';
import { formatReport } from '../FormatReport';
import { ENDPOINT_BASE } from 'constants/index';

import { createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl =
    `${ENDPOINT_BASE}template?token=`;

export const fetchReport = async function <T>(path: string): Promise<T> {
    const res = await fetch(path);
    const data = await res.json();
    return data;
};
// thunk to fetch report
// it's a place holder to be replaced by API call
export const fetchReportThunk = createAsyncThunk<ReportData | any, any>('reporting/fetch', async (token: any) => {
    const response = await fetch(`${baseUrl}${token}`);
    const json = await response.json();
    if (response.status === 401) {
        window.location.href = 'noaccess';
    }

    if (response.status === 404) {
        window.location.href = 'error';
    }
    try {
        const data: ReportData = formatReport(json.systems[0]);
        return data;
    } catch (e) {
        window.location.href = 'error';
    }
});