import { FC } from 'react';
import { DataObject } from '../../types/api';

import { Bar } from './Bar';
import { BarContainer } from './BarContainer';
import { ReactComponent as Logo } from 'assets/vector/halton-logo.svg';
import { ReactComponent as Chevron } from 'assets/vector/chevron.svg';
import {
  DataValue,
  getAirQualityValues,
  getThermalComfortValues,
  getViralRiskValues,
} from './dataValueGetters';
import { Button } from '@mui/material';
import { AIR_QUALITY, THERMAL_COMFORT, VIRAL_RISK } from '.';

import './AirQuality.scss';

interface Props {
  data: DataObject[];
  goBack(): void;
  isBackButtonVisible: boolean;
}
export const AirQuality: FC<Props> = ({
  data,
  goBack,
  isBackButtonVisible,
}) => {
  const thermalComfortData = data.find((d) =>
    (d as any).variableName?.includes(THERMAL_COMFORT)
  );

  const airQualityData = data.find((d) =>
    (d as any).variableName?.includes(AIR_QUALITY)
  );

  const viralRiskData = data.find((d) =>
    (d as any).variableName?.includes(VIRAL_RISK)
  );

  const thermalComfortDataValues = getThermalComfortValues(
    7,
    thermalComfortData
  );
  const activeThermalComfortValue = thermalComfortDataValues.find(
    (s) => s.isActive
  );

  const airQualityDataValues = getAirQualityValues(6, airQualityData);
  const activeAirQualityActiveValue = airQualityDataValues.find(
    (s) => s.isActive
  );

  const viralRiskValues = getViralRiskValues(5, viralRiskData);
  const viralRiskActiveValue = viralRiskValues.find((s) => s.isActive);

  return (
    <div className="AirQuality">
      {isBackButtonVisible ? (
        <Button onClick={goBack}>
          <Chevron style={{ transform: 'rotate(-90deg)' }} />
          Back
        </Button>
      ) : (
        <></>
      )}
      <BarContainer
        value={activeThermalComfortValue?.value || 0}
        title={(thermalComfortData as any)?.displayName}
        info={<InfoContent values={thermalComfortDataValues} />}
      >
        <Bar data={activeThermalComfortValue} />
      </BarContainer>
      <BarContainer
        value={activeAirQualityActiveValue?.value || 0}
        title={(airQualityData as any)?.displayName}
        info={<InfoContent values={airQualityDataValues} />}
      >
        <Bar data={activeAirQualityActiveValue} />
      </BarContainer>
      <BarContainer
        value={viralRiskActiveValue?.value || 0}
        title={(viralRiskData as any)?.displayName}
        info={<InfoContent values={viralRiskValues} />}
      >
        <Bar data={viralRiskActiveValue} />
      </BarContainer>
      <div className="AirQuality__logo">
        <Logo />
      </div>
      {/* {process.env.NODE_ENV === "development" && (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )} */}
    </div>
  );
};

const InfoContent: FC<{ values: DataValue[] }> = ({ values }) => {
  return (
    <div className="Info__wrapper">
      {values.map((v) => (
        <div
          className="Info__value-wrapper"
          data-active={v.isActive}
          data-type={v.type}
          key={v.value}
          data-hide={v.hide}
        >
          <div className="Info__value-container">
            <div className="Info__value" style={{ display: 'inline-block' }}>
              {v.value}
            </div>
          </div>
          <div className="Info__description">{v.description}</div>
        </div>
      ))}
    </div>
  );
};

export default AirQuality;
