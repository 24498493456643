import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

// import components
import PieChart from '../../components/PieChart';
import Header from '../../components/Header';

// import selectors
import { selectMarvelSharesData, MarvelSharesDataType } from '../../utils/selectors/SelectMarvelSharesData';
import { selectGlobalInfo, GlobalInfoType } from '../../utils/selectors/SelectGlobalInfo';


import './index.css';

// Definition of the columns of the table
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'HOOD',
    flex: 2,
  },
  {
    field: 'share',
    headerName: 'Share [%]',
    flex: 2,
    valueFormatter: (params: any) => `${parseFloat(params.value).toFixed(2)}%`,
  },
];
// ---------------------------------------------------------------

// Handle the selection of an item in the table-------------------
const handleSelection = <T,>(selectedItem: number[], data : T[], setData : (data : T[])=> void) : void => {
  const selectedData = selectedItem.map((item) => data[item]);
  setData(selectedData);
};
// ---------------------------------------------------------------


// Main component
const TechMarvelShares = () => {
  const marvelSharesData = useSelector(selectMarvelSharesData);
  const globalInfoStore = useSelector(selectGlobalInfo);

  const [showMessage, setShowMessage] = useState<boolean>(true);
  const [data, setData] = useState<MarvelSharesDataType[]>([]);

  const handleSelectItem = (selectedItem: number[]) => {
    handleSelection<MarvelSharesDataType>(selectedItem, marvelSharesData, setData);
    setShowMessage(false);
  };

  return (
    <Box m='20px' display='flex' justifyContent='space-between'>
      {showMessage ? (
        <Box
          m='60px'
          width='50vw'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Box marginBottom='50px'>
            <div className='loader'></div>
          </Box>
          <Box m='20px'>PLEASE SELECT AN ITEM</Box>
        </Box>
      ) : (
        <Box className='boxPieChart'>
          <PieChart data={data} />
        </Box>
      )}

      <Box className='boxDataGrid'>
        <DataGrid
          rows={marvelSharesData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          onRowSelectionModelChange={(item: any) => handleSelectItem(item)}
        />
      </Box>
    </Box>
  );
};

export default TechMarvelShares;
