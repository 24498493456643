import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// import components
import TechMarvelTimeSeriesMainScreen from './techMarvelTimeSeriesMainScreen';

// import selectors
import { selectMarvelAirTSData, AirflowTimeSeriesType } from '../../utils/selectors/SelectMarvelAirTSData';
import { selectGlobalInfo, GlobalInfoType } from '../../utils/selectors/SelectGlobalInfo';

// CSS
import './index.css';

// Main component
const TechMarvelTimeSeries = () => {
    //   Data selected from the state
    const marvelAirTSData: AirflowTimeSeriesType[] = useSelector(selectMarvelAirTSData);
    const globalInfo: GlobalInfoType = useSelector(selectGlobalInfo);

    const [data, setData] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [start, setStart] = useState<Date | null>(new Date(globalInfo.dateFrom));
    const [end, setEnd] = useState<Date | null>(new Date(globalInfo.dateTo));

    const handleStartChange = (newValue: Date | null) => {
        setStart(newValue);
    };
    const handleEndChange = (newValue: Date | null) => {
        setEnd(newValue);
    };

    useEffect(() => {
        const selectedData = selectedItems.map((item) => marvelAirTSData[item]);
        const DateTimeBasedSelectedData = selectedData.map((item, index) => {
            return {
                ...item,
                data: item.data.filter((d: any) => {
                    const time: Date = new Date(d.key);
                    return time >= start! && time <= end!;
                }),
            };
        });
        setData(DateTimeBasedSelectedData);
    }, [start, end, selectedItems, marvelAirTSData]);

    const handleSelectItem = (selectedItem: any[]) => {
        const selectedData = selectedItem.map((item) => marvelAirTSData[item]);
        setSelectedItems(selectedItem);

        const newSelectedData = selectedData.map((item, index) => {
            const theSelectedData = {
                ...item,
                data: item.data.filter((d: any) => {
                    const time = new Date(d.key);
                    return time >= start! && time <= end!;
                }),
            };

            return theSelectedData;
        });
        setData(newSelectedData);
    };

    return (
        <TechMarvelTimeSeriesMainScreen start={start} end={end} handleStartChange={handleStartChange} handleEndChange={handleEndChange} handleSelectItem={handleSelectItem} data={data} marvelAirTSData={marvelAirTSData}></TechMarvelTimeSeriesMainScreen>
    );
};

export default TechMarvelTimeSeries;
