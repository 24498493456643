import {JobData, LocalReportStoreType, SystemData} from '../types/AppTypes';
import {RootStateType} from '../../store';
import { createSelector} from '@reduxjs/toolkit';

export interface MarvelSharesDataType {
    id: number;
    name: string;
    share: number;
}

const SystemsSelector = (state : RootStateType) : SystemData[] => {
    return state.reporting.data.jobs[0].systems
}

const JobSelector = (state : RootStateType) : JobData => {
    return state.reporting.data.jobs[0]
}

export const selectMarvelSharesData = createSelector([SystemsSelector, JobSelector], (systems: SystemData[], job : JobData) => {
    const jobTotalConsumption = job.core.consumptionAgg;
    const aggTechMARVELSharesData : MarvelSharesDataType[] = [];

    systems.forEach((item, index) => {
        const systemName = item.systemName;
        const hoods = item.hoods;

        hoods.forEach((itemH, indexH) => {
            const name = systemName + '_' + itemH.hoodsName;

            const share = (itemH.core.consumptionAgg / jobTotalConsumption)*100;

            const resultPerHood = {
                id: aggTechMARVELSharesData.length,
                name,
                share,
            };

            aggTechMARVELSharesData.push(resultPerHood);

        });

    });

    return aggTechMARVELSharesData;

})
// export const selectMarvelSharesData = (report: RootStateType) : MarvelSharesDataType[] => {
//     const reporting : LocalReportStoreType = report.reporting
//     const job = reporting.data.jobs[0];
//     const systems = job.systems;
//
//     const jobTotalConsumption = job.core.consumptionAgg;
//
//     let aggTechMARVELSharesData : MarvelSharesDataType[] = [];
//
//     systems.forEach((item, index) => {
//       const systemName = item.systemName;
//       const hoods = item.hoods;
//
//       hoods.forEach((itemH, indexH) => {
//         const name = systemName + '_' + itemH.hoodsName;
//
//         const share = (itemH.core.consumptionAgg / jobTotalConsumption)*100;
//
//         const resultPerHood = {
//           id: aggTechMARVELSharesData.length,
//           name,
//           share,
//         };
//
//         aggTechMARVELSharesData.push(resultPerHood);
//
//       });
//
//     });
//
//     return aggTechMARVELSharesData;
//   };
//