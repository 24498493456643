import React from 'react';
import {ResponsiveBar} from '@nivo/bar';
import {Box, Typography} from '@mui/material';

// Import Types
import {BarDatum} from '@nivo/bar';

interface BarChartProps {
    data: BarDatum[];
}

// BarChart based on Responsive Bar from @nivo
// BarChart is used for savings garphics
const BarChart = ({data}: BarChartProps) => {
    const totalValues: { [name: string]: number } = {};
    data.forEach((entry) => {
        if (!totalValues[entry.id]) {
            totalValues[entry.id] = 0;
        }
        totalValues[entry.id] += Number(entry.consumption) + Number(entry.savings);
    });

    return (
        <Box height={'100%'}>
            <ResponsiveBar
                data={data}
                theme={{
                    labels: {
                        text: {
                            fontSize: 14,
                        },
                    },
                    axis: {
                        domain: {
                            line: {
                                stroke: '#141414',
                            },
                        },
                        legend: {
                            text: {
                                fill: '#141414',
                                fontSize: 14,
                                fontWeight: 600,
                            },
                        },
                        ticks: {
                            line: {
                                stroke: '#141414',
                                strokeWidth: 1,
                            },
                            text: {
                                fill: '#141414',
                                fontSize: 12,
                            },
                        },
                    },
                    legends: {
                        text: {
                            fill: '#141414',
                            fontSize: 14,
                        },
                    },
                }}
                keys={['consumption', 'savings']}
                indexBy='id'
                margin={{top: 50, right: 130, bottom: 50, left: 100}}
                padding={0.7}
                layout='horizontal'
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={{scheme: 'nivo'}}
                label={(d) => {
                    const value = Number(d.value);
                    const total = totalValues[d.data.id];

                    const percentage = (value / total) * 100;
                    return `${value.toFixed()} (${percentage.toFixed(2)}%)`;

                }}

                labelTextColor='Black'
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Air Total Consumption / Savings (m3)',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: 0,
                    legend: 'HOODS',
                    legendPosition: 'middle',
                    legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -30,
                        itemsSpacing: 40,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                barAriaLabel={(e) => e.id.toString()}
            />
        </Box>
    );
};

export default BarChart;
