import { Routes, Route, Navigate } from 'react-router-dom';

import { useTemplateToken } from 'hooks/useTemplateToken';

import AirQualityIndex from 'views/AirQualityIndex';
import RealTimeIEQ from 'views/RealTimeIEQ';
import NoAccess from 'views/NoAccess';
import Error from 'views/Error';

import './App.scss';
import './styles.css';
import 'react-vis/dist/style.css';
import FoodServiceReport from 'views/FoodServiceReport/FoodServiceReport';
import { store } from 'views/FoodServiceReport/store';
import { Provider } from 'react-redux';

function FSReport() {
  return (
    <Provider store={store}>
      <FoodServiceReport />
    </Provider>
  )
}

function App() {
  const { parsedToken, rawToken } = useTemplateToken();

  if (parsedToken) {
    return <Navigate to={`/${parsedToken.template_id}?t=${rawToken}`} />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/1" element={<AirQualityIndex />} />
        <Route path="/2" element={<FSReport />} />
        <Route path="/4" element={<RealTimeIEQ />} />
        <Route path="/noaccess" element={<NoAccess />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

const WrappedApp = () => <App />;

export default WrappedApp;
