import { FullReportAzureType, System, Hood } from '../types/FullReportAzureType';
import { ReportData, LocalHood, SystemData, LocalDatum, KeyValueItems } from '../types/AppTypes';

import { aggregateProduct, aggregateSum } from './AggregateArrays';

// format Report is a helper that format the report in the format for the react dashboard
export const formatReport = function (report: FullReportAzureType): ReportData {
  // get weather data
  const weatherData: LocalDatum[] = report.weather?.historicalData?.datas.map((item: any) => {
    return { timestamp: item.timestamp, main: item.main };
  });

  // get connection data
  // const localConnection: LocalConnection = report.connection;


  let jobTotalConsumption = 0;
  let jobTotalSavings = 0;

  const dateFrom: string = report.timeRange.from;
  const dateTo: string = report.timeRange.to;
  const jobName: string = report.systemId;
  const location: { latitude: number; longitude: number } = report.location;

  // get systems data
  const localSystems: SystemData[] = report.systems.map((itemS: System, indexS: number) => {
    let systemTotalConsumption = 0;
    let systemTotalSavings = 0;
    const localHoods: LocalHood[] = itemS.hoods.map((itemH: Hood, indexH: number) => {
      const result: LocalHood = {
        hoodsName: itemH.name,
        core: {
          consumptionAgg: -1,
          airflowDesign_CMH: itemH.coreData.airflow.find(x => x.variable.includes('DesignAirflowAvgHr'))?.values || null,
          airflowActual_CMH: [],
        },
        technologies: {
          MARVEL: {
            active: true,
            savingsAgg_Perc: -1,
            savingsAgg_CM: -1,
            airflowPercAvgHr_Perc: itemH.coreData.airflow.find(x => x.variable.includes('AirflowPercAvgHr'))?.values || [],
            temperature: itemH.coreData.exhaustTemperature.find(x => x.variable.includes('ExhaustTempAvgHr'))?.values || null,
            alarms: [],
          },
        },
      };

      let aggConsumption: { agg: number, values: KeyValueItems };

      try {
        aggConsumption = aggregateProduct(result.core.airflowDesign_CMH, result.technologies.MARVEL.airflowPercAvgHr_Perc, 0.01)
        result.core.consumptionAgg = aggConsumption.agg;
        result.core.airflowActual_CMH = aggConsumption.values;
        result.technologies.MARVEL.savingsAgg_CM = aggregateSum(result.core.airflowDesign_CMH) - aggregateSum(result.core.airflowActual_CMH);
        result.technologies.MARVEL.savingsAgg_Perc = (100 * result.technologies.MARVEL.savingsAgg_CM) / aggregateSum(result.core.airflowDesign_CMH);
      } catch (error) {

      }

      systemTotalConsumption += result.core.consumptionAgg;
      systemTotalSavings += result.technologies.MARVEL.savingsAgg_CM;

      return result;
    });

    const localSystem: SystemData = {
      systemName: itemS.name,
      core: {
        consumptionAgg: systemTotalConsumption,
      },
      technologies: {
        MARVEL: {
          active: true,
          savingsAgg: systemTotalSavings,
        },
      },
      hoods: localHoods,
    };

    jobTotalConsumption += localSystem.core.consumptionAgg;
    jobTotalSavings += localSystem.technologies.MARVEL.savingsAgg;

    return localSystem;
  });

  const data: ReportData = {
    dateFrom,
    dateTo,
    jobs: [
      {
        jobName,
        location,
        weather: {
          historicalData: {
            data: weatherData,
          },
        },
        // connection: {
        //   values: localConnection,
        // },
        core: {
          consumptionAgg: jobTotalConsumption,
        },
        technologies: {
          MARVEL: {
            active: true,
            savingsAgg: jobTotalSavings,
          },
        },
        systems: localSystems,
      },
    ],
  };

  return data;
};
