import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {MenuItem} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

type ItemProps = {
    id: string;
    title: string;
    to: string;
    toComponent : JSX.Element
    icon: React.ReactNode;
    selected: string;
    setSelected: (selected: string) => void;

};

export const CustomMenuItem = ({id, title, to, toComponent, icon,selected, setSelected}: ItemProps): JSX.Element => {
    const dispatch = useDispatch();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
            <MenuItem
                active={selected===id}
                onClick={() => {
                    setSelected(id);
                    dispatch({type : 'navigation/changed', payload : to})
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                icon={icon}
                style={{
                    backgroundColor: (isHovered && selected!==id) ? '#f2f2f2' : (selected===id) ? '#2499d5' : '#ffffff',
                    color: (isHovered && selected!==id) ? '#2499d5' : (selected===id) ? '#ffffff' : '#2499d5',
                    transitionDuration: '0.3s'
                }}
            >
                <Typography>{title}</Typography>
            </MenuItem>
    );
};

