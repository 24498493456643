import {EnergyParamsType} from '../types/AppTypes';


// Utilities for energy calculation
const getAtmPressure = (altitudeM: number): number => {
    const p0 = 101325; // unit : Pa
    const T0 = 288.15; // unit : K
    const g = 9.80665; // unit : m/s2
    const L = 0.0065; // unit : K/m
    const R = 8.31446; // unit : 8.31446 J/(mol.K)
    const M = 0.0289652; // unit : kg/mol

    const atmPressure = p0 * Math.pow(1 - (L * altitudeM) / T0, (g * M) / (R * L))
    return atmPressure;
}

const getAirDensity = (atmPressurePa: number, outdoor_temp_C: number): number => {
    // source : https://en.wikipedia.org/wiki/Density_of_air
    const R = 8.31446; // unit : 8.31446 J/(mol.K)
    const M = 0.0289652; // unit : kg/mol

    // const airDensity = atmPressurePa / (8.314472 * (outdoor_temp_C + 273.14))
    const airDensity = (atmPressurePa * M) / (R * (outdoor_temp_C + 273.14));
    return airDensity;
};
const getHumidityRatio = (partialPressureWV: number, atmPressure: number): number => {
    return (0.6222 * partialPressureWV) / (atmPressure - partialPressureWV);
};


const getEnthalpyRH = (t: number, RH: number, atmPressure: number): number => {
    const CPAir = 1.003;
    const saturationWaterPressure: number = getSaturationWaterPressure(t); //Saturation water pressure, Pa
    // const partialPressureWV = (saturationWaterPressure * RH) / 100; //Partial water pressure [Pa], RH [0..1]


    const W = getHumidityRatio(saturationWaterPressure, atmPressure); //in kg per Kg
    return CPAir * t + W * (2501 + 2.86 * t)

};

// water saturation pressure
const getSaturationWaterPressure = (temp_C : number) : number => {
    let Pwater;
    if (temp_C >= 0) {
        Pwater = 288.68 * Math.pow(1.098 + (temp_C / 100), 8.02)
    } else {
        Pwater = 4.689 * Math.pow(1.486 + (temp_C / 100), 12.3)
    }
    return Pwater;
};


// ---------------------------------------------------------------------------------------------------------
const getPressure = (airflow_cmh: number, airflow_Design_cmh: number, dP_Design_Pa: number): number => {
    const dPActual = airflow_Design_cmh === 0 ? 0 : dP_Design_Pa * Math.pow(airflow_cmh / airflow_Design_cmh, 2);
    return dPActual;
};

//  ---------------------- Fan Power ---------------------------------------------------------
// Fan Power in W
const getFanPowerConsumption = (airflow_cmh: number, dPPa: number, fanEff: number): number => {
    const fanPower = fanEff === 0 ? -1 : (airflow_cmh * dPPa) / fanEff / 3600 / 1000;
    return fanPower;
};

//  ---------------------- Heating Power ---------------------------------------------------------
const getHeatCapacity = (outdoorMassAirFlowKgPerS: number, returAirMassAirFlowKgPerS: number, outdoor_Temp_C: number, returAirTempC: number, spaceTempC: number): number => {
    // m_oa, m_ret, t_oa, t_ret, t_s
    // outdoorMassAirFlowKgPerS       : outdoor air mass air flow
    // outdoor_Temp_C                   : outdoor air temperature
    // returAirMassAirFlowKgPerS      : return exhuast mass air flow
    // returAirTempC                  : return exhuast temperature
    const CPAir = 1.003;

    // Mass of Air Supply
    // The total mass air = mass from outdoor + mass from return air
    const mS = outdoorMassAirFlowKgPerS + returAirMassAirFlowKgPerS;
    //

    // The temperature of supply is a weighted temperature of outdoor and return air, by the air mass
    const tBc = (returAirMassAirFlowKgPerS * returAirTempC + outdoorMassAirFlowKgPerS * outdoor_Temp_C) / mS;
    //

    // Power supplied to the space induced by air supplied at T = t_bc
    const Q = (spaceTempC - tBc >= 0) ? mS * CPAir * (spaceTempC - tBc) : 0;

    return Q;
};

const getHeatPower = (altitudeM:number, airflow_cmh:number, outdoor_Temp_C:number, spaceTempC:number, heatEff:number): number => {
    const returAirMassAirFlowKgPerS = 0;
    const returAirTempC = spaceTempC;

    const atmPressurePa = getAtmPressure(altitudeM);
    //
    const outdoorMassAirFlowKgPerS = airflow_cmh * getAirDensity(atmPressurePa, outdoor_Temp_C) / 3600;

    const heatPowerW = (heatEff <= 0) ? 0 : getHeatCapacity(outdoorMassAirFlowKgPerS, returAirMassAirFlowKgPerS, outdoor_Temp_C, returAirTempC, spaceTempC) / heatEff;
    return heatPowerW;
};
//  ---------------------- Cooling Power ------------------------------------------------------------

const getCoolingCapacity = (outdoorMassAirFlowKgPerS:number, returAirMassAirFlowKgPerS:number, outdoor_Temp_C:number, returAirTempC:number, outdoor_RH:number, returAir_RH:number, room_Temp_C:number, room_RH:number, atmPressurePa: number) : number => {
    const CPAir = 1.003; // kJ/Kg/K

    // Mass of Air Supply
    // The total mass air = mass from outdoor + mass from return air
    const mS = outdoorMassAirFlowKgPerS + returAirMassAirFlowKgPerS;

    const hRet = getEnthalpyRH(returAirTempC, returAir_RH, atmPressurePa);
    const hOa = getEnthalpyRH(outdoor_Temp_C, outdoor_RH, atmPressurePa);

    const hBc = (outdoorMassAirFlowKgPerS * hOa + returAirMassAirFlowKgPerS * hRet) / mS;

    const hAc = getEnthalpyRH(room_Temp_C, room_RH, atmPressurePa);

    const Q = (hAc - hBc <= 0) ? -mS * (hAc - hBc) : 0;
    return Q;
};

const getCoolingPower = (airflow_cmh: number, outdoor_Temp_C: number, outdoor_RH:number, returAirTempC:number, returAir_RH: number, room_Temp_C: number, room_RH:number, altitudeM:number, COP:number) : number => {
    const atmPressurePa = getAtmPressure(altitudeM);

    const outdoorMassAirFlowKgPerS = getAirDensity(atmPressurePa, outdoor_Temp_C) * airflow_cmh / 3600;
    const returAirMassAirFlowKgPerS = getAirDensity(atmPressurePa, returAirTempC) * 0 / 3600;

    const coolingPower = getCoolingCapacity(outdoorMassAirFlowKgPerS, returAirMassAirFlowKgPerS, outdoor_Temp_C, returAirTempC, outdoor_RH, returAir_RH, room_Temp_C, room_RH, atmPressurePa) / COP;
    return coolingPower;
};


export const calculateEnergyConsumption = async (weather:{temperature: number; humidity: number}[], airflow: number[], metaData : EnergyParamsType) => {
    const {roomTemperature, roomHumidity, supplyTemperature,heaterEfficiency, fanEfficiency, copAHU, elevation, dPDesign} = metaData;

    const weatherAirflow = weather.map((item, index) => {
        return {...item, airflow: airflow[index]};
    });

    const spaceTempC = roomTemperature;
    const spaceHumidity = roomHumidity;

    const heatEff = heaterEfficiency;
    const altitudeM = elevation;

    const dPPa = dPDesign;
    const fanEff = fanEfficiency;

    const COP = copAHU;

    const power = weatherAirflow.map((item, index) => {
        // const fanPower = getFanPowerConsumption(airflow_cmh, dPPa, fanEff);
        // const heatPower = getHeatPower(altitudeM, airflow_cmh, item.temperature, spaceTempC, heatEff);
        // const coolingPower = getCoolingPower(airflow_cmh, item.temperature, item.humidity, 19, spaceHumidity, spaceTempC, spaceHumidity, altitudeM, COP);
        const fanPower = getFanPowerConsumption(item.airflow, dPPa, fanEff);
        const heatPower = getHeatPower(altitudeM, item.airflow, item.temperature - 273.15, spaceTempC, heatEff);
        const coolingPower = getCoolingPower(item.airflow, item.temperature - 273.15, item.humidity, 19, spaceHumidity, spaceTempC, spaceHumidity, altitudeM, COP);

        return {cooling: coolingPower, heat: heatPower, fan: fanPower};
    });

    const heatConsumption = power.map((item) => item.heat).reduce((acc, act) => acc + act);
    const fanConsumption = power.map((item) => item.fan).reduce((acc, act) => acc + act);
    const coolingConsumption = power.map((item) => item.cooling).reduce((acc, act) => acc + act);
    return {heating: heatConsumption, fan: fanConsumption, cooling: coolingConsumption}
};


