import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';

import {CustomMenuItem} from './CustomItem';
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {Typography, useMediaQuery} from '@mui/material';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import LineAxisIcon from '@mui/icons-material/LineStyle';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SettingsInputCompositeOutlinedIcon from '@mui/icons-material/SettingsInputCompositeOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';


import Dashboard from '../../scenes/dashboard';
import TechMarvelSavings from '../../scenes/techMarvelSavings';
import TechMarvelShares from '../../scenes/techMarvelShares';
import TechMarvelTimeSeries from '../../scenes/techMarvelTimeSeries';
// import WarningsAlarms from '../../scenes/warningsAlarms';
import EnergyParameters from '../../scenes/energyParameters';
// import Documentation from '../../scenes/documentation';

import { ReactComponent as Logo } from '../../resources/Halton_Logo.svg'

export const LeftSidebar = (): JSX.Element => {
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const isMobile: boolean = useMediaQuery('(max-width:750px)');
    const [selected, setSelected] = useState<string>('dashboard');
    
    return (
        <Box className='fsReportSideBar'>
            <Sidebar collapsed={isCollapsed} backgroundColor={'#ffffff'} style={{height: '100%'}}>
                <Menu
                    menuItemStyles={{
                        button: {
                            color: '#2499D5',
                            backgroundColor: '#ffffff',
                            // '&:hover': {
                            //     // backgroundColor: '#ffffff',
                            //     backgroundColor: '#2499d5',
                            //     color: '#ffffff',
                            //     transitionDuration: '0.3s', 
                            // },
                            '&:active' : {
                                backgroundColor: '#2499d5',
                                color: '#ffffff',
                                transitionDuration: '0.3s', 
                            },
                        },
                    }}
                style={{marginTop: '30px'}}
                >
                    {/* <MenuItem
                        onClick={() => {
                            setIsCollapsed(!isCollapsed);
                        }}
                        icon={isCollapsed ? <MenuOutlinedIcon/> : <></>}
                    ></MenuItem> */}
                    <MenuItem style={{ height: '3rem', marginBottom: '2rem', cursor: 'default', backgroundColor: 'transparent'}} >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Logo fill='#2499D5' width="180" height="180"/>
                    </Box>
                        {/* <Typography variant='h2' color={'white'} paddingLeft={'10px'} paddingBottom={'20px'} */}
                                    {/* marginBottom={'0px'} fontWeight={500}> */}
                           {/* <img src={logo} alt="Halton" style={{ stroke: 'white' }}/>  */}
                        {/* </Typography> */}
                    </MenuItem>
                    <CustomMenuItem title='Dashboard' id='dashboard' to='/' toComponent={<Dashboard/>}
                                    icon={<HomeOutlinedIcon/>}
                                    selected={selected} setSelected={setSelected}/>
                    <SubMenu
                        label='Technologies'
                        id='technologies'
                        active={selected === 'technologies' ? true : false}
                        style={{
                            fontSize: '1rem',
                        }}
                        icon={<AssessmentOutlinedIcon/>}
                    >
                        <CustomMenuItem title='Savings' id='technologies-savings' to='/tech-marvel-savings'
                                        toComponent={<TechMarvelSavings/>}
                                        icon={<StackedBarChartIcon/>} selected={selected} setSelected={setSelected}/>
                        <CustomMenuItem title='Shares' id='technologies-share' to='/tech-marvel-shares'
                                        toComponent={<TechMarvelShares/>}
                                        icon={<DonutLargeIcon/>} selected={selected} setSelected={setSelected}/>
                        <CustomMenuItem title='Time Series' id='technologies-timeseries' to='/tech-marvel-timeseries'
                                        toComponent={<TechMarvelTimeSeries/>}
                                        icon={<LineAxisIcon/>} selected={selected} setSelected={setSelected}/>
                    </SubMenu>
                    <CustomMenuItem title='Alarms and Warnings' id='alarms-warnings' to='/coming-soon' toComponent={<Dashboard/>}
                                    icon={<ReportProblemOutlinedIcon/>} selected={selected} setSelected={setSelected}/>
                    <CustomMenuItem title='Energy parameters' id='energy-parameters' to='/energy-parameters'
                                    toComponent={<EnergyParameters/>}
                                    icon={<SettingsInputCompositeOutlinedIcon/>} selected={selected}
                                    setSelected={setSelected}/>
                    <CustomMenuItem title='Documentation' id='documentation' to='/coming-soon' toComponent={<Dashboard/>}
                                    icon={<NewspaperOutlinedIcon/>} selected={selected} setSelected={setSelected}/>
                </Menu>
            </Sidebar>
        </Box>
    );
};
