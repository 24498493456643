import { FC } from 'react';
import { DataObject } from '../../types/api';

import { DataContainer } from './DataContainer';
import { ReactComponent as Logo } from 'assets/vector/halton-logo.svg';
import { ReactComponent as Chevron } from 'assets/vector/chevron.svg';
import  Chart from 'assets/png/chart.png';

import { Button } from '@mui/material';
import { TEMPERATURE, HUMIDITY, CO2, PARTICLES,VOCS} from '.';

import './AirQuality.scss';

interface Props {
  data: DataObject[];
  goBack(): void;
  isBackButtonVisible: boolean;
}
export const AirQuality: FC<Props> = ({
  data,
  goBack,
  isBackButtonVisible,
}) => {
  const temperature = data.find((d) =>
    (d as any).variableName?.includes(TEMPERATURE)
  );

  const co2 = data.find((d) =>
    (d as any).variableName?.includes(CO2)
  );

  const humidity = data.find((d) =>
    (d as any).variableName?.includes(HUMIDITY)  
  );

  const particles = data.find((d) =>
  (d as any).variableName?.includes(PARTICLES)  
);

const vocs= data.find((d) =>
(d as any).variableName?.includes(VOCS)  
);




  
  return (
    <div className="AirQuality">
      {isBackButtonVisible ? (
        <Button onClick={goBack}>
          <Chevron style={{ transform: 'rotate(-90deg)' }} />
          Back
        </Button>
      ) : (
        <></>
      )}
      <DataContainer
        value={(temperature as any)?.value}
        title={(temperature as any)?.displayName +' '+(temperature as any)?.value+ '°C' }
        info={<h3></h3>}
      
      >
          
      </DataContainer>
      <DataContainer
        value={ 0}
        title={(co2 as any)?.displayName +' '+(co2 as any)?.value+ ' ppm' }
        info={<h3>Most living organisms, including humans, exhale carbon dioxide (CO2) through respiration. Without sufficient ventilation, CO2 would accumulate continuously in occupied buildings. CO2 is not harmful at commonly observed indoor levels, but emerging evidence suggests that CO2 exposure may lead to impaired decision-making.</h3>}
      >

      </DataContainer>
      <DataContainer
        value={ 0}
        title={(humidity as any)?.displayName +' '+(humidity as any)?.value+ '%' }
        info={<InfoContent values={[]} />}
      >


      </DataContainer>
      <DataContainer
        value={ 0}
        title={(particles as any)?.displayName +' '+(particles as any)?.value+ ' µg/m³' }
        info={<h3>Particulate matter (PM) air pollution is a complex mixture of small, solid particles and liquid droplets. Inhaling elevated levels of PM has been shown to lead to various adverse health effects, primarily associated with the cardiopulmonary system. Exposure to elevated levels of PM is the leading source of mortality among outdoor air pollutants.</h3>}
      >



      </DataContainer>
      <DataContainer
        value={ 0}
        title={(vocs as any)?.displayName+' '+(vocs as any)?.value+ ' ppb' }
        info={<h3>Exposure to volatile organic compounds (VOCs) is associated with irritation of the eye, nose and throat, as well as liver and kidney damage. Effects on the central nervous system, including dizziness or loss of coordination, may be an early indication of VOC exposure, as VOCs are known to permeate lipid membranes, making the brain an easy target due to its high lipid content.</h3>}
      >
      </DataContainer>

      <DataContainer
        value={ 0}
        title={ 'Pollutant level chart' }
        info = {<img src={Chart}/>}
       >
      </DataContainer>
        
      
      


      <div className="AirQuality__logo">
        <Logo />
      </div>
      {/* {process.env.NODE_ENV === "development" && (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )} */}
    </div>



  );
};

const InfoContent: FC<{ values: [] }> = ({ values }) => {
  return (
    <div className="Info__wrapper">
      {values.map((v) => (
        <div
          className="Info__value-wrapper"

        >
          <div className="Info__value-container">
            <div className="Info__value" style={{ display: 'inline-block' }}>

            </div>
          </div>
          <div className="Info__description">{}</div>
        </div>
      ))}
    </div>
  );
};

export default AirQuality;
