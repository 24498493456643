import { useState } from 'react';
import { useSelector } from 'react-redux';

// import selectors
import { selectMarvelSavingsData, MarvelSavingsDataType } from '../../utils/selectors/SelectMarvelSavingsData';

import './index.css';
import MainScreen from './savingsMainScreen';


// Main component-------------------------------------------------
const TechMarvelSavings = () => {

    const marvelSavingsData: MarvelSavingsDataType[] = useSelector(selectMarvelSavingsData);
    const [data, setData] = useState<MarvelSavingsDataType[]>([]);
    // ---------------------------------------------------------------

    let shouldDisplayTable = true;
    marvelSavingsData.map((item: MarvelSavingsDataType, index: number) => {
        const consumption: number | string = item.consumption.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,

        })

        if (!shouldDisplayTable) return;
        if (parseInt(consumption) == -1) { shouldDisplayTable = false; return; }
    })

    return (
        <MainScreen shouldDisplayTable={shouldDisplayTable} data={data} setData={setData} marvelSavingsData={marvelSavingsData}></MainScreen>
    );
};

export default TechMarvelSavings;
