import { ReactNode, FC, useState } from 'react';
import { ReactComponent as Chevron } from 'assets/vector/chevron.svg';

export const BarContainer: FC<{
  title: string;
  value: number;
  info?: ReactNode | undefined;
  children: any;
}> = ({ title, children, info }) => {
  const [isOpen, isOpenSet] = useState<boolean>(false);
  const toggle = () => isOpenSet((prev) => !prev);
  return (
    <div className={`BarContainer ${isOpen ? ' BarContainer--open' : ''}`}>
      <div className="BarContainer__wrapper">
        <h2 className="BarContainer__title" onClick={toggle}>
          <span className="BarContainer__title-text">{title}</span>
          <span className="BarContainer__title-icon">
            <Chevron />
          </span>
        </h2>
        <div className="BarContainer__content">{children}</div>
        <div className="BarContainer__info">{info}</div>
      </div>
    </div>
  );
};
