// import MUI
import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// import components
import LineChart from '../../components/LineChart';
import CustomTimePicker from '../../components/CustomTimePicker';
import { ReactComponent as Wrong } from 'assets/vector/wrong.svg';

// import selectors
import { AirflowTimeSeriesType } from '../../utils/selectors/SelectMarvelAirTSData';

// CSS
import './index.css';

// Definition of the columns of the table
interface Column {
    field: string;
    headerName: string;
    flex: number;
}

// Props interface for Tech Marvel Time Series Main Screen
interface ITechMarvelSharesMainScreenProps {
    start: Date | null;
    end: Date | null;
    handleStartChange: any; // function
    handleEndChange: any; // function
    data: any[];
    marvelAirTSData: AirflowTimeSeriesType[];
    handleSelectItem: any; // function
}

const columns: Column[] = [
    {
        field: 'name',
        headerName: 'HOOD',
        flex: 1,
    },
];
// ---------------------------------------------------------------

// Tech Marvel Time Series Main Screen
const TechMarvelTimeSeriesMainScreen = (props: ITechMarvelSharesMainScreenProps) => {

    if (props.marvelAirTSData?.length > 0 && props.marvelAirTSData[0].data?.length == 0) {
        return <div style={{
            height: '80vh',
            display: 'flex',
            paddingTop: '10rem',
            alignItems: 'center',
            padding: '1.125rem',
            justifyContent: 'center'
        }}>
            <Typography fontFamily={'Roboto'}>
                <div className="Page">
                    <div className="Page__icon">
                        <Wrong />
                    </div>
                    <p style={{ fontSize: '1.3rem', marginTop: '1.5rem' }}>Timeseries data at this moment is unavailable</p>
                    {/* <p>Please contact <i><a href="mailto:contact@halton.com" style={{ color: '#2499d5' }}><u>contact@halton.com</u></a></i></p> */}
                </div>
            </Typography>
        </div>
    } else {

        return (
            <Box m={'1.25rem'} ml={'3.125rem'} display={'flex'} height={'100%'} justifyContent='space-between'
                style={{ paddingBottom: '800px' }}>
                <Box className='boxhauteur'>
                    <Box>
                        <CustomTimePicker
                            handleStartChange={(date: Date | null) => {
                                props.handleStartChange(date)
                            }}
                            handleEndChange={(date: Date | null) => {
                                props.handleEndChange(date)
                            }}
                            start={props.start}
                            end={props.end}
                        />
                    </Box>
                    {props.data.length === 0 ? (
                        <Box
                            m='20px'
                            width={'50vw'}
                            height={'60vh'}
                            display={'flex'}
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                        >
                            <Box marginBottom={'50px'}>
                                <div className='loader'></div>
                            </Box>
                            <Box m={'20px'}>PLEASE SELECT AN ITEM</Box>
                        </Box>
                    ) : (
                        <Box height={'75vh'} width={'50vw'} className='hauteur'>
                            <LineChart
                                data={props.data.map((item: any) => {
                                    return {
                                        id: item.id,
                                        data: item.data,
                                    };
                                })}
                            />
                        </Box>
                    )}
                </Box>

                <Box
                    m={'40px 0 0 0'}
                    height={'75vh'}
                    width={'25vw'}
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            backgroundColor: '#2499D5',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#2499D5',
                            color: 'white',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: 'white',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#2499D5',
                            color: 'white',
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: 'white',
                        },
                        '& .MuiCheckbox-root svg': {
                            color: 'black',
                        },
                    }}
                >
                    <DataGrid
                        rows={props.marvelAirTSData}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        checkboxSelection
                        onRowSelectionModelChange={(item: any) => props.handleSelectItem(item)}
                    />
                </Box>
            </Box>
        );
    }

};

export default TechMarvelTimeSeriesMainScreen;
