import { FC } from 'react';

import { ReactComponent as Logo } from 'assets/vector/halton-logo.svg';
import { ReactComponent as Expired } from 'assets/vector/expired.svg';

import './index.scss';

const NoAccess: FC = () => {
  return (
    <div className="Page">
      <div className="Page__icon">
        <Expired />
      </div>
      <h1>This link has expired</h1>
      <p>Please contact iot-admin@halton.com to gain access to this site.</p>
      <div className="Page__logo">
        <Logo />
      </div>
    </div>
  );
};

export default NoAccess;
