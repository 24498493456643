import React from 'react';
import Box from '@mui/material/Box';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Halton from '../resources/halton-blue.png';

export default function FixedBottomNavigation() {
  return (
    <Box>
      <Paper elevation={3} style={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: '45px', paddingTop: '3px', marginTop: '10px', zIndex: 1500 }}>
        <Box display='flex' justifyContent='flex-end' alignItems='center'>
          <Box display='flex' justifyContent='space-around' style={{ marginRight: '30px', marginTop:'10px' }}>
            <BottomNavigationAction
              href='https://www.linkedin.com/company/halton-group'
              icon={<LinkedInIcon sx={{ fill: '#0077B5' }} />}
            />

            <BottomNavigationAction
              href='https://www.instagram.com/haltongroup/?hl=en'
              icon={<InstagramIcon sx={{ fill: '#E4405F', marginRight: '30px' }} />}
            />
            <BottomNavigationAction
              href='https://www.halton.com/'
              icon={<img height={'20px'} src={Halton} alt='halton' />}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

