import { createSelector } from 'reselect';
import { RootState } from 'store';
import { RouterState } from 'redux-first-history';

export const selectRouter = (state: RootState): RouterState => state.router;

export const selectQuery = createSelector(
  selectRouter,
  (router): string | undefined => router.location?.search
);
