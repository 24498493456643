import { DataObject } from '../../types/api';

export interface DataValue {
  value: number;
  description: string;
  isActive: boolean;
  graphValue: number;
  type: 'success' | 'warning' | 'error';
  hide?: boolean;
}

const getThermalComfortValues = (
  divider: number,
  dataObject: DataObject | undefined
) => {
  const value = Math.round(parseFloat(dataObject?.value ?? '0') ?? 0);

  const baseValues: DataValue[] = [
    {
      value: -3,
      description: 'Cold',
      graphValue: (100 / divider) * 1,
      isActive: value === -3,
      type: 'error',
    },
    {
      value: -2,
      description: 'Cool',
      graphValue: (100 / divider) * 3,
      isActive: value === -2,
      type: 'error',
    },
    {
      value: -1,
      description: 'Slightly cool',
      graphValue: (100 / divider) * 5.5,
      isActive: value === -1,
      type: 'warning',
    },
    {
      value: 0,
      description: 'Neutral',
      graphValue: (100 / divider) * 7,
      isActive: value === 0,
      type: 'success',
    },
    {
      value: 1,
      description: 'Slightly warm',
      graphValue: (100 / divider) * 5.5,
      isActive: value === 1,
      type: 'warning',
    },
    {
      value: 2,
      description: 'Warm',
      graphValue: (100 / divider) * 3,
      isActive: value === 2,
      type: 'error',
    },
    {
      value: 3,
      description: 'Hot',
      graphValue: (100 / divider) * 1,
      isActive: value === 3,
      type: 'error',
    },
  ];

  return baseValues;
};

const getAirQualityValues = (
  divider: number,
  dataObject: DataObject | undefined
) => {
  const value = Math.round(parseFloat(dataObject?.value ?? '0') ?? 0);

  const baseValues: DataValue[] = [
    {
      value: 6,
      description: 'Severe',
      graphValue: 100 / value,
      isActive: value === 6,
      type: 'error',
    },
    {
      value: 5,
      description: 'Very poor',
      graphValue: 100 / value,
      isActive: value === 5,
      type: 'error',
    },
    {
      value: 4,
      description: 'Poor',
      graphValue: 100 / value,
      isActive: value === 4,
      type: 'error',
    },
    {
      value: 3,
      description: 'Moderate',
      graphValue: 100 / value,
      isActive: value === 3,
      type: 'warning',
    },
    {
      value: 2,
      description: 'Fine',
      graphValue: 100 / value,
      isActive: value === 2,
      type: 'warning',
    },
    {
      value: 1,
      description: 'Excellent',
      graphValue: 100 / value,
      isActive: value === 1,
      type: 'success',
    },
  ];

  return baseValues;
};

const getViralRiskValues = (
  divider: number,
  dataObject: DataObject | undefined
) => {
  const value = Math.round(parseFloat(dataObject?.value ?? '0') ?? 0);
  const baseValues: DataValue[] = [
    {
      value: 100,
      description: 'Likely',
      graphValue: 100 - value,
      isActive: value > 10,
      type: 'error',
    },
    {
      value: 10,
      description: 'Unlikely',
      graphValue: 100 - value,
      isActive: value <= 10,
      type: 'success',
    },
  ];

  return baseValues;
};

export { getThermalComfortValues, getAirQualityValues, getViralRiskValues };
