import { KeyValueItems } from '../types/AppTypes';

//  Helpers used to aggregate KeyItemArrays into one (sum and product)
export const aggregateSum = function (input_array: KeyValueItems): number {
    const outputArray = input_array.reduce((acc, act) => {
      return acc + act.value;
    }, 0);

    return outputArray;
  };


export const aggregateProduct = function (input_array_1: KeyValueItems, input_array_2: KeyValueItems, multiFactor: number): { agg: number; values: KeyValueItems } {
    let result = 0;
    const values = [];

    if (input_array_1.length === input_array_2.length) {
      const length = input_array_1.length;
      for (let i = 0; i < length; i++) {
        result += multiFactor * input_array_1[i].value * input_array_2[i].value;
        values.push({ key: input_array_1[i].key, value: multiFactor * input_array_1[i].value * input_array_2[i].value });
      }
      return { agg: result, values: values };
    } else {
      return { agg: 0, values: [] };
    }
  };

  export const aggregateTimeSeriesProduct = function (input_array_1: KeyValueItems, input_array_2: KeyValueItems, keys: string[]) : KeyValueItems {
    return input_array_1.map((item, index) => {
      return { key: keys[index], value: item.value * input_array_2[index].value };
    });
  };