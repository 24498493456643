import {RootStateType} from '../../store';
import  {EnergyDataType, EnergyParamsType} from '../types/AppTypes';


export const selectEnergyValues = (report: RootStateType) : EnergyDataType => {
    return report.energy.values
}

export const selectEnergyParams = (report: RootStateType) : EnergyParamsType => {
    return report.energy.params
}