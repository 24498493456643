import {useDispatch, useSelector} from 'react-redux';
import {Button, Box, Typography} from '@mui/material';
import {useState} from 'react';

import InputCard from '../../components/InputCard';
import { ReactComponent as Logo } from '../../resources/Halton_Logo.svg'



// Import selectors
const ComingSoon = () => {

    return (
        <Box height={'100vh'} display={'flex'} flexGrow={1} bgcolor={'#ffffff'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Logo fill='#2499D5' width="600" height="180"/>
            <Typography variant='h3' fontFamily={'Roboto'}>
                <b>COMING SOON</b>
            </Typography>
        </Box>
    );
};

export default ComingSoon