import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from '@mui/material';
import { useState } from 'react';
import { energySlice } from 'views/FoodServiceReport/slices/EnergySlice';
import InputCard from '../../components/InputCard';


// Import selectors
import { selectEnergyParams } from '../../utils/selectors/SelectEnergy';
import { EnergyParamsType } from '../../utils/types/AppTypes';
import { calculateEnergy } from '../../utils/helpers/thunks/AsyncCalculateEnergy';
import { AppDispatch } from '../../store';

const EnergyParameters = () => {

    const dispatch = useDispatch<AppDispatch>();
    const energyParams = useSelector(selectEnergyParams);
    const [metaData, setMetaData] = useState<EnergyParamsType>(energyParams);


    const submitAll = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch(energySlice.actions.paramsUpdated(metaData));
        dispatch(calculateEnergy())
    };


    return (
        <Box m='20px' display={'flex'} justifyContent='space-between'>
            <Box m={'10px 0 0 0'} display={'flex'} justifyContent='space-around' flexDirection={'column'}>
                <Button variant='contained' color='primary' type='submit' sx={{ backgroundColor: '#2196f3', marginBottom: '10px' }}
                    onClick={submitAll}>
                    Submit for calculation
                </Button>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'Elevation [m]'}
                        description={'-'}
                        value={metaData.elevation}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, elevation: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>

                    <InputCard
                        name={'Heater efficiency [0-1]'}
                        description={'-'}
                        value={metaData.heaterEfficiency}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, heaterEfficiency: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'COP [0 - infinity]'}
                        description={'-'}
                        value={metaData.copAHU}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, copAHU: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'Fan efficiency [0-1]'}
                        description={'-'}
                        value={metaData.fanEfficiency}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, fanEfficiency: event.target.valueAsNumber }))
                            }
                        }
                    />

                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'DP Design [Pa]'}
                        description={'-'}
                        value={metaData.dPDesign}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, dPDesign: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'Room Temperature [°C]'}
                        description={'-'}
                        value={metaData.roomTemperature}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, roomTemperature: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'Room Humidity [%]'}
                        description={'-'}
                        value={metaData.roomHumidity}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, roomHumidity: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'Supply Temperature [°C]'}
                        description={'-'}
                        value={metaData.supplyTemperature}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, supplyTemperature: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'Electric Heating [%]'}
                        description={'-'}
                        value={metaData.electricHeating}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, electricHeating: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'Gaz Heating [%]'}
                        description={'-'}
                        value={metaData.gasHeating}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, gasHeating: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'Electricity Cost [€/kWh]'}
                        description={'-'}
                        value={metaData.costOfElectricity}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, costOfElectricity: event.target.valueAsNumber }))
                            }
                        }
                    />
                    <InputCard
                        name={'Gaz Cost [€/kWh]'}
                        description={'-'}
                        value={metaData.costOfGas}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, costOfGas: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent={'start'}>
                    <InputCard
                        name={'CO2 emission rate'}
                        description={'-'}
                        value={metaData.erC}
                        handleChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setMetaData((meta: EnergyParamsType) => ({ ...meta, erC: event.target.valueAsNumber }))
                            }
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default EnergyParameters