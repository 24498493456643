import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  getTemplateRequested,
  getTemplateSucceeded,
  getTemplateFailed,
} from 'store/actions/template';

import { getQsToken } from 'utils';

import { getTemplate, GetTemplateResponse } from 'store/api/templateClient';

import { selectQuery } from 'store/selectors';

function* getTemplateSaga() {
  try {
    const search: string | undefined = yield select(selectQuery);

    const token = getQsToken(search);

    if (!token) throw new Error('No token provided for getTemplateSaga.');
    const { data }: AxiosResponse<GetTemplateResponse> = yield call( //eslint-disable-line
      getTemplate,
      token as string
    );

    // TODO: remove data mock from here
    yield put(getTemplateSucceeded({ data }));
  } catch (e) {
    if (e instanceof Error) {
      yield put(getTemplateFailed({ error: e.message }));
    }
  }
}

function* templateSaga() {
  yield all([takeLatest(getTemplateRequested.type, getTemplateSaga)]);
}

export default templateSaga;
