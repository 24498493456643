import { Box, Typography } from '@mui/material';
// import ProgessCircle from './ProgressCircle';
import React from 'react';

interface StatBoxProps {
  title: string;
  subtitle: string;
  icon: any;
  progress: any;
  increase: any;
}

// The StatBox is used to display savings and Co2 reduction on the Dashboard
const StatBox: React.FC<StatBoxProps> = ({ title, subtitle, icon, progress, increase }) => {
  return (
    <Box width={'60%'} m='0 30px'>
      <Box display='flex' justifyContent='space-between' flexDirection='row'>
        <Box display='flex' justifyContent='space-between' flexDirection='column'>
          <Typography>{subtitle}</Typography>
          <Typography
            fontSize='1.8rem'
            fontWeight='bold'
            sx={{
              color: '#3d5170',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignContent='center' flexDirection='column'>
          {icon}
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
