import { FC } from 'react';

import { Card, CardContent, Grid, Typography } from '@mui/material';

import { ReactComponent as SensorImg } from 'assets/vector/sensor.svg';
import { ReactComponent as ThumbsUp } from 'assets/vector/thumbs-up.svg';


import { SensorDataObject } from '../../types/types';
import { TEMPERATURE, HUMIDITY, CO2, PARTICLES,VOCS} from '.';

import './SensorCard.scss';
import { StructuralElement } from 'types/api';

const SensorCard: FC<{
  sensorData: SensorDataObject[];
  location: StructuralElement;
  openSensorDetails(): void;
}> = ({ sensorData,location, openSensorDetails }) => {
  console.log(sensorData)
  return (
    <Card className="Sensors__componentCard" onClick={openSensorDetails}>
      <CardContent style={{ padding: 0 }}>
        <div className="Sensors__cardHeader">
          <Typography>
            {location.name}
          </Typography>
        </div>

        <Grid container className="Sensors__cardContent">
          <Grid item display="flex" alignItems="center" justifyContent="center">
            <SensorImg />
          </Grid>
          <Grid item xs={12} sm>
            {sensorData.map((data, i) => {
              switch (data.units) {
                case 'celsius': {
                    data.units = '°C';
                    break;
                }
                case 'percent': {
                    data.units = '%';
                    break;
                }
                case 'parts per million': {
                    data.units = ' ppm';
                    break;
                }
                case 'micrograms per cubic meter': {
                    data.units = ' µg/m³';
                    break;
                }
                case 'parts per billion': {
                    data.units = ' ppb';
                    break;
                }
                default: {
                    break;
                }
            }
        
              return (
                <Grid
                  container
                  key={i}
                  display="flex"
                  alignItems="center"
                  margin="8px 0"
                >
                  <Grid item xs={12} md={6}>
                    <Typography>{data.displayName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="Sensors__badge">
                    <Typography>{data.value +data.units}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SensorCard;
